import * as Yup from "yup";

import Locale from "@constants/Locale";

const sellContractVehicleSchemaPT = Yup.object({
	brand: Yup.string().required("Campo obrigatório"),
	model: Yup.string().required("Campo obrigatório"),
	version: Yup.string().required("Campo obrigatório"),
	plate: Yup.string().plate({ locale: Locale.PT, errorMessage: "Matrícula inválido" }),
	plateDate: Yup.string().isISODateString(),
	bastidor: Yup.string().bastidor({ errorMessage: "Chassis inválido" }),
	km: Yup.number()
		.moreThan(0, "O campo deve ser maior que 0")
		.integer("Decimais não são permitidos")
		.max(999999, "Km deve ser igual ou inferior a 999.999"),
	color: Yup.string().required("Campo obrigatório"),
	fuel: Yup.string().required("Campo obrigatório"),
	transmission: Yup.string().required("Campo obrigatório"),
	dealership: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obrigatório"),
	dealershipProvince: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obrigatório"),
	conformidades: Yup.string().required("Campo obrigatório").max(300, "Máximo de 300 caracteres")
});

export { sellContractVehicleSchemaPT };
