import * as Yup from "yup";
import { sellContractClientSchemaPT } from "@schema/SellContractPT/clientSchemaPT";
import { sellContractVehicleSchemaPT } from "@schema/SellContractPT/vehicleSchemaPT";
import { sellContractPaymentSchemaPT } from "@schema/SellContractPT/paymentSchemaPT";
import { sellContractSellInfoSchemaPT } from "@schema/SellContractPT/sellDataSchemaPT";
import { sellContractPayAmountsSchemaPT } from "@schema/SellContractPT/sellResumeSchemaPT";

const sellContractSchemaPT = Yup.object({
	client: sellContractClientSchemaPT,
	vehicle: sellContractVehicleSchemaPT,
	payment: sellContractPaymentSchemaPT,
	sellInfo: sellContractSellInfoSchemaPT,
	payAmounts: sellContractPayAmountsSchemaPT,
	observations: Yup.string().nullable().default("")
});

export { sellContractSchemaPT };
