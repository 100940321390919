const authReducer = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_USER":
      state = {
        ...state,
        user: action.user,
      };
      break;
    case "CREATE_ROL":
      state = {
        ...state,
        rol: action.rol,
      };
      break;
    case "CREATE_LOCALS":
      state = {
        ...state,
        locals: action.locals,
      };
      break;
    case "CREATE_VEHICLE_SEARCH":
      state = {
        ...state,
        vehicle_search: action.search,
      };
      break;
    case "CREATE_LEADS_SEARCH":
      state = {
        ...state,
        leads_search: action.search,
      };
      break;
    case "OK_MESSAGE":
      state = {
        ...state,
        message: true,
        message_t: action.message_t,
        message_a: action.message_a,
      };
      break;
    case "NO_MESSAGE":
      state = {
        ...state,
        message: false,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default authReducer;
