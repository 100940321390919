function validaNIF(value) {
    const valueId = value + "";
  
    // has 9 digits?
    if (!/^[0-9]{9}$/.test(valueId)) return false;
  
    // is from a person?
    if (!/^[123]|45|5/.test(valueId)) return false;
  
    // digit check
    let tot =
      valueId[0] * 9 +
      valueId[1] * 8 +
      valueId[2] * 7 +
      valueId[3] * 6 +
      valueId[4] * 5 +
      valueId[5] * 4 +
      valueId[6] * 3 +
      valueId[7] * 2;
    let div = tot / 11;
    
    let mod = tot - parseInt(div) * 11;
    let tst = mod === 1 || mod === 0 ? 0 : 11 - mod;
    return valueId[8] === String(tst);
  }
  export default validaNIF