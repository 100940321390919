import React, { useState } from "react";

import { Input } from "reactstrap";
import { postImage } from "@services/Vehicle/vehicle.service";
import { errorToast } from "@helpers/toastFunctions";
import { MdOutlineFindInPage } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const Uploader = ({
  getImagesPreviewUrls,
  imagesPreviewUrls,
  id,
  imageLoad,
  setImageLoad,
  type,
}) => {
    const { t } = useTranslation('vehicleValidation');
  const [imageValidationError, setImageValidationError] = useState(null);
  const filesSelectedHandler = async (e) => {
    setImageLoad(false);
    if (checkMimeType(e)) {
      const files = Array.from(e.target.files);

      for (const file of files) {
        const formData = new FormData();

        formData.append("image", file, file.name);
        try {
          const response = await postImage(id, formData, type);

          if (response) {
            const reader = new FileReader();

            reader.onloadend = () => {
              const result = {
                file: reader.result,
                size: file.size,
                name: file.name,
                labelId: response.labelId,
                type: file.type,
                url: response.url,
                id: response.id,
              };
              setImageValidationError(null);
              getImagesPreviewUrls(result);
            };
            reader.readAsDataURL(file);
          }
        } catch (e) {
          errorToast(e);
        }
      }
      await setImageLoad(true);
      e.preventDefault(e);
    }
  };
  const checkMimeType = (event) => {
    const { files } = event.target;
    const types = ["image/png", "image/jpeg", "image/jpg"];
    const invalidExtensions = ["jfif"];
    const maxSize = 2097152; // 2MB

    let error = "";

    for (let x = 0; x < files.length; x++) {
      const { type, size, name } = files[x];

      const fileExtension = name.split(".").pop();
      const isInvalidExtension = invalidExtensions.includes(fileExtension.toLowerCase());

      if (isInvalidExtension) {
        error += `${fileExtension} no es una extensión compatible para ${name}\n`;
        continue;
      }

      if (!types.includes(type)) {
        error += `${type} no es un formato válido para ${name}\n`;
        setImageLoad(true);
      }

      if (size > maxSize) {
        error += `La imagen ${name} debe tener un tamaño inferior a 2MB\n`;
        setImageLoad(true);
      }
    }

    if (error !== "") {
      event.target.value = null;
      errorToast(error);
      setImageValidationError(error);
      return false;
    }

    return true;
  };
  return (
    <div className="row">
      <div className="col-md-6">
        <div id="main">
          <div>
            <h5>{t('selectOrDragImages')}</h5>
            <label for="file-input" className="pointer">
              <MdOutlineFindInPage
                title="Buscar archivo"
                size={70}
                color={"#007bff"}
              />
            </label>
            <Input
              className="uploadInput"
              id="file"
              type="file"
              multiple
              name="customFile"
              onChange={(event) => {
                filesSelectedHandler(event);
              }}
              label="Seleccion un archivo"
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </div>

          {imageValidationError ? (
            <span className="error-msg">{imageValidationError}</span>
          ) : null}
        </div>
      </div>
      <div className="col-md-6">
        {t('imagesText')}
      </div>
    </div>
  );
};

export default Uploader;
