import styles from "@styles/views/SellContract/observations.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormTextArea from "@components/Form/Textarea/FormTextarea";

const ObservationsPT = () => {
	return (
		<div>
			<PaneHeader title="Observações" />

			<div className={styles.observations}>
				<FormTextArea
					name="observations"
					label="Introduzir notas  ou comentários no contrato"
					maxLength={300}
				/>
			</div>
		</div>
	);
};

export default ObservationsPT;
