import GenericModal from "@components/GenericModal";
import ContractId from "@components/ContractId/ContractId";
import CreateSellContractPT from "@views/SellContractPT/CreateSellContractPT";
import EditSellContractPT from "@views/SellContractPT/EditSellContractPT";

const SellContractModalPT = ({
	isOpen,
	setIsOpen,
	mode,
	lead,
	client,
	document,
	setIdContract,
	recall
}) => (
	<GenericModal
		isOpen={isOpen}
		setIsOpen={setIsOpen}
		modalClassName="modal-xl"
		headContent={
			{
				create: `Criar contrato ${lead?.nombre_marca_v} ${lead?.nombre_modelo_v} ${lead?.matricula}`,
				edit: (
					<div>
						<ContractId
							contractType={document?.tipo}
							contractId={document?.documentId}
						>
							<span>
								{`Editar contrato ${document?.datos?.marca} ${document?.datos?.modelo} ${document?.datos?.matricula} -`}
							</span>
						</ContractId>
					</div>
				)
			}[mode]
		}
		bodyContent={
			{
				create: (
					<CreateSellContractPT
						data={{ lead, client }}
						onCreate={(id) => {
							setIdContract(id);
							setIsOpen(false);
						}}
					/>
				),
				edit: (
					<EditSellContractPT
						document={document}
						onEdit={async () => {
							await recall();
							setIsOpen(false);
						}}
					/>
				)
			}[mode]
		}
	/>
);
export default SellContractModalPT;
