import React, { useState, useEffect } from "react";
import errorCall from "@services/errorCall";
import { Label, Input, FormGroup, Collapse } from "reactstrap";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { getVersionData } from "@services/Vehicle/vehicle.service";

const ExtrasCategory = ({
  query,
  sendData,
  parentValues,
  load,
  state,
  vehiculo,
  dontShowColapse,
  setRetailPrice,
  retailPrice,
  price,
  setPrice,
}) => {
  const { t } = useTranslation("fichaLeadsCompra");

  const [categories, setCategories] = useState(null);
  const [filterValues, setFilterValues] = useState([]);
  const [extras, setExtras] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const launchQuery = async () => {
      try {
        if (
          vehiculo.version[0]?.label !== "" ||
          vehiculo.version !== "" ||
          vehiculo.version[0]?.value.length > 9
        ) {
          const response = await getVersionData({
        version: vehiculo?.version[0]?.value,
        mode: "JATO",
      });;
          if (response.length !== 0) {
            await setCategories(response?.extras);
            await setPrice(response?.newPrice);
          }
          if (response.length !== 0 && setRetailPrice && !extras) {
            await setRetailPrice(response?.newPrice);
            await setPrice(response?.newPrice);
          }
          if (response.length !== 0 && setRetailPrice && extras) {
            await setRetailPrice(response?.newPrice);
            let suma;
            suma = state.checkLead.extras
              .map((el) => el.price)
              .reduce((a, b) => a + b, 0);

            setPrice(response?.newPrice + suma);
          }
        }
      } catch (e) {
        errorCall(e);
      }
    };
    if (load) {
      launchQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (parentValues && parentValues !== filterValues) {
      setFilterValues(parentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  useEffect(() => {
    setExtras(state.checkLead.extras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiculo]);

  const selectionCheck = (elm, tipo) => {
    const extrasCopy = JSON.parse(JSON.stringify(extras));

    if (extras?.length === 0) {
      extrasCopy.push({
        value: elm.name,
        category: tipo.category,
        price: elm.retailPrice,
        id: elm.id,
      });
      setPrice(price + elm.retailPrice);
    } else if (
      extrasCopy.find(
        (e) => e.value === elm.name && e.category === tipo.category
      )
    ) {
      const remove = extrasCopy.indexOf(
        extrasCopy.find((e) => {
          if (e.value === elm.name && e.category === tipo.category) {
            setPrice(price - elm.retailPrice);
            return true;
          } else {
            return false;
          }
        })
      );
      extrasCopy.splice(remove, 1);
    } else {
      extrasCopy.push({
        value: elm.name,
        category: tipo.category,
        price: elm.retailPrice,
        id: elm.id,
      });
      setPrice(price + elm.retailPrice);
    }
    setExtras(extrasCopy);
  };

  useEffect(() => {
    sendData(extras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extras]);

  const check = (elm, tipo) => {

    return extras?.find(
      (e) => e.value === elm.name && e.category === tipo.category
    );
  };

  return (
    <>
      {categories !== null ? (
        <>
          {!dontShowColapse && (
            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                cursor: "pointer",
              }}
              onClick={toggle}
            >
              <span className="tittle ml-4">
                {t("extraData")}
              </span>

              {isOpen === false ? (
                <MdExpandMore size={25} color="red" />
              ) : (
                <MdExpandLess size={25} color="red" />
              )}
            </div>
          )}
          <Collapse isOpen={dontShowColapse ? true : isOpen}>
            <p>
              {" "}
              {retailPrice
                ? `${t("totalValue")}: ${price.toFixed(
                    2
                  )} €`
                : ""}
            </p>
            <div className="row">
              <>
                {categories?.map((tipo, idx) => (
                  <div key={`categories-tipo-${idx}`} className="col-md-6 col-sm-12">
                    <React.Fragment>
                      <h5 style={{ fontWeight: "600" }}>{tipo.category}</h5>
                      <hr />
                      {tipo.items.map((elm) => (
                        <FormGroup key={`categories-tipo-${tipo.category}-${idx}`}>
                          <Label style={{ marginLeft: "30px" }}>
                            <Input
                              type="checkbox"
                              name={`${elm.name} + ${tipo.category}`}
                              value={`${elm.name} + ${tipo.category}`}
                              onClick={() => selectionCheck(elm, tipo)}
                              checked={
                                extras.length > 0 && check(elm, tipo) ? true : false
                              }
                            />
                            <strong>{`${elm.name}`}</strong>
                            <p>{`Precio:${elm.retailPrice}€`}</p>
                          </Label>
                        </FormGroup>
                      ))}
                    </React.Fragment>
                  </div>
                ))}
              </>
            </div>
          </Collapse>{" "}
        </>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default ExtrasCategory;
