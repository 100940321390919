import { useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/checkbox.module.scss";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * FormCheckBox component for rendering a checkbox element with its label.
 *
 * @component
 * @param {Object} props - The props for the FormCheckBox component.
 * @param {string} props.label - The label text for the checkbox input.
 * @param {string} props.name - The name attribute for the checkbox input.
 * @param {boolean} [props.disabled] - Whether the checkbox input is disabled.
 * @returns {JSX.Element} The rendered component.
 */
const FormCheckBox = ({ label, name, disabled }) => {
  const { register } = useFormContext();
  const { markupError } = useFormError(name);

  return (
    <>
      <label htmlFor={name} className={styles.customCheckboxWrapper}>
        <input id={name} type="checkbox" disabled={disabled} {...register(name)} />
        <span className={disabled && styles.disabled}>{label}</span>
      </label>

      {markupError}
    </>
  );
};

export default FormCheckBox;
