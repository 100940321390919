const SearchFilterInput = (props) => {
  const { editSearchTerm } = props;

  return (
    <input
      className="my-3 form-control input-lg"
      type="text"
      onChange={editSearchTerm}
      placeholder="Nombre"
      autoComplete="on"
    />
  );
};

export default SearchFilterInput;
