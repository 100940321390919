import * as Yup from "yup";

import Locale from "@constants/Locale";

const sellContractClientSchemaPT = Yup.object({
	name: Yup.string().required("Campo obrigatório"),
	firstSurname: Yup.string().required("Campo obrigatório"),
	secondSurname: Yup.string().required("Campo obrigatório"),
	dni: Yup.string().required("Campo obrigatório").identification({ locale: Locale.PT, errorMessage: "NIF inválido" }),
	phone: Yup.string().phone({ locale: Locale.PT, errorMessage: "Telefone inválido" }),
	email: Yup.string().required("Campo obrigatório").email(),
	province: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obrigatório"),
	town: Yup.string().required("Campo obrigatório"),
	address: Yup.string().required("Campo obrigatório"),
	cp: Yup.string()
		.required("Campo obrigatório")
		.postalCode({ locale: Locale.PT, errorMessage: "CP inválido" })
});

export { sellContractClientSchemaPT };
