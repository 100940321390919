import { get, put } from "@services/axiosCalls";

export const getEmployeeLocales = async (id) => {
  const response = await get(`api/employee/${id}/locales`);
  return response;
};

export const getEmployeeType = async (id) => {
  const response = await get(`api/employee/${id}/types`);
  return response;
};

export const getBrands = async (id) => {
  const response = await get(`api/commercial-brands`);
  return response;
};

export const getEmployeeBrands = async (id) => {
  const response = await get(`api/employee/${id}/commercialBrands`);
  return response;
};

export const getEmployeeCompanies = async (id) => {
  const response = await get(`api/employee/${id}/companies`);
  return response;
};

export const getCompanies = async () => {
  const response = await get(`api/companies`);
  return response;
};

export const getPersonInfo = async (id) => {
  const response = await get(`api/person/${id}`);
  return response;
};

export const updatePersonCall = async (id, data) => {
  const response = await put(`api/person/${id}`, data);
  return response;
};

export const getEmployeeInfo = async (id) => {
  const response = await get(`api/employee/${id}`);
  return response;
};

export const updateEmployeeCall = async (id, data) => {
  const response = await put(`api/employee/${id}`, data);
  return response;
};

export const updateLocalsForEmployee = async (id, data) => {
  const response = await put(`api/employee/${id}/locales`, data);
  return response;
};

export const updateCompaniesForEmployee = async (id, data) => {
  const response = await put(`api/employee/${id}/companies`, data);
  return response;
};

export const updateCommercialBrandsForEmployee = async (id, data) => {
  const response = await put(`api/employee/${id}/commercialBrands`, data);
  return response;
};
