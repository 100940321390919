import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import styles from "../index.module.scss";
import GenericModal from "@components/GenericModal";
import { updateCaseEmailSchema } from "@schema/postventaSchema";
import FormError from "@components/FormError";
import { errorToast, successToast } from "@helpers/toastFunctions";
import instance from "@services/base";

const ModalUpdateCaseEmail = ({
	show,
	setShow,
	caseInfo,
	setCaseInfo,
	createAppointment,
	getCreateAppointmentData
}) => {
	const { t } = useTranslation("caseAppointments");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(updateCaseEmailSchema)
	});

	const updateEmail = async (formData) => {
		try {
			await instance.patch(`/api/post-venta/cases/${caseInfo.id}`, {
				email: formData.email
			});
			setCaseInfo((caseInfo) => ({
				...caseInfo,
				email: formData.email
			}));
			await createAppointment(getCreateAppointmentData());
			successToast(t("success.updateEmail"));
			setShow(false);
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.updateEmail"));
		}
	};

	return (
		<GenericModal
			isOpen={show}
			setIsOpen={() => setShow(false)}
			headContent={t("updateCaseEmailModal.title")}
			bodyContent={
				<div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
					<span>{t("updateCaseEmailModal.content")}</span>
					<form>
						<div style={{ display: "flex", flexDirection: "column", width: "40%" }}>
							<label>Email</label>
							<input
								type="text"
								placeholder="mail@mail.com"
								{...register("email")}
							/>
							<FormError error={errors["email"]} />
						</div>
					</form>
				</div>
			}
			footerContent={
				<div className={styles.modalButtons}>
					<button
						className="btn btn-flexicar-inverse"
						onClick={() => setShow(false)}
					>
						{t("updateCaseEmailModal.cancelButton")}
					</button>
					<button
						type="submit"
						className="btn btn-flexicar-orange"
						onClick={handleSubmit(updateEmail)}
					>
						{t("updateCaseEmailModal.acceptButton")}
					</button>
				</div>
			}
		/>
	);
};

export default ModalUpdateCaseEmail;
