import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <Container>
      <Row className="justify-content-center p-5">
        <Spinner animation="border" className="my-5 mx-2"/>
        <h5 className="flexicarColor my-5 mx-2"> CARGANDO...</h5>
      </Row>
    </Container>
  );
};

export default Loading;
