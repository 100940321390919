import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";

const FilterDaysInStock = ({
  values,
  name,
  str,
  symbol,
  sendData,
  value,
  column,
}) => {
  const [filterValues, setFilterValues] = useState([]);

  //Al actualizar el padre recibe Props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (value && value.value.length === 0 && filterValues.length > 0) {
      setFilterValues([]);
    }
  });

  //Se ejecuta cuando se añade o elimina un valor del desplegable
  //Envía el valor al padre y al State
  const onChange = (selection) => {
    sendData(symbol, selection, column);
    setFilterValues(selection);
  };

  return (
    <div>
      <Select
        color={"#FF5D0E"}
        options={values.map((x, y) => {
          return {
            label: str + " de " + x + " días",
            value:
              "v." +
              column +
              symbol +
              " DATE_ADD(now(), INTERVAL -" +
              x +
              " DAY)",
          };
        })}
        placeholder={name}
        values={filterValues}
        clearable={filterValues.length > 0 ? true : false}
        noDataRenderer={() => (
          <span style={{ textAlign: "center" }}>Sin datos</span>
        )}
        onChange={(selection) => onChange(selection)}
        className="fix_selector_arrow"
      />
    </div>
  );
};

export default FilterDaysInStock;
