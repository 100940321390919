import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Label } from "reactstrap";
import { MdContentCopy } from "react-icons/md";
import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import { errorToast } from "@helpers/toastFunctions";
import { successToast } from "@helpers/toastFunctions";

import RolesList from "@views/UserRoles/RolesList";
import {
  getRoles,
  addRoles,
  getEmployeesNameByGreaterType,
  getEmployeeRoles,
  deleteEmployeeRole,
  copyEmployeeRoles,
  getEmployeeName,
} from "@services/Roles/RolesService";
import Select from "@components/Select";

const UserRoles = () => {
  const { id } = useParams();

  const [employeeName, setEmployeeName] = useState("");

  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [userSelected, setUserSelected] = useState();

  const [userRoles, setUserRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);

  const [userRolesFilter, setUserRolesFilter] = useState("");
  const [availableRolesFilter, setAvailableRolesFilter] = useState("");

  useEffect(() => {
    (async () => {
      const responseGetEmployeeName = await getEmployeeName(id);
      const responseGetRoles = await getRoles();
      const responseUserRoles = await getEmployeeRoles(id);
      const response = await getEmployeesNameByGreaterType(id);
      setEmployeeName(
        `${responseGetEmployeeName.nombre} ${responseGetEmployeeName.apellido1} ${responseGetEmployeeName.apellido2}`
      );
      setAvailableRoles(
        responseGetRoles.filter(
          (rol) =>
            !Object.values(responseUserRoles)
              .map((x) => x.id)
              .includes(rol.id)
        )
      );
      setUserRoles(responseUserRoles);
      setUserSelectOptions(
        response.map((x) => ({
          value: x.id,
          label: `${x.nombre} ${x.apellido1} ${x.apellido2}`,
        }))
      );
    })();
  }, [id]);

  const copyUserSelectedRoles = async () => {
    if (userSelected) {
      try {
        await copyEmployeeRoles(id, userSelected.value);
        const userRoles = await getEmployeeRoles(id);
        setUserRoles(userRoles);
        const roles = await getRoles();
        setAvailableRoles(
          roles.filter(
            (rol) =>
              !Object.values(userRoles)
                .map((x) => x.id)
                .includes(rol.id)
          )
        );
        successToast(`Roles de ${userSelected.label} copiados correctamente`);
      } catch (e) {
        errorToast("Ha ocurrido un error al copiar los roles");
      }
    } else {
      errorToast("Debes seleccionar un empleado para copiar los roles");
    }
  };

  const deleteUserRol = async (rol) => {
    try {
      await deleteEmployeeRole(id, rol.id);
      setUserRoles((prevRoles) => prevRoles.filter((x) => x.id !== rol.id));
      setAvailableRoles((prevAvRoles) => [...prevAvRoles, rol]);
      successToast(`Rol ${rol.nombre} eliminado correctamente`);
    } catch (e) {
      errorToast("Ha ocurrido un error al eliminar el rol");
    }
  };

  const addUserRol = async (rol) => {
    try {
      await addRoles(id, rol.id);
      setUserRoles((prevRoles) => [...prevRoles, rol]);
      setAvailableRoles((prevAvRoles) =>
        prevAvRoles.filter((x) => x.id !== rol.id)
      );
      successToast(`Rol ${rol.nombre} añadido correctamente`);
    } catch (e) {
      errorToast("Ha ocurrido un error al añadir el rol");
    }
  };

  return (
    <Mainlayout>
      <Mainlayout.Content
        full
        contentTitle={`ROLES DEL USUARIO: ${employeeName}`}
      >
        <div className="col-12 col-lg-10 d-flex flex-column align-self-center">
          <div className={`d-flex flex-column mt-3 ${styles.card}`}>
            <h2 className="text-primary">Acciones rápidas</h2>
            <div className="col-12 d-flex flex-wrap mt-2">
              <div className="col-12 col-lg-6">
                <Label>Busqueda en Roles Concedidos</Label>
                <Input
                  type="text"
                  value={userRolesFilter}
                  onChange={(e) => setUserRolesFilter(e.target.value)}
                />
              </div>

              <div className="col-12 col-lg-6">
                <Label>Busqueda en Roles Disponibles</Label>
                <Input
                  type="text"
                  value={availableRolesFilter}
                  onChange={(e) => setAvailableRolesFilter(e.target.value)}
                />
              </div>

              <div className="col-12 col-lg-6 mb-5 mb-lg-4">
                <Label>Selecciona un empleado para copiarle los roles</Label>
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    <Select
                      placeholder="Selcciona un empleado"
                      options={userSelectOptions}
                      value={userSelected}
                      onChange={(selectedOption) =>
                        setUserSelected(selectedOption)
                      }
                    />
                  </div>
                  <button
                    outline="true"
                    className="btn"
                    title="Copiar roles del empleado seleccionado"
                    onClick={copyUserSelectedRoles}
                  >
                    <MdContentCopy size={24} />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap mb-4">
            <div
              className={`col-12 col-lg-6 d-flex flex-column mt-5 ${styles.card}`}
            >
              <h2 className="text-primary">Roles Concedidos</h2>
              <RolesList
                roles={userRoles.filter((userRole) =>
                  userRole.nombre
                    .toLocaleLowerCase()
                    .includes(userRolesFilter.toLocaleLowerCase())
                )}
                action="delete"
                actionClick={(rol) => deleteUserRol(rol)}
              />
            </div>
            <div
              className={`col-12 col-lg-6 d-flex flex-column mt-5 ${styles.card}`}
            >
              <h2 className="text-primary">Roles Disponibles</h2>
              <RolesList
                roles={availableRoles.filter((avRoles) =>
                  avRoles.nombre
                    .toLocaleLowerCase()
                    .includes(availableRolesFilter.toLocaleLowerCase())
                )}
                action="add"
                actionClick={(rol) => addUserRol(rol)}
              />
            </div>
          </div>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default UserRoles;
