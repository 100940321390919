import { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import debounce from "lodash.debounce";

import styles from "./index.module.scss";
import TableCalendar from "@components/WeekCalendar/TableCalendar";
import AgendaCalendar from "@components/WeekCalendar/AgendaCalendar";

import { weekDays } from "@components/WeekCalendar/utils";
import { useMediaQuery } from "@hooks/useMediaQuery";
import { AppointmentContext } from "@views/Appointments/AppointmentContext";

const WeekCalendar = () => {
	moment.locale("es");

	const { type, calendarConfig, eventsFiltered, setEvents } = useContext(AppointmentContext);

	const isDesktop = useMediaQuery("(min-width: 1200px)");

	const [weekStart, setWeekStart] = useState(moment().clone().startOf("week"));
	const [weekEnd, setWeekEnd] = useState(moment().clone().endOf("week"));
	const [calendarDayHeaders, setCalendarDayHeaders] = useState([]);

	const enumerateDaysBetweenDates = (startDate, endDate) => {
		const now = startDate.clone();
		const dates = [];

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.clone());
			now.add(1, "days");
		}
		return dates;
	};

	const getCalendarHeaders = (evs, inBetweenDates) =>
		inBetweenDates.map((x, idx) => {
			const eventsOnDay = Object.values(evs[x.format("DD-MM-YYYY")] ?? {});
			const eventsOnDayCount = Object.values(eventsOnDay).reduce((acc, time) => {
				return (
					acc +
					Object.values(time).reduce((innerAcc, location) => {
						return innerAcc + location.length;
					}, 0)
				);
			}, 0);

			return {
				day: x,
				weekDay: `${weekDays[idx]} ${x.format("DD")}`,
				dayFormatted: x.format("DD-MM-YYYY"),
				eventsCount: eventsOnDayCount
			};
		});

	const getPreviousWeek = () => {
		setWeekStart(weekStart.clone().subtract(1, "week"));
		setWeekEnd(weekEnd.clone().subtract(1, "week"));
	};

	const getNextWeek = () => {
		setWeekStart(weekStart.clone().add(1, "week"));
		setWeekEnd(weekEnd.clone().add(1, "week"));
	};

	const debounceFetch = useCallback(
		debounce(async (startDate, endDate) => {
			const formatDate = (date) => date.format("DD-MM-YYYY");
			// Fetch events for the date range
			const appointments = await calendarConfig.getEvents(
				formatDate(startDate),
				formatDate(endDate)
			);

			// Group events by date, hour and locale
			let groupedEvents = appointments.reduce((acc, event) => {
				const appointmentDate =
					type === "post-venta" ? event.appointmentDate : event.lead.appointmentDate;
				const dealership = type === "post-venta" ? event.dealership.name : event.local.name;

				const start = moment(appointmentDate);
				const day = formatDate(start);
				const hour = start.format("HH:mm");
				if (!acc[day]) acc[day] = {};
				if (!acc[day][hour]) acc[day][hour] = {};
				if (!acc[day][hour][dealership]) acc[day][hour][dealership] = [];

				event["bgColor"] = calendarConfig.getChipBgColor(event);
				acc[day][hour][dealership].push(event);
				return acc;
			}, {});

			// Sort the events by date and hour
			groupedEvents = Object.fromEntries(
				Object.entries(groupedEvents)
					.sort(([dateA], [dateB]) => moment(dateA, "DD-MM-YYYY").diff(moment(dateB, "DD-MM-YYYY")))
					.map(([dateKey, innerObj]) => [
						dateKey,
						Object.fromEntries(
							Object.entries(innerObj).sort(([a], [b]) =>
								moment(a, "HH:mm").diff(moment(b, "HH:mm"))
							)
						)
					])
			);

			setEvents(groupedEvents);
		}, 500),
		[]
	);

	useEffect(() => {
		debounceFetch(weekStart, weekEnd);
	}, [debounceFetch, weekStart, weekEnd]);

	useEffect(() => {
		const inBetweenDates = enumerateDaysBetweenDates(weekStart, weekEnd);
		const headers = getCalendarHeaders(eventsFiltered, inBetweenDates);
		setCalendarDayHeaders(headers);
	}, [weekStart, weekEnd, eventsFiltered]);

	return (
		<div className="w-100 overflow-auto">
			<div className={styles.eventsHeader}>
				<span className={styles.eventsHeaderDate}>
					{`${weekStart.format("DD MMM YYYY")} - ${weekEnd.format("DD MMM YYYY")}`}
				</span>
				<div className={styles.arrows}>
					<button className={styles.arrowButton}>
						<AiOutlineArrowLeft
							size={24}
							onClick={getPreviousWeek}
						/>
					</button>
					<button className={styles.arrowButton}>
						<AiOutlineArrowRight
							size={24}
							onClick={getNextWeek}
						/>
					</button>
				</div>
			</div>

			{isDesktop ? (
				<TableCalendar calendarDayHeaders={calendarDayHeaders} />
			) : (
				<AgendaCalendar calendarDayHeaders={calendarDayHeaders} />
			)}
		</div>
	);
};

export default WeekCalendar;
