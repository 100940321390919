const LEAD_STATUS = {
  citado: 12,
  coche_muy_Antiguo: 8,
  contactado_por_email: 43,
  contactado_por_mail: 51,
  contactado_por_whasapp: 52,
  distante_a_tienda: 35,
  reservado_pago_transferencia: 38,
  reservado_pago_tienda: 41,
  rellamada: 37,
  pendiente_tasacion_vehiculo_pago: 45,
  pendiente_documentacion_financiacion: 49,
  pagado_tienda: 47,
  pagado_tarjeta_pasarela: 46,
  pagado_bizum_pasarela: 56,
  nos_llama_cliente: 33,
  no_financiable: 39,
  no_comtesta: 11,
  no_asiste_cita: 32,
  ganado: 2,
  formulario_nuevo: 1,
  formulario_nuevo_IA: 62,
  envia_foto_whatsapp: 30,
  en_gestion: 54,
  dudoso: 3,
  volver_a_llamar: 50,
  vehiculo_vendido_otro_cliente: 42,
  telefono_erroneo: 29,
  tasacion_informativa: 31,
  reservo_otro_vehiculo: 48,
  reservado_pendiente_pago_pasarela: 40,
  pendiente_tasacion_central: 57,
  reserva_online: 63
};

export default LEAD_STATUS;