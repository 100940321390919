import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineDelete, MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";

import styles from "./index.module.scss";
import Card from "@components/Card";
import UploadNoCallComponent from "@components/UploadNoCallComponent";
import PaginationFooter from "@components/PaginationFooter";
import RenderTemplateEjs from "@components/RenderTemplateEjs/renderTemplateEjs";
import { get } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { formatSpanishFullDate } from "@helpers/formatDate";
import { getCurrentPage } from "@helpers/currentPage";
import { typeDocumentTranslate } from "@constants/documentWithdrawReception";
import instance from "@services/base";
import Loading from "@views/Loading/Loading";

const WithdrawalReceptionDocs = ({ caseId, vehicleId }) => {
	const [areDocumentsLoading, setAreDocumentsLoading] = useState(true);

	const [documents, setDocuments] = useState([]);
	const [showAddFileModal, setShowAddFileModal] = useState(false);
	const [selectedDocumentId, setSelectedDocumentId] = useState();
	const [offset, setOffset] = useState(0);
	const [actualPage, setActualPage] = useState(1);
	const [totalDocuments, setTotalDocuments] = useState();
	const [dataTemplate, setDataTemplate] = useState();
	const [spanishFullDate, setSpanishFullDate] = useState();
	const [isRenderTemplate, setIsRenderTemplate] = useState(false);
	const paginationLimit = 2;

	const loadDocuments = useCallback(async () => {
		try {
			const response = await get(API.GET_DOCUMENTS_POST_VENTA(caseId, paginationLimit, actualPage));
			if (response) {
				setDocuments(response.results);
				setTotalDocuments(response.total);
			}
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al buscar documentos");
		} finally {
			setAreDocumentsLoading(false);
		}
	}, [actualPage, caseId]);

	const loadTemplateDocument = async (doc) => {
		if (doc.sign) {
			try {
				const response = await get(API.GET_DOCUMENT_POST_VENTA_SIGN(doc.id));
				if (response) {
					return window.open(response.url, "_blank", "noopener,noreferrer");
				}
			} catch (error) {
				errorToast("Error al cargar el documento");
			}
		} else {
			const response = await get(API.GET_VEHICLE_POST_VENTA(caseId, doc.id));

			const templateInfo = response.data;

			setSpanishFullDate(formatSpanishFullDate(new Date(response.createdAt)));
			setDataTemplate({templateInfo, typeDocumentTranslate, spanishFullDate});
			setIsRenderTemplate(true);
		}
	};
	const changePagination = (min, num) => {
		setOffset(min);
		const actualPage = getCurrentPage(min, paginationLimit, totalDocuments);
		setActualPage(actualPage);
	};

	const saveDocument = async (file, id) => {
		setAreDocumentsLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", file.object);
			const response = await instance.post(
				`/api/post-venta/cases/${caseId}/documents/${selectedDocumentId}`,
				formData
			);
			if (response.status === 204) successToast("Documento adjuntado con exito");
			loadDocuments();
		} catch (err) {
			errorToast(err.response?.data?.message || "Error al adjuntar el documento");
		}
	};

	const handleDeleteAction = async (id) => {
		setAreDocumentsLoading(true);
		try {
			const response = await instance.delete(`/api/post-venta/cases/${caseId}/documents/${id}`);
			if (response.status === 204) {
				if (documents.length === 1 && actualPage !== 1) setActualPage(actualPage - 1);

				successToast("Documento borrado con exito");
				loadDocuments();
			}
		} catch (err) {
			errorToast(err.response?.data?.message || "Error al eliminar el documento");
		}
	};

	useEffect(() => {
		loadDocuments();
	}, [loadDocuments]);

	if (areDocumentsLoading) return <Loading />;

	return (
		<>
			{documents.length > 0 ? (
				<>
					<div className={styles.documentsWrapper}>
						{documents.map((doc) => (
							<Card
								key={`withdrawal-reception-card-${doc.id}`}
								title={`Documento de ${typeDocumentTranslate[doc.type]}`}
								content={`${doc.agent.name} ${doc.agent.firstSurname} ${doc.agent.secondSurname
									} edito el documento el ${formatSpanishFullDate(new Date(doc.createdAt))}`}
								titleIcon={
									<div>
										{doc.sign ? (
											<AiOutlineCheckCircle
												color="green"
												className="pointer"
												size={20}
											/>
										) : (
											<AiOutlineInfoCircle
												color="red"
												className="pointer"
												size={20}
												onClick={() => {
													setSelectedDocumentId(doc.id);
													setShowAddFileModal(true);
												}}
											/>
										)}
									</div>
								}
								actions={
									<>
										<div>
											<MdOutlineRemoveRedEye
												className="pointer"
												size={24}
												color="#307DF6"
												onClick={() => {
													loadTemplateDocument(doc);
												}}
											/>
										</div>
										{!doc.sign && (
											<>
												<div>
													<Link
														to={`/main/vehicle/${vehicleId}/caso/${caseId}/editar-documento/${doc.id}`}
													>
														<MdOutlineEdit
															className="pointer"
															size={24}
															color="#ff5d0e"
														/>
													</Link>
												</div>
												<div>
													<MdOutlineDelete
														className="pointer"
														size={24}
														onClick={() => handleDeleteAction(doc.id)}
														color="#D34600"
													/>
												</div>
											</>
										)}
									</>
								}
							/>
						))}
					</div>
					<PaginationFooter
						paginationMin={offset}
						total={totalDocuments}
						showNumb={paginationLimit}
						elements={documents}
						changePagination={changePagination}
						actualPage={actualPage}
					/>
				</>
			) : (
				<span>No se han encontrado documentos</span>
			)}

			<UploadNoCallComponent
				onFile={saveDocument}
				show={showAddFileModal}
				setShow={setShowAddFileModal}
			/>

			<RenderTemplateEjs
				data={dataTemplate}
				templateId={4}
				show={isRenderTemplate}
				setShow={setIsRenderTemplate}
			/>
		</>
	);
};

export default WithdrawalReceptionDocs;
