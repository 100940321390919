import {
  createUser,
  createRol,
  createLocal,
  vehicleSearch,
  leadsSearch,
  message,
} from "@redux/actions";

export const dispatcher = (actions) => {
  return (dispatch) => {
    const dispatcher = {};
    actions.forEach((e) => {
      switch (e) {
        case "createUser":
          dispatcher.createUser = (user) => dispatch(createUser(user));
          break;
        case "createRol":
          dispatcher.createRol = (rol) => dispatch(createRol(rol));
          break;
        case "createLocal":
          dispatcher.createLocal = (locals) => dispatch(createLocal(locals));
          break;
        case "vehicleSearch":
          dispatcher.vehicleSearch = (search) =>
            dispatch(vehicleSearch(search));
          break;
        case "leadsSearch":
          dispatcher.leadsSearch = (search) => dispatch(leadsSearch(search));
          break;
        case "message":
          dispatcher.message = (mes) => dispatch(message(mes));
          break;
        default:
          break;
      }
    });
    return dispatcher;
  };
};
