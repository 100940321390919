import React from "react";
import { Row } from "reactstrap";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
const PaginationFooter = ({
  paginationMin,
  total,
  showNumb,
  elements,
  changePagination,
  page,
}) => {
  return (
    <div>
      <Row className="justify-content-md-center mt-3">
        <BiChevronLeft
          className={
            "waves_effect " + (paginationMin <= 0 ? "inactive" : "pointer")
          }
          size={30}
          onClick={
            paginationMin <= 0
              ? (e) => e.preventDefault()
              : () => changePagination(paginationMin - showNumb, showNumb)
          }
        />
        {elements.length === 0 ? 0 : page} / {Math.ceil(total / showNumb)}
        <BiChevronRight
          className={
            "waves_effect " +
            (paginationMin >= total - showNumb ? "inactive" : "pointer")
          }
          size={30}
          onClick={
            paginationMin >= total - showNumb
              ? (e) => e.preventDefault()
              : () => changePagination(paginationMin + showNumb, showNumb)
          }
        />
      </Row>
    </div>
  );
};

export default PaginationFooter;
