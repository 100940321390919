import { useFormContext } from "react-hook-form";

import styles from "@styles/views/SellContract/payment.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormSelect from "@components/Form/Select/FormSelect";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import { paymentTypePT, yesOrNotSelectPT } from "@constants/selectOptionsPT";

const PaymentPT = () => {
	const { watch } = useFormContext();

	const wantsReservation = watch("payment.reservation.wants", false)?.value;
	const wantsEntry = watch("payment.entry.wants", false)?.value;

	return (
		<div>
			<PaneHeader title="Pagamento" />

			<div className={styles.container}>
				<FormSelect
					name="payment.type"
					label="Tipo de pagamento"
					options={paymentTypePT}
				/>
			</div>

			<div className={styles.container}>
				<FormSelect
					name="payment.reservation.wants"
					label="Sinal para reserva"
					options={yesOrNotSelectPT}
				/>
				{wantsReservation && (
					<FormFormattedNumberInput
						name="payment.reservation.amount"
						label="Quantia do Sinal"
					/>
				)}
			</div>

			<div className={styles.container}>
				<FormSelect
					name="payment.entry.wants"
					label="Entrada"
					options={yesOrNotSelectPT}
				/>

				{wantsEntry && (
					<FormFormattedNumberInput
						name="payment.entry.amount"
						label="Quantia da Entrada"
					/>
				)}
			</div>
		</div>
	);
};

export default PaymentPT;
