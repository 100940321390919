import { Col, Container, Row } from "reactstrap";

import styles from "./index.module.scss";
import PaneHeader from "@components/PaneHeader";

const MainlayoutSidebar = ({ children, hide = true }) => (
	<Col className={`${hide ? "d-none" : ""} d-xl-block col-xl-2 col-12 mb-5 mb-xl-0`}>
		<div className={styles.sidebar_container}>{children}</div>
	</Col>
);

const MainLayoutContent = ({ contentTitle, buttons, full = false, children }) => (
	<div className={`${full ? "" : "col-xl-10"} col-12 ${styles.main_content}`}>
		<PaneHeader
			title={contentTitle}
			buttons={buttons}
		/>
		<div className={styles.content_container}>{children}</div>
	</div>
);

const Mainlayout = ({ children }) => (
	<Container
		className="mt-4"
		fluid
	>
		<Row className="m-auto justify-content-center">{children}</Row>
	</Container>
);

Mainlayout.Sidebar = MainlayoutSidebar;
Mainlayout.Content = MainLayoutContent;
export default Mainlayout;
