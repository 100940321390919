export const yesOrNotSelectPT = [
	{ label: "Sim", value: true },
	{ label: "Não", value: false }
];

export const warrantyTypePT = [
	{ label: "Base 6 meses (TVDE)", value: 1 },
	{ label: "Base 12 meses (TVDE)", value: 2 },
	{ label: "Base 18 meses (TVDE)", value: 3 },
	{ label: "Base 18 meses", value: 4 },
	{ label: "Base 24 meses", value: 5 },
	{ label: "Base 36 meses", value: 6 }
];

export const paymentTypePT = [
	{ label: "Pronto pagamento", value: 1 },
	{ label: "Financiamento Total", value: 2 },
	{ label: "Financiamento Parcial", value: 3 }
];
