import moment from "moment";

export const weekDays = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
];

export const dailyHours = (() => {
    const start = moment("09:30", "HH:mm");
    const end = moment("20:30", "HH:mm");
    const result = [];

    while (start.isSameOrBefore(end)) {
        result.push(start.format("HH:mm"));
        start.add(30, "minutes");
    }

    return result;
})();