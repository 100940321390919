
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ModalWarningContract = ({ modalUnsigned,setModalUnsigned }) => {

  return (
    <Modal
      size="large"
      centered
      className="modal-lg"
      isOpen={modalUnsigned}
    >
      <ModalHeader toggle={() => setModalUnsigned(false)}>Retirada de vehículo</ModalHeader>
      <ModalBody className="p-4">
        <center>
          Para poder retirar el vehículo de stock, tiene que tener subido el
          contrato de venta
        </center>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={() => setModalUnsigned(false)}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWarningContract;
