import { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/radio-button-group.module.scss";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * @typedef {Object} Option
 * @property {string} label - The label for the radio button.
 * @property {any} value - The value of the radio button.
 * @property {boolean} disabled - Whether the radio button is disabled.
 */

/**
 * FormRadioButtonGroup component for rendering a group of radio inputs elements with their labels.
 *
 * @component
 * @param {Object} props - The props for the FormRadioButtonGroup component.
 * @param {Option[]} props.options - An array of radio options, each with a label and value.
 * @param {string} props.name - The name attribute for the radio inputs (shared among options).
 * @returns {JSX.Element} The rendered component.
 */
const FormRadioButtonGroup = ({ options, name }) => {
	const { register } = useFormContext();
	const { markupError } = useFormError(name);

	return (
		<div className={styles.customRadioButton}>
			<div className={styles.customRadioButtonWrapper}>
				{options.map(({ label, value, disabled }, index) => {
					const radioId = `radio-${name}-${index}`;

					return (
						<Fragment key={`radio-group-${name}-${label}`}>
							<label
								htmlFor={radioId}
								className={styles.customRadioButtonInput}
							>
								<input
									id={radioId}
									type="radio"
									value={value}
									disabled={disabled}
									{...register(name)}
								/>
								<span className={disabled && styles.disabled}>{label}</span>
							</label>
						</Fragment>
					);
				})}
			</div>

			{markupError}
		</div>
	);
};

export default FormRadioButtonGroup;
