import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";

export const getProvinces = async (setter,error) => {
    try {
        const response = await instance.post(API.FILTROS, {
            dataCall: {
                data_query: "provincias",
                data_call: null
            }
        });

        const provinces = response.data.map((x) => ({
            label: x.nombre,
            value: x.id
        }));

        setter(provinces);
    } catch (err) {
        errorToast(error);
    }
};