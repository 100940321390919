import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

/**
 * @typedef {import('react-number-format').NumericFormatProps} Props
 */

/**
 * @type React.ForwardRefRenderFunction<?, Props>
 */
const FormattedNumberInput = forwardRef(
	(
		{
			onChange,
			placeholder = "0 €",
			suffix = " €",
			displayType = "input",
			thousandSeparator = ".",
			decimalSeparator = ",",
			decimalScale = 2,
			decimalPow = 0,
			...rest
		},
		ref
	) => {
		const [currency, setCurrency] = useState(rest.value / Math.pow(10, decimalPow));

		useEffect(() => {
			setCurrency(rest.value / Math.pow(10, decimalPow));
		}, [rest.value, decimalPow]);

		return (
			<NumericFormat
				{...rest}
				getInputRef={ref}
				placeholder={placeholder}
				value={currency}
				displayType={displayType}
				thousandSeparator={thousandSeparator}
				decimalSeparator={decimalSeparator}
				decimalScale={decimalScale}
				onValueChange={(values) => {
					const decimalValue = values.floatValue;
					setCurrency(decimalValue);
					onChange?.(isNaN(decimalValue) ? 0 : decimalValue * Math.pow(10, decimalPow));
				}}
				valueIsNumericString
				suffix={suffix}
			/>
		);
	}
);

FormattedNumberInput.name = "FormattedNumberInput";
export default FormattedNumberInput;
