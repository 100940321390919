import React from "react";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./SectionButton.css";
import { TiArrowForward } from "react-icons/ti";

const SectionButton = ({ title, url }) => {
  const history = useHistory();
  return (
    <Row
      className="custom__box justify-content-between"
      onClick={() => history.push(url)}
    >
      <h5 className="custom__text">{title}</h5>
      <TiArrowForward size={25} color="orange" />
    </Row>
  );
};

export default SectionButton;
