import * as Yup from "yup";

export const sellContractPayAmountsSchema = Yup.object({
	financingAmount: Yup.number().isNumberWithLessThanNDecimals(2),
	dealershipTPVAmount: Yup.number().isNumberWithLessThanNDecimals(2),
	virtualTPVAmount: Yup.number().isNumberWithLessThanNDecimals(2),
	cashAmount: Yup.number().isNumberWithLessThanNDecimals(2),
	transferenceAmount: Yup.number().isNumberWithLessThanNDecimals(2),
	carPayAmount: Yup.number().isNumberWithLessThanNDecimals(2)
});
