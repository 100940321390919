import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import { errorToast } from "@helpers/toastFunctions";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import ejsTemplateRender from "@helpers/ejsTemplateRender";

const Ejs = () => {
  const { t } = useTranslation('ejs');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const factura_id = urlParams.get("factura");
    document.title = `Flexicar | ${factura_id ? factura_id : "Documento"}`;
    const factura_fecha = urlParams.get("fecha");
    if (factura_id && factura_fecha) {
      getTemplate(factura_id, factura_fecha);
    } else {
      getTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplate = async (factura_id, factura_fecha) => {
    const urlParam = window.location.pathname.split("/");
    const contractId = urlParam[urlParam.length - 1];

    try {

      const dataTemplate = { factura_id, factura_fecha, contractId };

      await ejsTemplateRender(5, dataTemplate);

    } catch {
      return errorToast(<span>{t("errors.errorContractExists")} {contractId}</span>);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Button
        className="btn-flexicar mt-4 mr-5 right no-print"
        onClick={() => handlePrint()}
      >
        Imprimir contrato
      </Button>
      <br />
      <div className="m-5" ref={componentRef} id="template"></div>
    </div>
  );
};

export default Ejs;
