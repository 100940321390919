export const SELL_CONTRACT_DEFAULT_PRICES = Object.freeze({
	OWNERSHIP_AMOUNT: 350,
	TIN: 0,
	QUOTA: 0,
	TRANSPORT_AMOUNT: 0,
	WARRANTY_PREMIUM_AMOUNT: 450,
	WARRANTY_EXTRA_AMOUNT: 292.01,
	INSURANCE_AMOUNT: 0
});

export const SELL_CONTRACT_PRICE_TYPES = Object.freeze({
	PVP_CONTADO: 1,
	PVP_FINANCIADO: 2,
	PVD: 3
});
