import { useState, useEffect } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { Collapse } from "reactstrap";
import styles from "./index.module.scss";

const DataCardVehicle = ({ vehicleDataClient, vehiculo, vehiculoOld }) => {
	const toggle = () => setIsOpen(!isOpen);
	const [isOpen, setIsOpen] = useState(true);

	useEffect(() => {
		if (!vehiculoOld.jato && vehiculo.version_old === null) {
			setIsOpen(false);
		}
	}, [vehiculoOld, vehiculo]);

	return (
		<div className={styles.container}>
			<div
				className={styles.collapse}
				onClick={toggle}
			>
				{" "}
				<div>
					<span className="text-black">Datos aportados por el cliente</span>
				</div>
				<div>
					{!isOpen ? (
						<MdExpandMore
							size={25}
							color="black"
						/>
					) : (
						<MdExpandLess
							size={25}
							color="black"
						/>
					)}
				</div>
			</div>
			<Collapse isOpen={isOpen}>
				<table className={styles.collapseContent}>
					<tbody>
						<tr>
							<td>Marca:</td>
							<td>{vehicleDataClient.make}</td>
						</tr>
						<tr>
							<td>Modelo:</td>
							<td>{vehicleDataClient.model}</td>
						</tr>
						<tr>
							<td>Versión:</td>
							<td>{vehicleDataClient.version}</td>
						</tr>
						<tr>
							<td>Año de matriculación:</td>
							<td>{vehicleDataClient.year}</td>
						</tr>
						<tr>
							<td>Combustible:</td>
							<td>{vehicleDataClient.fuel}</td>
						</tr>
						<tr>
							<td>Caja de cambios:</td>
							<td>{vehicleDataClient.transmission}</td>
						</tr>
					</tbody>
				</table>
			</Collapse>
		</div>
	);
};

export default DataCardVehicle;
