import React, { useState, useRef, useEffect } from "react";
import { postWithStatus } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { useTranslation } from "react-i18next";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import { clientSchema } from "@schema/ModalClient/clientSchema";
import { MdEdit } from "react-icons/md";
import { WarningPanel } from "@components/InfoBox";
import { useHistory } from "react-router-dom";
import instance from "@services/base";
import FormSelect from "@components/Form/Select/FormSelect";
import { getProvinces } from "@utils/getProvinces";

const EditClient = ({
	client,
	leadId,
	clientId,
	leadType,
	isOpen,
	handleIsOpen,
	getDataclient,
	isWebClient
}) => {
	const { i18n, t } = useTranslation("modalClient");
	const [isDisabled, setIsDisabled] = useState(false);
	const [idClientExist, setIdClientExist] = useState(0);
	const [linkModalOpen, setLinkModalOpen] = useState(false);
	const [provinceOptions, setProvinceOptions] = useState([]);
	const formRef = useRef(null);
	const history = useHistory();
	const toggle = async () => {
		handleIsOpen(!isOpen);
	};

	useEffect(() => {
		getProvinces(setProvinceOptions, i18n.t("errors.province"));
		setIsDisabled(isWebClient);
	}, [isWebClient, i18n]);

	const defaultValues = {
		name: client.name || "",
		firstSurname: client.firstSurname || "",
		secondSurname: client.secondSurname || "",
		primaryPhone: client.contact?.primaryPhone,
		email: client.contact?.email || "",
		municipality: client?.address?.municipality || "",
		province: client.address?.province?.name
			? { label: client.address?.province?.name, value: client.address?.province?.id}
			: ""
	};

	const checkIsEditable = async (contact) => {
		try {
			const response = await postWithStatus(API.VERIFICATION_CLIENT_DATA(clientId), {
				email: contact.email,
				primaryPhone: contact.primaryPhone
			});

			return response.data;
		} catch (error) {
			errorToast(t("errors.checkCLient"));
		}
	};

	const linkLeadClientExist = async (newClientId) => {
		try {
			const response = await instance.put(API.LINK_LEAD_CLIENT(leadType, leadId, newClientId));
			if (response.status === 200 || response.status === 204) {
				successToast(t("leadLinkedExistingClient"));
				setLinkModalOpen(false);
				handleIsOpen(false);
				if (leadType === "purchase-leads") {
					getDataclient(client?.id);
				}
				if (leadType === "sell-leads") {
					history.push(`/main/lead-venta/${leadId}/${newClientId}`);
				}
			}
		} catch (error) {
			errorToast(t("errors.linkLeadClient"));
		}
	};

	const updateClient = async (payload) => {
		try {
			const response = await instance.put(API.UPDATE_CLIENT_DATA(clientId), payload);
			if (response.status === 200 || response.status === 204) {
				successToast(t("clientSuccessfullyUpdated"));
				getDataclient(clientId);
				handleIsOpen(false);
			}
		} catch (error) {
			errorToast(t("errors.updateClient"));
		}
	};

	const handleSubmit = async (data) => {

		const payload = {
			id: parseFloat(clientId),
			name: data.name.trim() || null,
			firstSurname: data.firstSurname.trim() || null,
			secondSurname: data.secondSurname.trim() || null,
			contact: {
				email: data.email.trim() || null,
				primaryPhone: data.primaryPhone
			},
			address: {
				province: {
					id: data.province?.value ? data.province.value : null,
					name: data.province?.label ? data.province.label : null
				},
				municipality: data.municipality.trim() || null
			}
		};
		if (clientId && leadId) {
			const response = await checkIsEditable(payload.contact);

			if (response) {
				if (response.isWebClient) {
					return setIsDisabled(true);
				}

				if (response.isEditable) {
					return updateClient(payload);
				}

				if (!response.isEditable && !response.isWebClient) {
					setIdClientExist(response.clientId);
					return setLinkModalOpen(true);
				}
			}
		}
	};

	return (
		<>
			<MdEdit
				color="white"
				size={30}
				role="button"
				onClick={toggle}
			/>
			<Modal
				className="modal-lg"
				centered
				isOpen={isOpen}
				toggle={toggle}
			>
				<ModalHeader>{t("editDataClient")}</ModalHeader>
				<ModalBody className="p-8">
					<Form
						ref={formRef}
						onSubmit={handleSubmit}
						defaultValues={defaultValues}
						schema={clientSchema[i18n.language]}
					>
						<div className="row p-4">
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="name"
									label={t("name")}
									disabled={isDisabled}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="firstSurname"
									label={t("firstSurname")}
									disabled={isDisabled}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="secondSurname"
									label={t("secondSurname")}
									disabled={isDisabled}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="primaryPhone"
									label={t("telephone")}
									disabled={isDisabled}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="email"
									label={t("email")}
									disabled={isDisabled}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormSelect
									name="province"
									placeholder={t("select")}
									label={t("province")}
									options={provinceOptions}
									isDisabled={isDisabled}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="municipality"
									label={t("municipality")}
									disabled={isDisabled}
								/>
							</div>
						</div>
						{isDisabled && (
							<div className="mt-4 mx-3">
								<WarningPanel text={t("contactNoEditable")} />
							</div>
						)}
					</Form>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-flexicar-inverse-size-s btn-flexicar-mobile"
						onClick={toggle}
					>
						{t("close")}
					</button>

					<button
						type="submit"
						onClick={() => formRef.current?.triggerSubmit()}
						className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
						disabled={isDisabled}
					>
						{t("save")}
					</button>
				</ModalFooter>
			</Modal>
			<Modal
				className="modal-lg"
				isOpen={linkModalOpen}
				centered
			>
				<ModalHeader>{t("linkClient")}</ModalHeader>
				<ModalBody>
					{t("clientAlreadyExists")}
					<p className="mt-4">
						<span className="font-weight-bold">{t("email")}: </span>
						{formRef.current?.getValues("email")}
					</p>
					<p>
						<span className="font-weight-bold">{t("telephone")}: </span>
						{formRef.current?.getValues("primaryPhone")}
					</p>
					{t("linkExistingCustomer")}
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-flexicar-inverse-size-s btn-flexicar-mobile"
						onClick={() => setLinkModalOpen(false)}
					>
						{t("cancel")}
					</button>
					<button
						type="submit"
						onClick={() => linkLeadClientExist(idClientExist)}
						className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
					>
						{t("link")}
					</button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default EditClient;
