export const AFTER_SALES_FILTER_STATE = Object.freeze({
	OPEN: "abiertos",
	APPOINTED: "citados",
	REPAIR_PREMIUM: "reparacion premium",
	AUTHORIZED: "autorizados",
	DENIED: "denegados",
	WARRANTY: "garantia",
	REPAIR_BASIC: "reparacion básica",
	INCIDENCE: "incidencias",
	DELIVERED: "entregados"
});

export const afterSalesFiltersConfig = [
	{
		filteri18n: "open",
		filter: AFTER_SALES_FILTER_STATE.OPEN,
		color: "#0d8500"
	},
	{
		filteri18n: "appointed",
		filter: AFTER_SALES_FILTER_STATE.APPOINTED,
		color: "#f1b746"
	},
	{
		filteri18n: "repair_premium",
		filter: AFTER_SALES_FILTER_STATE.REPAIR_PREMIUM,
		color: "#57ff3b"
	},
	{
		filteri18n: "authorized",
		filter: AFTER_SALES_FILTER_STATE.AUTHORIZED,
		color: "#88bfe7"
	},
	{
		filteri18n: "denied",
		filter: AFTER_SALES_FILTER_STATE.DENIED,
		color: "#ed0530"
	},
	{
		filteri18n: "warranty",
		filter: AFTER_SALES_FILTER_STATE.WARRANTY,
		color: "#2199e9"
	},
	{
		filteri18n: "repair_basic",
		filter: AFTER_SALES_FILTER_STATE.REPAIR_BASIC,
		color: "#68fff6"
	},
	{
		filteri18n: "incidence",
		filter: AFTER_SALES_FILTER_STATE.INCIDENCE,
		color: "#f45000"
	},
	{
		filteri18n: "delivered",
		filter: AFTER_SALES_FILTER_STATE.DELIVERED,
		color: "#f165fd"
	}
];

export const getAfterSalesColorByLeadState = (event) => {
	const id = event?.case?.stateId;
	const colors = {
		1: "#0d8500",
		2: "#f1b746",
		3: "#57ff3b",
		4: "#88bfe7",
		5: "#ed0530",
		6: "#2199e9",
		7: "#68fff6",
		8: "#f45000",
		9: "#f165fd"
	};

	return colors[id] ?? colors[1];
};

export const getAfterSalesTypeNameByLeadState = (event) => {
	const id = event?.case?.stateId;
	const state = {
		1: AFTER_SALES_FILTER_STATE.OPEN,
		2: AFTER_SALES_FILTER_STATE.APPOINTED,
		3: AFTER_SALES_FILTER_STATE.REPAIR_PREMIUM,
		4: AFTER_SALES_FILTER_STATE.AUTHORIZED,
		5: AFTER_SALES_FILTER_STATE.DENIED,
		6: AFTER_SALES_FILTER_STATE.WARRANTY,
		7: AFTER_SALES_FILTER_STATE.REPAIR_BASIC,
		8: AFTER_SALES_FILTER_STATE.INCIDENCE,
		9: AFTER_SALES_FILTER_STATE.DELIVERED
	};

	return state[id] ?? state[1];
};
