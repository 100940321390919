import React, { useContext } from "react";
import { MdVpnKey } from "react-icons/md";
import { Redirect } from "react-router";

import styles from "./index.module.scss";
import { UserContext } from "@contexts/UserContext";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import { loginSchema } from "@schema/loginSchema";
import { errorToast } from "@helpers/toastFunctions";

const Login = () => {
	const { isAuthenticated, logIn } = useContext(UserContext);
	if (isAuthenticated) return <Redirect to="/main/admin" />;

	const onSubmit = async (formData) => {
		try {
			await logIn(formData);
		} catch (err) {
			if (
				err.response?.status === 401 &&
				err.response?.data?.errorCode === "login.maxAttemptsExceeded"
			) {
				errorToast(
					"Usuario bloqueado. Por favor, contacta con un administrador para desbloquear el usuario."
				);
			} else {
				errorToast(err.response?.data?.message);
			}
		}
	};

	return (
		<div className={styles.loginWrapper}>
			<div>
				<Form onSubmit={onSubmit} schema={loginSchema}>
					<h2>Acceso</h2>
					<hr />
					<div className="d-flex flex-column mb-3">
						<FormInput
							name="email"
							label="Usuario"
							placeholder="Email"
						/>
					</div>
					<div className="d-flex flex-column mb-3">
						<FormInput
							name="password"
							label="Contraseña"
              type="password"
							placeholder="Contraseña"
						/>
					</div>
					<div className="mt-4 d-flex justify-content-end">
						<button className="btn-flexicar">
							<span className="font-weight-bold">CONECTAR</span>
							<MdVpnKey
								className="ml-2"
								style={{ verticalAlign: "top" }}
								size={22}
							/>
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default Login;
