import styles from "./index.module.scss";

/**
 * @typedef {Object} AdditionalClasses
 * @property {string} [wrapper] - Class for the card wrapper.
 * @property {string} [header] - Class for the card header.
 * @property {string} [contentTitle] - Class for the content title.
 * @property {string} [subtitle] - Class for the subtitle.
 * @property {string} [content] - Class for the content.
 * @property {string} [headerActions] - Class for the header actions.
 */

/**
 * A customizable card component.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.titleIcon - The icon or element to display on the title area of the card.
 * @param {string} props.title - The title or main text for the card.
 * @param {string} props.subtitle - The subtitle or secondary text for the card.
 * @param {string} props.content - The main content of the card.
 * @param {AdditionalClasses} [props.additionalClasses] - Additional class names.
 * @param {React.ReactNode} props.actions - Action buttons or elements to be displayed in the card header.
 *
 * @returns {React.ReactNode} The rendered Card component.
 */
const Card = ({ titleIcon, title, subtitle, content, actions, additionalClasses }) => (
	<div className={`${styles.cardWrapper} ${additionalClasses?.wrapper ?? ""}`}>
		<div className={`${styles.cardHeader} ${additionalClasses?.header ?? ""}`}>
			<div className={`${styles.cardContentTitle} ${additionalClasses?.contentTitle ?? ""}`}>
				{titleIcon}
				<span>{title}</span>
			</div>
			<span className={`${styles.cardSubtitle} ${additionalClasses?.subtitle ?? ""}`}>
				{subtitle}
			</span>
			<span className={`${styles.cardContent} ${additionalClasses?.content ?? ""}`}>{content}</span>
		</div>
		<div className={`${styles.cardHeaderActions} ${additionalClasses?.headerActions ?? ""}`}>
			{actions}
		</div>
	</div>
);

export default Card;
