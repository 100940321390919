export const yesOrNotSelect = [
	{ label: "Sí", value: true },
	{ label: "No", value: false }
];

export const insouranceOptions = [{ label: "Mutua", value: "Mutua" }];

export const transportSellTypeOptions = [
	{ label: "Presencial", value: "Presencial" },
	{ label: "Online", value: "Online" }
];

export const transportDeliveryTypeOptions = [
	{ label: "Tienda", value: "Tienda" },
	{ label: "Domicilio", value: "Domicilio" }
];

export const ivaTypeOptions = [
	{ label: "No deducible", value: true },
	{ label: "Deducible", value: false }
];

export const termOptions = [
	{ label: "12 meses", value: 12 },
	{ label: "24 meses", value: 24 },
	{ label: "36 meses", value: 36 },
	{ label: "48 meses", value: 48 },
	{ label: "60 meses", value: 60 },
	{ label: "72 meses", value: 72 },
	{ label: "84 meses", value: 84 },
	{ label: "96 meses", value: 96 },
	{ label: "108 meses", value: 108 },
	{ label: "120 meses", value: 120 }
];
