import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

import GenericModal from "@components/GenericModal";
import { WarningPanel } from "@components/InfoBox";
import Paginator from "@components/Paginator";
import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { formatSpanishFullDate } from "@helpers/formatDate";
import Loading from "@views/Loading/Loading";

const PAGE_SIZE = 8;

const HistoricalCaseActionModal = ({ isOpen, setIsOpen }) => {
	const { caseId } = useParams();
	const { t, ready } = useTranslation("caseHistoricalModal");

	const [isLoading, setIsLoading] = useState(true);

	const [historicalActionsData, setHistoricalActionsData] = useState({ total: 0, results: [] });
	const [currentPage, setCurrentPage] = useState(1);

	const loadData = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await instance.get(
				`api/post-venta/cases/${caseId}/status-history?page=${currentPage}&size=${PAGE_SIZE}`
			);
			setHistoricalActionsData(response.data);
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.load"));
		} finally {
			setIsLoading(false);
		}
	}, [t, caseId, currentPage]);

	useEffect(() => {
		if (ready) {
			loadData();
		}
	}, [ready, loadData]);

	const body = (
		<div className={styles.wrapper}>
			{historicalActionsData.results.length > 0 ? (
				<>
					<table className={styles.historial}>
						<thead>
							<tr>
								<th>{t("table.users")}</th>
								<th>{t("table.date")}</th>
								<th>{t("table.state")}</th>
							</tr>
						</thead>

						<tbody>
							{historicalActionsData.results.map((x, idx) => (
								<tr key={`historical-actions-${idx}`}>
									<td>{x.agent.fullName}</td>
									<td>{formatSpanishFullDate(x.updatedAt)}</td>
									<td>{x.newStatus.name}</td>
								</tr>
							))}
						</tbody>
					</table>
					<Paginator
						className={styles.pagination}
						pageSize={PAGE_SIZE}
						totalCount={historicalActionsData.total}
						currentPage={currentPage}
						onPageChange={(page) => setCurrentPage(page)}
					/>
				</>
			) : (
				<WarningPanel text={t("noData")} />
			)}
		</div>
	);

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			headContent="Historial de acciones"
			bodyContent={isLoading || !ready ? <Loading /> : body}
		/>
	);
};

export default HistoricalCaseActionModal;
