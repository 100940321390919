import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalWarning = ({ okButton, cancelButton, open }) => {
  return (
    <div>
      <Modal isOpen={open}>
        <ModalHeader>Alerta</ModalHeader>
        <ModalBody>
          La operación que va a realizar no puede revertirse
        </ModalBody>
        <ModalFooter>
          <Button className="btn-flexicar-orange" onClick={okButton}>
            Continuar
          </Button>
          <Button className="btn-flexicar-inverse-size-s" onClick={cancelButton}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalWarning