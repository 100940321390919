import axios from 'axios';
import { API } from "@services/urlConstants";
import { environment } from "@config/environment.config";

export const GetTextTranslate = async (dataToTranslate, targetLanguage) => {

    try {
        const translationRequests = Object.entries(dataToTranslate).map(([key, value]) => {
            return axios.post(API.GOOGLE_TRANSLATE(environment.API_KEY_GOOGLE_TRANSLATE), {
                q: value,
                target: targetLanguage,
                format: 'text'
            });
        });

        const responses = await Promise.all(translationRequests);

        const translatedData = responses.reduce((acc, response, index) => {
            const key = Object.keys(dataToTranslate)[index];
            acc[key] = response.data.data.translations[0].translatedText;
            return acc;
        }, {});

        return translatedData;

    } catch (error) {
        console.error(error);
    }
};

