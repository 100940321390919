const colors = {
  primary: "rgba(255,93,14)",
  disable: "rgb(200, 200, 200)",
  secondary: "rgb(224, 49, 157)",
  secondaryLow: "rgba(255,80,97,0.1)",
  white: "rgb(255,255,255)",
  greyTable: "rgba(242,242,242,0.5)",
};

export default colors;
