import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "./index.module.scss";
import { getVideos, patchVideo } from "@services/youtubeApi.service";
import {
  getYoutubeVideoId,
  defaultRoles,
} from "@views/VideoTutorial/helpers/videoTutorials";
import YoutubeEmbed from "@views/VideoTutorial/components/YoutubeEmbeded";
import Select from "@components/Select";
import { getFilterRoles } from "@services/Filtros/filtros";

const UpdateVideoModal = (props) => {
  const {
    modalUpdateVideo,
    modalUpdateVideoHandler,
    videoData,
    onVideosHandler,
    adminRoles,
  } = props;
  
  const [roles, setRoles] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    delete data.url;
    Object.assign(data, {
      roles: defaultRoles(roles, adminRoles),
    });

    patchVideo(`/videos/${videoData.id}`, data)
      .then((response) => {
        getVideos(`/videos`)
          .then((response) => {
            modalUpdateVideoHandler();
            onVideosHandler(response.data);
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  useEffect(() => {
    reset({
      title: videoData ? videoData.title : "",
      description: videoData ? videoData.description : "",
      url: videoData ? videoData.url : "",
    });
	setRoles([]);
  }, [videoData,reset]);

  useEffect(() => {
    if(videoData) {
      setRoles(videoData.roles);

      const url = getYoutubeVideoId(videoData.url);
      setVideoUrl(url);
    }
  }, [videoData]);

  return (
    <div>
      <Modal isOpen={modalUpdateVideo} toggle={modalUpdateVideoHandler}>
        <ModalHeader
          toggle={modalUpdateVideoHandler}
          className={styles.modal_header}
        >
          Editar Video
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={styles.container}>
                <input
                  className={`${errors.title && "is-invalid"}`}
                  type="text"
                  placeholder="Título"
                  {...register("title", { required: true })}
                />
                {errors.title?.type === "required" && (
                  <div className="invalid-feedback">Introduzca título</div>
                )}

                <textarea
                  className={`${errors.title && "is-invalid"}`}
                  placeholder="Descripción"
                  {...register("description", { required: true })}
                />
                {errors.description?.type === "required" && (
                  <div className="invalid-feedback">
                    Introduzca descripción del video
                  </div>
                )}
                
                <input
                  className={`${errors.title && "is-invalid"}`}
                  type="url"
                  placeholder="url"
                  {...register("url", { required: true })}
                  readOnly
                />

                <Select
                  isMulti
                  placeholder="Roles"
                  loadOptions={getFilterRoles}
                  value={roles}
                  onChange={(opts) => setRoles(opts)}
                />

				<YoutubeEmbed
					embedId={videoUrl}
				/>
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={styles.btn_custom}
            onClick={handleSubmit(onSubmit)}
          >
            Editar
          </Button>{" "}
          <Button color="secondary" onClick={modalUpdateVideoHandler}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdateVideoModal;
