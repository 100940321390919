import React, { useState, useEffect, useContext, useCallback } from "react";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
} from "reactstrap";

import moment from "moment";

import { errorToast, successToast } from "@helpers/toastFunctions";

import { UserContext } from "@contexts/UserContext";
import validationDNI from "@helpers/validationDNI";
import validationNIE from "@helpers/validationNIE";
import validationCIF from "@helpers/validationCIF";
import validationEmail from "@helpers/validationEmail";
import Step3 from "@views/LeadVenta/ModalReservar/Step3/Step3";
import { useTranslation } from "react-i18next";

const ModalReservar = ({ setModal, status, lead, client }) => {
  const { t } = useTranslation("modalReservar");
  const { rol, user } = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [payType, setPayType] = useState(null);
  const [payOpt, setPayOpt] = useState({
    coche: null,
    financia: null,
    banco: null,
  });
  const [data, setData] = useState({
    dni: "",
    telefono: "",
    reserva: 500,
    precio: 0,
    km: "",
    email: "",
    nombre: "",
    apellido1: "",
    apellido2: "",
    vehiculo: "",
    matricula: "",
    concepto: "",
    notas: "",
  });
  const [companie, setCompanie] = useState(null);
  const [activeBtn1, setActiveBtn1] = useState(true);
  const [activeBtn2, setActiveBtn2] = useState(false);
  const [activeBtn3, setActiveBtn3] = useState(true);
  const [grabacionText, setGrabacionText] = useState(false);
  const [modalData, setModalData] = useState(null);
  const localRoles = [1, 6, 7, 9];

  const getLocalEmpresas = useCallback(async () => {
		const response = await post(API.GET_EMPRESAS_BY_LOCAL, {
			id_local: lead.id_local_for_marketplace
		});
		setCompanie(response[0]);
	}, [lead]);

  const getleadData = useCallback(async () => {
    setData({
      dni: "",
      telefono: client?.contact.primaryPhone ,
      reserva: 500,
      precio: lead.pvp_financiado ? +lead.pvp_financiado : +lead.pvp_contado,
      km: lead.kilometros.replaceAll(".", ""),
      email: client?.contact?.email,
      nombre: client?.name,
      apellido1: client?.firstSurname,
      apellido2: client?.secondSurname,
      vehiculo: lead.nombre_marca_v + " " + lead.nombre_modelo_v,
      matricula: lead.matricula,
      concepto:
        "Reserva " +
        lead.nombre_marca_v +
        " " +
        lead.nombre_modelo_v +
        " " +
        lead.matricula,
      notas: "",
      bastidor: lead.bastidor
    });
  }, [client, lead]);

  useEffect(() => {
    if (status && lead && !companie) {
      getLocalEmpresas();
      getleadData();
    }
  }, [status, lead, companie, getLocalEmpresas, getleadData]);

  const setStep1 = (value) => {
    setPayType(value);
    setStep(2);
  };

  const setStep2 = (label, value) => {
    setPayOpt({
      ...payOpt,
      [label]: value,
    });
    if (label === "banco") {
      setStep(3);
    }
    if (payOpt.coche != null && payOpt.financia != null && payOpt.banco != null) {
      setStep(3);
    }
    if (label === "financia" && value === 1) {
      setStep(3);
    }
  };

  const validateSpanishID = (dni) => {
    if (validationDNI(dni)) {
      return true;
    }
    if (validationCIF(dni)) {
      return true;
    }
    if (validationNIE(dni)) {
      return true;
    }
    return false;
  };

  const checkReservaData = async (tipo) => {
    if (tipo === 1) {
      setActiveBtn1(false);
    } else if (tipo === 2) {
      setActiveBtn2(false);
    } else if (tipo === 3 || tipo === 4) {
      setActiveBtn3(false);
    }
    let err = false;

    Object.keys(data).forEach((key) => {
      if ((!data[key] || data[key] === "") && key != "notas") {
        err = true;
        setActiveBtn1(true);
        return errorToast(<span>{t("errors.errorInput")} {key}</span>);
      }
    });
    if (err) {
      setActiveBtn3(true);
      return;
    }
    if (!companie || !companie.label || !companie.value) {
      return errorToast(<span>{t("errors.errorSelect")}</span>);
    }
    const nombre_empresa = companie.label;
    const id_empresa = companie.value;

    let d = new Date();
    let ano = d.getFullYear();
    let dia = d.getDate();
    let mes = d.getMonth() + 1;
    let horas = d.getHours();
    let minutos = d.getMinutes();
    let segundos = d.getSeconds();
    let now =
      ano +
      "-" +
      mes +
      "-" +
      dia +
      " " +
      horas +
      ":" +
      minutos +
      ":" +
      segundos;

    if (mes < 10) {
      mes = "0" + mes;
    }
    if (dia < 10) {
      dia = "0" + dia;
    }

    let random_numb = Number(Math.random() * 100000)
      .toFixed()
      .toString();

    let id_unico = data.matricula + data.dni + dia + mes + ano + random_numb;

    const validateEmail = validationEmail(data.email);
    if (!validateEmail) {
      setActiveBtn1(true);
      return errorToast(<span>{t("errors.errorEmail")}</span>);
    }

    const validateDNI = validateSpanishID(data.dni);
    if (!validateDNI) {
      setActiveBtn1(true);
      return errorToast(<span>{t("errors.errorDni")}</span>);
    }

    if (data.matricula.length < 6 || data.matricula.length > 8) {
      setActiveBtn1(true);
      return errorToast(<span>{t("errors.errorPlate")}</span>);
    }

    if (payOpt.banco == null) {
      setPayOpt({
        ...payOpt,
        banco: 0,
      });
    }

    if (payType == null || payOpt.coche == null || payOpt.financia == null) {
      return errorToast(<span>{t("errors.errorStep")}</span>);
    }

    let datos = {
      nombre: data.nombre,
      apellido1: data.apellido1,
      apellido2: data.apellido2,
      dni: data.dni,
      telefono: data.telefono,
      kilometros: data.km,
      pvp_pago: data.precio,
      email: data.email,
      vehiculo: data.vehiculo,
      matricula: data.matricula,
      concepto: data.concepto,
      importe: data.reserva,
      id_lead: lead.id_lead,
      id_cliente: lead.id_cliente_lead,
      id_vehiculo: lead.id,
      sociedad: lead.nombre_sede_v,
      pago_forma: payType,
      pago_vehiculo: payOpt.coche,
      financia: payOpt.financia,
      financia_banco: payOpt.banco,
      fecha_alta: now,
      id_unico,
      vehicle_id: lead.link_a_vehiculo,
      observaciones: data.notas,
      nombre_empresa,
      id_empresa,
      bastidor: data.bastidor,
    };

    if (tipo === 1) {
      return createDocumentation(datos, 1);
    } else if (tipo === 2 || tipo === 4) {
      if (payType === "pasarela") {
        const getTPV = await post(API.GET_TPV_VIRTUAL, {
          id_local: lead.id_local_for_marketplace,
        });
        if (getTPV && getTPV[0] && getTPV[0].tpv) {
          datos.cif_empresa = getTPV[0].tpv;
          grabacionPasarela(datos, tipo);
        } else {
          return errorToast(<span>{t("errors.errorTpv")}</span>);
        }
      } else daveDataReserva(datos);
    } else if (tipo === 3) {
      if (datos.pago_forma === "pasarela" || datos.pago_forma === "contado") {
        createDocumentation(datos, 3, datos.pago_forma);
      } else {
        return createDocumentation(datos, 2, datos.pago_forma);
      }
    }
  };

  const grabacionPasarela = async (datos, tipo) => {
    if (datos.pago_forma === "pasarela") {
      let dni = datos.dni;
      if (dni.charAt(0) === "0") {
        dni = dni.substr(1);
      }
      const datos3 = {
        concepto: datos.concepto,
        id_sociedad: datos.cif_empresa,
        url_vehiculo: lead.URL,
        matricula: datos.matricula,
        importe: datos.importe,
        nif: dni,
        email: datos.email,
        movil: datos.telefono,
        envio_email: true,
        envio_SMS: true,
        id_unico: datos.id_unico,
      };

      try {
        const pagoFacilCall = await post(API.PAGOFACIL_CALL, { datos: datos3 });
        if (pagoFacilCall && pagoFacilCall.codigo) {
          if (pagoFacilCall.codigo === "00") {
            daveDataReserva(datos);
          } else if (pagoFacilCall === "61") {
            return errorToast(
              <span>
                {t("errors.errorReserve")}
              </span>
            );
          } else {
            if (tipo === 2) {
              setActiveBtn2(true);
            } else {
              setActiveBtn3(true);
            }
            return errorToast(<span>{pagoFacilCall.msj}</span>);
          }
        } else {
          if (tipo === 2) {
            setActiveBtn2(true);
          } else {
            setActiveBtn3(true);
          }
          return errorToast(<span>{t("errors.errorCreateReserve")}</span>);
        }
      } catch (e) {
        errorToast(<span>{e.message}</span>);
      }
    }
  };

  const daveDataReserva = async (datos) => {
    datos.id_agente = user;

    try {
      const createReservation = await post(API.CREATE_RESERVATION, { datos });

      if (createReservation) {
        setModal();
        successToast(<span>Reserva creada correctamente</span>);
        return window.location.reload();
      }
    } catch (e) {
      setActiveBtn2(true);
      return errorToast(<span>{t("errors.errorCreateReserveKo")}</span>);
    }
  };

  const createDocumentation = async (data, tipo, pago_forma) => {
    let datos = {
      nombre: data.nombre,
      apellido1: data.apellido1,
      apellido2: data.apellido2,
      vehiculo: data.vehiculo,
      matricula: data.matricula,
      dni: data.dni,
      telefono: data.telefono,
      email: data.email,
      importe: data.importe,
      kilometros: data.kilometros,
      pvp: data.pvp_pago,
      observaciones: data.observaciones,
      forma_pago: data.pago_forma,
      bastidor: data.bastidor
    };

    let vehiculo = data.vehicle_id;
    let lead = data.id_lead;
    let cliente = data.id_cliente;

    const company = await post(API.GET_EMPRESA_BY_ID, { id: data.id_empresa });
    if (company && company.res) {
      datos.empresa_cif = company.res.CIF;
      datos.empresa_direccion = company.res.direccion;
      datos.empresa = company.res.nombre;
      datos.empresa_provincia = company.res.provincia;
      datos.sello = company.res.sello;

      try {
        const createDocument = await post(API.CREATE_RESERVATION_DOCUMENT, {
          vehiculo,
          lead,
          cliente,
          datos: JSON.stringify(datos),
          agente: user
        });

        if (createDocument) {
          if (tipo === 3) {
            return modalGrabacion(pago_forma, data.CIF, datos);
          } else if (tipo === 2) {
            return checkReservaData(4);
          }
          setActiveBtn2(true);
        }
      } catch (e) {
        setActiveBtn1(true);
        return errorToast(<span>{t("errors.errorCreateDocumentKo")}</span>);
      }
    } else {
      return errorToast(<span>{t("errors.errorCompany")}</span>);
    }
  };

  const modalGrabacion = async (pago_forma, cif, datos) => {
    const getTPV = await post(API.GET_TPV_VIRTUAL, {
      id_local: lead.id_local_for_marketplace,
    });
    if (getTPV && getTPV[0] && getTPV[0].tpv) {
      datos.cif_empresa = getTPV[0].tpv;
      setModalData(datos);
      setGrabacionText(true);
    } else {
      return errorToast(<span>{t("errors.errorTpv")}</span>);
    }
  };

  return (
    <Modal
      className="modal-lg"
      centered
      isOpen={status}
      toggle={() => setModal()}
    >
      <ModalHeader>
        Reservar {lead?.nombre_marca_v} {lead?.nombre_modelo_v}{" "}
        {lead?.matricula}
      </ModalHeader>
      <ModalBody className="p-4">
        {!grabacionText ? (
          <>
            <Progress multi>
              <Progress
                onClick={() => setStep(1)}
                bar
                style={{ cursor: "pointer" }}
                color="success"
                value="33"
                max={55}
              >
                Paso 1
              </Progress>
              <Progress
                onClick={step === 3 ? () => setStep(2) : undefined}
                bar
                style={{
                  cursor: step === 3 ? "pointer" : "initial",
                  borderLeft: "1px solid black",
                  backgroundColor: step === 1 ? "#979797" : "#28a745",
                }}
                value="33"
                max={55}
              >
                Paso 2
              </Progress>
              <Progress
                bar
                style={{
                  borderLeft: "1px solid black",
                  backgroundColor: step === 3 ? "#28a745" : "#979797",
                }}
                value="33"
                max={55}
              >
                Paso 3
              </Progress>
            </Progress>
            {step === 1 ? (
              <>
                <h4 className="mt-5 centerXY">Selecciona forma de pago</h4>
                <div className="centerXY">
                  <button
                    className={`btn btn-light btn-outline-dark ${payType === "pasarela"
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep1("pasarela")}
                  >
                    Pasarela
                  </button>
                  <button
                    className={`btn btn-light btn-outline-dark ${payType === "presencial"
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep1("presencial")}
                  >
                    Presencial
                  </button>
                  <button
                    className={`btn btn-light btn-outline-dark ${payType === "contado"
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep1("contado")}
                  >
                    Transferencia
                  </button>
                </div>
              </>
            ) : step === 2 ? (
              <>
                <h6 className="mt-5 centerXY">¿Coche como forma de pago?</h6>
                <div className="centerXY">
                  <button
                    className={`btn btn-light btn-outline-dark ${payOpt.coche === 1
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep2("coche", 1)}
                  >
                    Sí
                  </button>
                  <button
                    className={`btn btn-light btn-outline-dark ${payOpt.coche === 0
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep2("coche", 0)}
                  >
                    No
                  </button>
                </div>
                <h6 className="mt-2 centerXY">¿Desea financiar?</h6>
                <div className="centerXY">
                  <button
                    disabled={
                      payOpt.coche === 1 || payOpt.coche === 0 ? false : true
                    }
                    className={`btn btn-light btn-outline-dark ${payOpt.financia === 1
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep2("financia", 1)}
                  >
                    Sí
                  </button>
                  <button
                    disabled={
                      payOpt.coche === 1 || payOpt.coche === 0 ? false : true
                    }
                    className={`btn btn-light btn-outline-dark ${payOpt.financia === 0
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep2("financia", 0)}
                  >
                    No
                  </button>
                </div>

                <h6 className="mt-2 centerXY">
                  ¿Su banco ya le ofrece financiación?
                </h6>
                <div className="centerXY">
                  <button
                    disabled={
                      (payOpt.coche === 1 || payOpt.coche === 0) &&
                        (payOpt.financia === 1 || payOpt.financia === 0)
                        ? false
                        : true
                    }
                    className={`btn btn-light btn-outline-dark ${payOpt.banco === 1
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep2("banco", 1)}
                  >
                    Sí
                  </button>
                  <button
                    disabled={
                      (payOpt.coche === 1 || payOpt.coche === 0) &&
                        (payOpt.financia === 1 || payOpt.financia === 0)
                        ? false
                        : true
                    }
                    className={`btn btn-light btn-outline-dark ${payOpt.banco === 0
                        ? "btn-flexicar-inverse-active"
                        : "btn-flexicar-inverse"
                      }`}
                    onClick={() => setStep2("banco", 0)}
                  >
                    No
                  </button>
                </div>
              </>
            ) :
              <Step3
                data={data}
                rol={rol}
                companie={companie}
                setCompanie={setCompanie}
                localRoles={localRoles}
                activeBtn1={activeBtn1}
                activeBtn2={activeBtn2}
                activeBtn3={activeBtn3}
                lead={lead}
                setData={setData}
                checkReservaData={checkReservaData}
              ></Step3>
            }
          </>
        ) : (
          <>
            ▶️ Iniciar grabación
            <br />
            <br />
            ¿Acepta que grabemos la conversación?
            <br />
            [Esperar respuesta del cliente]
            <br />
            En Madrid a {moment().format("DD/MMM/YYYY")}.<br />
            D./Dª: <b>{modalData?.nombre}</b> <b>{modalData?.apellido1}</b>{" "}
            <b>{modalData?.apellido2}</b> con DNI/NIE <b>{modalData?.dni}</b> ,
            teléfono <b>{modalData?.telefono}</b> y email{" "}
            <b>{modalData?.email}</b> entrega a{" "}
            <b>{modalData?.empresa}</b> con CIF{" "}
            <b>{modalData?.cif_empresa}</b> el importe de{" "}
            <b>{modalData?.importe}</b> euros a través del{" "}
            {payType === "pasarela"
              ? "pago con tarjeta de crédito"
              : "pago por transferencia bancaria"}
            , en concepto de reserva del vehículo: <b>{modalData?.vehiculo}</b>{" "}
            con matrícula <b>{modalData?.matricula}</b> y{" "}
            <b>{modalData?.kilometros}</b> kilómetros.
            <br />
            La validez de la reserva tiene una duración de 7 días a partir de
            hoy. En el caso de no abonar la cantidad final en dicho plazo,
            siempre y cuando sea por motivos ajenos a nuestra gestión, la
            reserva perderá su validez y <b>{modalData?.empresa}</b> se
            guarda el derecho de devolución del importe de la misma, quedando
            exento de reclamación alguna.
            <br />
            ¿Acepta estas condiciones?
            <br />
            <br />
            [Esperar respuesta del cliente]
            <br />
            <br />
            🛑 Finalizar grabación
            <br />
            <br />
            <button
              type="button"
              className="btn btn-light btn-outline-dark btn-flexicar"
              onClick={() => {
                setGrabacionText(false);
                setActiveBtn3(true);
              }}
            >
              Modificar datos
            </button>
            <button
              type="button"
              className="btn btn-light btn-outline-dark btn-flexicar"
              onClick={() => checkReservaData(4)}
            >
              Finalizar reserva
            </button>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-light btn-outline-dark btn-flexicar"
          onClick={() => setModal()}
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalReservar;
