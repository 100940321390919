import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModaLSignatureWithoutPhoto = ({ open, setModal, getPDF }) => {
  const { t } = useTranslation("myDocumentation");
  return (
    <div>
      <Modal isOpen={open}>
        <ModalHeader>{t("contractPdf.verifyIdentity")}</ModalHeader>
        <ModalBody>{t("contractPdf.importantMessage")}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              getPDF(2, 1);
              setModal(false);
            }}
          >
            {t("contractPdf.accept")}
          </Button>
          <Button color="secondary" onClick={() => setModal(false)}>
            {t("contractPdf.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModaLSignatureWithoutPhoto;
