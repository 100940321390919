import React from "react";
import { Row } from "reactstrap";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const PaginationOtherLeads = ({
  statePagination,
  totalItems,
  itemsPerPage,
  setStatePagination
}) => {
  const nextPage = () => setStatePagination(statePagination + itemsPerPage);

  const prevPage = () => {
    if (statePagination > 0) setStatePagination(statePagination - itemsPerPage);
  };

  return (
    <div>
      <Row className="justify-content-center mt-3">
        <ul className="pagination">
          <li
            onClick={() => prevPage()}
            className={
              "waves_effect " + (statePagination <= 0 ? "inactive" : "pointer")
            }
          >
            <div>
              <MdChevronLeft size={25} />
            </div>
          </li>
          {totalItems === 0 ? 0 : statePagination / itemsPerPage + 1} /
          {Math.ceil(totalItems / itemsPerPage)}
          <li
            onClick={() => nextPage()}
            className={
              "waves_effect " +
              (statePagination >= totalItems - itemsPerPage
                ? "inactive"
                : "pointer")
            }
          >
            <MdChevronRight size={25} />
          </li>
        </ul>
      </Row>
    </div>
  );
};

export default PaginationOtherLeads;
