import { IoClose } from "react-icons/io5";

import "@styles/components/multi-select-chips-list.scss";

/**
 * A component that displays a list of chips for each elemets that recives.
 *
 * @param {Object} props - The component props.
 * 
 * @param {Array<string>} props.dropdownSelected - An array of strings of dropdown values selected.
 * @param {function} props.deleteDropdownSelected - Function that deletes each chip by clicking.

 * @returns {React.ReactNode} The rendered React component.
 */

const MultiSelectChipsList = ({
    dropdownSelected,
    deleteDropdownSelected,
}) => {

    return (
        <>
            {dropdownSelected.map((item, idx) => (
                <div className="chipContainer" key={item+idx}>
                    {item}
                    {deleteDropdownSelected && <span
                        className="react-dropdown-select-option-remove pointer ml-1"
                        onClick={() => deleteDropdownSelected(item, idx)}
                    >
                        <IoClose size={14} />
                    </span>}
                </div>
            ))}
        </>
    );
};

export default MultiSelectChipsList;