import { FiAlertCircle } from "react-icons/fi";

import styles from "./index.module.scss";

const FormError = ({ error }) =>
	error ? (
		<p className={styles.customSelectError}>
			<FiAlertCircle size={20} />
			{error.message}
		</p>
	) : null;

export default FormError;
