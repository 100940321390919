import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DropdownStandar = ({ data, selected, handleSelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const changeData = async (e) => await handleSelect(e);

  return (
    <div>
      {data && data.length > 0 ? (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret>
            {selected ? selected.dropdownInfo : "Seleccione uno"}
          </DropdownToggle>
          <DropdownMenu>
            {data.map((e, index) => (
              <DropdownItem key={index}>
                <div onClick={() => changeData(e)}> {e.dropdownInfo}</div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      ) : (
        <p>No hay resultados</p>
      )}
    </div>
  );
};

export default DropdownStandar;
