import { useEffect, useRef, useState } from "react";

const useIsOverflowed = (text) => {
	const containerRef = useRef(null);
	const [isOverflowed, setIsOverflowed] = useState(false);

	useEffect(() => {
		const container = containerRef.current;
		const resizeObserver = new ResizeObserver(() => {
			const container = containerRef.current;

			container.scrollWidth > container.offsetWidth
				? setIsOverflowed(true)
				: setIsOverflowed(false);
		});

		resizeObserver.observe(container);

		return () => {
			resizeObserver.disconnect();
		};
	}, [text]);

	return { containerRef, isOverflowed };
};

export default useIsOverflowed;
