import { get, post } from "@services/axiosCalls";

export const getLocals = async (id) => {
  const response = await get(`api/employee/${id}/locales`);
  return response;
};

export const getEmployeeType = async (id) => {
  const response = await get(`api/employee/${id}/types`);
  return response;
};

export const getBrands = async () => {
  const response = await get(`api/commercial-brands`);
  return response;
};

export const getCompanies = async () => {
  const response = await get(`api/companies`);
  return response;
};

export const getPersons = async (id) => {
  const response = await get(`api/employee/${id}/greater-type-names`);
  return response;
};

export const getPersonInfo = async (id) => {
  const response = await get(`api/person/${id}`);
  return response;
};

export const createPerson = async (data) => {
  const response = await post(`api/person`, data);
  return response;
}

export const createEmployee = async (data) => {
  const response = await post(`api/employee`, data);
  return response;
};

export const addLocaleForEmployee = async (id, data) => {
  const response = await post(`api/employee/${id}/locales`, data);
  return response;
}

export const addCompaniesForEmployee = async (id, data) => {
  const response = await post(`api/employee/${id}/companies`, data);
  return response;
}

export const addCommercialBrandsForEmployee = async (id, data) => {
  const response = await post(`api/employee/${id}/commercialBrands`, data);
  return response;
}

export const searchPersonsCall = async (input) => {
  let queryParameters = "";
  if (input.nombre) {
    queryParameters += `name=${input.nombre}`;
  }
  if (input.apellido1) {
    queryParameters += `&surname1=${input.apellido1}`;
  }
  if (input.apellido2) {
    queryParameters += `&surname2=${input.apellido2}`;
  }
  const response = await get(`api/person/search?${queryParameters}`);
  return response;
};
