import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const scheduleDefault = {
  1: [{ start: "09:30", end: "20:30" }],
  2: [{ start: "09:30", end: "20:30" }],
  3: [{ start: "09:30", end: "20:30" }],
  4: [{ start: "09:30", end: "20:30" }],
  5: [{ start: "09:30", end: "20:30" }],
  6: [{ start: "09:30", end: "20:30" }],
  0: [{ start: "--:--", end: "--:--" }],
};

const SchedulesDealerModal = (props) => {
  const {
    newDealer,
    setNewDealerHandler,
    modalSchedules,
    setModalSchedules,
    setModalAddress,
    setModalOptionalDealer,
  } = props;

  const { register, handleSubmit, reset } = useForm({});

  useEffect(() => {
    reset({
      lunes0: newDealer.schedule
        ? newDealer.schedule[1][0].start
        : scheduleDefault[1][0].start,
      lunes1: newDealer.schedule
        ? newDealer.schedule[1][0].end
        : scheduleDefault[1][0].end,
      martes0: newDealer.schedule
        ? newDealer.schedule[2][0].start
        : scheduleDefault[2][0].start,
      martes1: newDealer.schedule
        ? newDealer.schedule[2][0].end
        : scheduleDefault[2][0].end,
      miercoles0: newDealer.schedule
        ? newDealer.schedule[3][0].start
        : scheduleDefault[3][0].start,
      miercoles1: newDealer.schedule
        ? newDealer.schedule[3][0].end
        : scheduleDefault[3][0].end,
      jueves0: newDealer.schedule
        ? newDealer.schedule[4][0].start
        : scheduleDefault[4][0].start,
      jueves1: newDealer.schedule
        ? newDealer.schedule[4][0].end
        : scheduleDefault[4][0].end,
      viernes0: newDealer.schedule
        ? newDealer.schedule[5][0].start
        : scheduleDefault[5][0].start,
      viernes1: newDealer.schedule
        ? newDealer.schedule[5][0].end
        : scheduleDefault[5][0].end,
      sabado0: newDealer.schedule
        ? newDealer.schedule[6][0].start
        : scheduleDefault[6][0].start,
      sabado1: newDealer.schedule
        ? newDealer.schedule[6][0].end
        : scheduleDefault[6][0].end,
      domingo0: newDealer.schedule
        ? newDealer.schedule[0][0].start
        : scheduleDefault[0][0].start,
      domingo1: newDealer.schedule
        ? newDealer.schedule[0][0].end
        : scheduleDefault[0][0].end,
    });
  }, [newDealer, reset]);

  const onSubmit = (data) => {
    let schedule = {
      1: [
        {
          start: data.lunes0,
          end: data.lunes1,
        },
      ],
      2: [
        {
          start: data.martes0,
          end: data.martes1,
        },
      ],
      3: [
        {
          start: data.miercoles0,
          end: data.miercoles1,
        },
      ],
      4: [
        {
          start: data.jueves0,
          end: data.jueves1,
        },
      ],
      5: [
        {
          start: data.viernes0,
          end: data.viernes1,
        },
      ],
      6: [
        {
          start: data.sabado0,
          end: data.sabado1,
        },
      ],
      0: [
        {
          start: data.domingo0,
          end: data.domingo1,
        },
      ],
    };

    setNewDealerHandler({
      ...newDealer,
      schedule,
    });

    setModalSchedules(false);
    setModalOptionalDealer();
  };

  const handleBack = () => {
    setModalSchedules(false);
    setModalAddress();
  };

  const cancelHandler = () => {
    setModalSchedules(false);
    setNewDealerHandler({
      id: undefined,
      nombre: undefined,
      tpv: undefined,
      local_type: undefined,
      email: undefined,
      telefono: undefined,
      movil: undefined,
      direccion: undefined,
      coordinate_longitude: undefined,
      coordinate_latitude: undefined,
      schedule: undefined,
      slug: undefined,
      society: undefined,
      administrationPhone: undefined,
      administrationEmail: undefined,
      encargadoLocal: undefined,
    });
  };

  return (
    <div>
      <Modal isOpen={modalSchedules}>
        <ModalHeader className={styles.modal_header} toggle={cancelHandler}>
          {"Horarios. Paso 3 de 4"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row className="align-items-center justify-content-center border-bottom border-top">
                <Col className="col-2 mr-4">
                  <h6>Lunes:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[1][0].start
                        : scheduleDefault[1][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("lunes0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[1][0].end
                        : scheduleDefault[1][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("lunes1")}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center border-bottom">
                <Col className="col-2 mr-4">
                  <h6>Martes:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[2][0].start
                        : scheduleDefault[2][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("martes0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[2][0].end
                        : scheduleDefault[2][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("martes1")}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center border-bottom">
                <Col className="col-2 mr-4">
                  <h6>Miércoles:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[3][0].start
                        : scheduleDefault[3][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("miercoles0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[3][0].end
                        : scheduleDefault[3][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("miercoles1")}
                  />
                </Col>
              </Row>

              <Row className="align-items-center justify-content-center border-bottom">
                <Col className="col-2 mr-4">
                  <h6>Jueves:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[4][0].start
                        : scheduleDefault[4][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("jueves0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[4][0].end
                        : scheduleDefault[4][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("jueves1")}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center border-bottom">
                <Col className="col-2 mr-4">
                  <h6>Viernes:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[5][0].start
                        : scheduleDefault[5][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("viernes0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[5][0].end
                        : scheduleDefault[5][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("viernes1")}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center border-bottom">
                <Col className="col-2 mr-4">
                  <h6>Sábado:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[6][0].start
                        : scheduleDefault[6][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("sabado0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[6][0].end
                        : scheduleDefault[6][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("sabado1")}
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center">
                <Col className="col-2 mr-4">
                  <h6>Domingo:</h6>
                </Col>
                <Col className="col-3">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[0][0].start
                        : scheduleDefault[0][0].start
                    }
                    type="time"
                    bsSize="sm"
                    {...register("domingo0")}
                  />
                </Col>
                <Col className="col-2">
                  <input
                    className={`${styles.custom_input}`}
                    defaultValue={
                      newDealer.schedule
                        ? newDealer.schedule[0][0].end
                        : scheduleDefault[0][0].end
                    }
                    type="time"
                    bsSize="sm"
                    {...register("domingo1")}
                  />
                </Col>
              </Row>
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Row className="justify-content-center">
            <Button className="mx-5" color="secondary" onClick={handleBack}>
              Atras
            </Button>
            <Button
              className={`${styles.btn_custom} mx-5`}
              onClick={handleSubmit(onSubmit)}
            >
              Siguiente
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SchedulesDealerModal;
