import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useSearchParam = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const getSearchParam = (param) => searchParams.get(param);

  const setSearchParam = (param, val = "") => {
    if (searchParams.has(param)) searchParams.set(param, val);
    else searchParams.append(param, val);

    history.push({
      search: decodeURIComponent(searchParams.toString()),
    });
  };

  const removeSearchParam = (param) => {
    searchParams.delete(param);

    history.push({
      search: decodeURIComponent(searchParams.toString()),
    });
  };

  return { getSearchParam, setSearchParam, removeSearchParam };
};

export default useSearchParam;
