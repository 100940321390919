import React from "react";
import { Row } from "reactstrap";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const PaginationFooter = ({
  paginationMin,
  total,
  showNumb,
  elements,
  changePagination,
  actualPage,
}) => {
  const page = actualPage ? actualPage : paginationMin / showNumb + 1;
  const totalPages = Math.ceil(total / showNumb);

  return (
    <Row className="justify-content-center mt-3 ml-0 mr-0">
      <ul className="pagination">
        <li
          onClick={
            paginationMin <= 0
              ? (e) => e.preventDefault()
              : () => changePagination(paginationMin - showNumb, showNumb)
          }
          className={
            "waves_effect " + (paginationMin <= 0 ? "inactive" : "pointer")
          }
        >
          <div>
            <MdChevronLeft size={25} />
          </div>
        </li>
        {elements.length === 0 ? 0 : page} /{totalPages}
        <li
          onClick={
            paginationMin >= total - showNumb
              ? (e) => e.preventDefault()
              : () => changePagination(paginationMin + showNumb, showNumb)
          }
          className={
            "waves_effect " +
            (paginationMin >= total - showNumb ? "inactive" : "pointer")
          }
        >
          <MdChevronRight size={25} />
        </li>
      </ul>
    </Row>
  );
};

export default PaginationFooter;
