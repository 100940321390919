import { API } from "@services/urlConstants";
import { get, put } from "@services/axiosCalls";

export const getAgents = async () => {
  const response = await get(`${API.GET_AGENTS}`);
  return response;
};

export const changeAgentStatus = async (employeeId, agentState) => {
  const response = await put(`/api/employee/${employeeId}/agent`, {
      agentState,
  });
  return response;
};
