import React from "react";
import { Label, Input } from "reactstrap";

const FilterCheckbox = ({ value, name, query_val, sendData, description }) => {
  const onChange = () => {
    sendData(!value, name, query_val);
  };

  return (
    <div>
      <Label style={{ marginLeft: "30px" }} check>
        <Input type="checkbox" onChange={() => onChange()} checked={value} />
        <span style={{ cursor: "pointer" }}>{description}</span>
      </Label>
    </div>
  );
};

export default FilterCheckbox;
