import { API } from "@services/urlConstants";
import instance from "@services/base";

export const updateAgentSales = async (id_lead,id_agent) => {
    try {
      const response = await instance.put(
        `${API.CHANGE_AGENT_VENTAS}/${id_lead}/agent/`,{
          id: id_agent
        }
      );

     return response
  
    } catch (error) {
     throw(error)
    } 
};

