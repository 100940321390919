import { environment } from "@config/environment.config";
import instance from "@services/base";

const get = async (route) => {
  try {
    const response = await instance.get(route);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const post = async (route, data) => {
  try {
    const response = await instance.post(route, data);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const put = async (route, data) => {
  const response = await instance.put(route, data);
  return response.data;
};

const deleteReq = async (route) => {
  const response = await instance.delete(route);
  return response.data;
};

const postFile = async (route, file) => {
  try {
    const response = await instance.post(route, file);
    return response.data;
  } catch (e) {
    throw e;
  }
};
const postWithStatus = async (route, data) => {
  try {
    const response = await instance.post(route, data);
    return response;
  } catch (e) {
    throw e;
  }
};

const getSignUrl = async (id) => {
  const route = `${environment.CLOUD_FUNCTIONS_URL}${environment.CF_SIGNED_URL}`;
  try {
    const response = await instance.post(
      route,
      { file_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          token: environment.API_KEY_CF_SIGNED_URL,
        },
      }
    );

    return response.data;
  } catch (e) {
    throw e;
  }
};

export { get, post, put, postFile, getSignUrl, deleteReq,postWithStatus };
