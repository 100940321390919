import React, { useContext, useEffect, useState } from "react";

import styles from "./index.module.scss";
import Chip from "@components/WeekCalendar/Chip";
import Collapsible from "@components/Collapsible";
import { AppointmentContext } from "@views/Appointments/AppointmentContext";

const AgendaCalendar = ({ calendarDayHeaders }) => {
	const {
		type,
		calendarConfig,
		eventsFiltered,
		eventsFilteredCount,
		localSelected,
		localesOptions,
		setLocalSelected
	} = useContext(AppointmentContext);

	const [cells, setCells] = useState([]);

	// Iterate the calendar matrix to get the events of each day-hour (cell)
	useEffect(() => {
		const newChips = calendarDayHeaders.map(({ weekDay, dayFormatted, eventsCount }) => {
			return (
				<div
					key={`agenda-${dayFormatted}`}
					className={styles.appointmentAgendaDay}
				>
					<Collapsible
						header={
							<span className={styles.appointmentAgendDayText}>
								{weekDay}{" "}
								<span className={styles.appointmentAgendaDayCount}>{`(${eventsCount})`}</span>
							</span>
						}
					>
						{/* Hours */}
						{!eventsFiltered[dayFormatted] ? (
							<span className={styles.appointmentAgendaDayEmpty}>No hay citas para este dia</span>
						) : (
							Object.entries(eventsFiltered[dayFormatted]).map(([hour, hourEvents]) => (
								<Collapsible
									key={`agenda-${dayFormatted}-${hour}`}
									header={
										<span className={styles.appointmentAgendDayText}>
											{hour}{" "}
											<span className={styles.appointmentAgendaDayCount}>
												{`(${Object.values(hourEvents).flat().length})`}
											</span>
										</span>
									}
								>
									<div className={styles.appointmentAgendaHourChips}>
										{Object.entries(hourEvents).map((values) => {
											const firstEvent = values[1][0];
											const dealershipId =
												type === "post-venta" ? firstEvent.dealership.id : firstEvent.local.id;

											return Object.keys(localSelected).length === 0 ? (
												// Locales chips
												<Chip
													key={`cell-chip-${dayFormatted}-${hour}-${dealershipId}`}
													bgColor="#f0a23c"
													name={`${values[0]} (${values[1].length})`}
													onClick={() =>
														setLocalSelected(
															localesOptions.filter((x) => x.value === dealershipId)[0]
														)
													}
												/>
											) : (
												values[1].map((appointment) => {
													const resourceId =
														type === "post-venta" ? appointment.case.id : appointment.lead.id;
													return (
														// Client name chip
														<Chip
															key={`cell-chip-${dayFormatted}-${hour}-${resourceId}`}
															bgColor={appointment.bgColor}
															name={calendarConfig.getChipName(appointment)}
															onClick={() =>
																window.open(
																	calendarConfig.getChipLink(appointment),
																	"_blank",
																	"noopener,noreferrer"
																)
															}
														/>
													);
												})
											);
										})}
									</div>
								</Collapsible>
							))
						)}
					</Collapsible>
				</div>
			);
		});

		setCells(newChips);
	}, [
		type,
		eventsFiltered,
		calendarDayHeaders,
		localSelected,
		localesOptions,
		setLocalSelected,
		calendarConfig
	]);

	return (
		<>
			<div className={styles.appointmentTotal}>
				<span className={styles.appointmentTotalText}>Citas totales: </span>
				<span className={styles.appointmentTotalCount}>{eventsFilteredCount}</span>
			</div>
			<div className={styles.appointmentAgenda}>{cells}</div>
		</>
	);
};

export default AgendaCalendar;
