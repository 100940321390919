import { useContext, useState } from "react";
import { Button, Input, Label } from "reactstrap";
import { MdAutorenew } from "react-icons/md";

import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import AppointmentStateModal from "@views/Appointments/AppointmentStateModal";
import { AppointmentContext } from "@views/Appointments/AppointmentContext";
import WeekCalendar from "@components/WeekCalendar";
import Collapsible from "@components/Collapsible";
import Select from "@components/Select";
import { useMediaQuery } from "@hooks/useMediaQuery";
import { useTranslation } from "react-i18next";

const SwitchFeria = ({ type, language, feria, setFeria, t }) => {
	if (language === "es-ES" && type === "venta") {
		return (
			<div className="custom-switch d-flex justify-content-center mt-4">
				<Input
					type="checkbox"
					className="custom-control-input"
					id="reset-password-switch"
					checked={feria}
					onChange={() => setFeria((prev) => !prev)}
				/>
				<Label
					className="custom-control-label"
					for="reset-password-switch"
				>
					{t("soloFeria")}
				</Label>
			</div>
		);
	} else {
		return <></>;
	}
};

const SelectFilters = ({ t, localesOptions, localSelected, setLocalSelected }) => {
	return (
		<div>
			<h3>{t("filters")}</h3>
			<div>
				<Label>{t("concesionarios")}</Label>
				<Select
					placeholder={t("select")}
					options={localesOptions}
					value={localSelected}
					onChange={(option) => setLocalSelected(option)}
				/>
			</div>
		</div>
	);
};

const Appointments = () => {
	const {
		type,
		calendarConfig,
		localesOptions,
		localSelected,
		setLocalSelected,
		resetFilters,
		feria,
		setFeria,
		activateLeadFilters,
		setCurrentFilter,
		eventsFilteredByLeadState
	} = useContext(AppointmentContext);

	const [showModalLeadState, setShowModalLeadState] = useState(false);
	const isDesktop = useMediaQuery("(min-width: 1200px)");
	const { t, i18n } = useTranslation("appointments");

	const activeFiltersByLanguage = (confg) => {
		return (
			<li
				key={`filter-${confg.filteri18n}`}
				style={{
					color: activateLeadFilters ? "#2199E9" : "inherit"
				}}
			>
				<div
					className={styles.before}
					style={{
						backgroundColor: confg.color
					}}
				/>

				<span
					style={{
						cursor: activateLeadFilters ? "pointer" : "inherit"
					}}
					onClick={() => {
						if (activateLeadFilters) {
							setCurrentFilter(confg.filter);
							setShowModalLeadState(true);
						}
					}}
				>
					{t(confg.filteri18n)}
					{activateLeadFilters && ` (${eventsFilteredByLeadState[confg.filter]?.length})`}
				</span>
			</li>
		);
	};

	const filtersByLng = (lng) => {
		return (
			<div className="mt-4">
				<Collapsible
					open={isDesktop}
					header={<span>{t("legend")}</span>}
				>
					<ul className={styles.legend}>
						{lng === "pt-PT" ? (
							<>{calendarConfig.filtersConfigPT.map((confg) => activeFiltersByLanguage(confg))}</>
						) : (
							<>{calendarConfig.filtersConfigES.map((confg) => activeFiltersByLanguage(confg))} </>
						)}
					</ul>
				</Collapsible>
			</div>
		);
	};

	return (
		<Mainlayout>
			<Mainlayout.Sidebar hide={false}>
				<SelectFilters
					localesOptions={localesOptions}
					localSelected={localSelected}
					setLocalSelected={setLocalSelected}
					t={t}
				></SelectFilters>
				<Button
					className={`${styles.resetButton} btn-flexicar-red`}
					onClick={resetFilters}
				>
					<span>{t("reset")}</span>
					<MdAutorenew size={20} />
				</Button>
				<SwitchFeria
					type={type}
					feria={feria}
					setFeria={setFeria}
					t={t}
					language={i18n.language}
				></SwitchFeria>

				{filtersByLng(i18n.language)}
			</Mainlayout.Sidebar>

			<Mainlayout.Content contentTitle={t(type)}>
				<WeekCalendar />
			</Mainlayout.Content>

			<AppointmentStateModal
				showModalLeadState={showModalLeadState}
				setShowModalLeadState={setShowModalLeadState}
			/>
		</Mainlayout>
	);
};

export default Appointments;
