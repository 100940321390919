import axios from "axios";
import { environment } from "@config/environment.config";

//ADD to .env REACT_APP_API_HOST_TEMPORAL_FINANCE=https://services-pre-temp.flexicar.es

export default axios.create({
  baseURL: environment.API_FINANCE,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 25000,
});
