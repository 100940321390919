import { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

import styles from "./index.module.scss";

/**
 * Represents a pane header component.
 *
 * @param {Object} props - The properties of the PaneHeader component.
 * @param {React.ReactNode} props.title - The title of the pane header, which can be a string or JSX element.
 * @param {JSX.Element[]} props.buttons - An array of JSX buttons for the pane header.
 * @param {"primary"|"secondary"} [props.color="primary"] - The color of the pane header, can be "primary" or "secondary".
 * @param {string} [props.className=""] - Additional CSS class names to be applied to the PaneHeader.
 *
 * @returns {JSX.Element} JSX element representing the PaneHeader.
 */
const PaneHeader = ({ title, buttons, color = "primary", className = "" }) => {
	const parentRef = useRef(null);
	const titleRef = useRef(null);
	const buttonsRef = useRef(null);

	// +14px del padding del parent
	// +4px del border y +16px del padding del title
	const parentPadding = 14;
	const border = 4;
	const titlePadding = 16;

	const [isCollapsed, setIsCollapsed] = useState(false);
	const [isMenuOpened, setIsMenuOpened] = useState(false);

	const [titleMinWidth, setTitleMinWidth] = useState(0);
	const [buttonsMinWidth, setButtonsMinWidth] = useState(0);

	const detectButtonsCollision = useCallback(() => {
		setIsCollapsed(parentRef.current.scrollWidth <= titleMinWidth + buttonsMinWidth);
	}, [titleMinWidth, buttonsMinWidth]);

	useEffect(() => {
		setTitleMinWidth(titleRef.current.scrollWidth + parentPadding + border + titlePadding);
		setButtonsMinWidth(buttonsRef.current.scrollWidth);
		// Depende de contenTitle por si no estan con su valor en el primer render
		// Por ejemplo si content title viene de una llamada async
	}, [title]);

	useEffect(() => {
		detectButtonsCollision();
		window.addEventListener("resize", detectButtonsCollision);

		return () => {
			window.removeEventListener("resize", detectButtonsCollision);
		};
	}, [detectButtonsCollision]);

	return (
		<div
			ref={parentRef}
			className={`${styles.title_container} ${styles[color]} ${className}`}
		>
			<span
				ref={titleRef}
				className={`${styles.title} ${styles[color]}`}
				style={{ minWidth: isCollapsed ? "85%" : "unset" }}
			>
				{title}
			</span>
			<div
				ref={buttonsRef}
				className={styles.buttons}
				style={{ minWidth: buttonsMinWidth }}
			>
				{!isCollapsed && buttons}
			</div>

			{isCollapsed && (
				<div
					className={styles.menuButton}
					onClick={() => setIsMenuOpened((open) => !open)}
				>
					{isMenuOpened ? (
						<>
							<AiOutlineClose
								size={22}
								color="#fff"
							/>
							<div className={styles.popoverMenuButtonWrapper}>
								<div className={styles.popoverMenuButton}>{buttons}</div>
							</div>
						</>
					) : (
						<AiOutlineMenu
							size={22}
							color="#fff"
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default PaneHeader;
