export function convertToInteger(data) {
	if (typeof data === "string") {
		return parseInt(data.replace(/[.]/g, ""), 10);
	}

	if (typeof data === "number") {
		return data
	}

	return NaN;
}
