export const documentationFiltersInitialState = {
	documentTypeSelect: [],
	dealershipSelect: [],
	startDate: null,
	endDate: null,
	selectedCheckbox: {
		notAllOk: true,
		contract_ok: false,
		bill_date_ok: false,
		delivery_date_ok: false
	},
	freeFilter: "",
	page: 1,
	isResetting: false
};

export const DOCUMENTATION_ACTIONS = {
	SET_DOCUMENT_TYPE_SELECT: "SET_DOCUMENT_TYPE_SELECT",
	SET_DEALERSHIP_SELECT: "SET_DEALERSHIP_SELECT",
	SET_START_DATE: "SET_START_DATE",
	SET_END_DATE: "SET_END_DATE",
	SET_SELECTED_CHECKBOX: "SET_SELECTED_CHECKBOX",
	SET_FREE_FILTER: "SET_FREE_FILTER",
	SET_PAGE: "SET_PAGE",
	SET_RESETTING: "SET_RESETTING",
	RESET_FILTERS: "RESET_FILTERS",
	COMPLETE_RESET: "COMPLETE_RESET"
};

const updateStateWithCommonProperties = (state, newProps) => ({
	...state,
	...newProps,
	page: 1,
	isResetting: false
});

export const documentationFiltersReducer = (state, action) => {
	switch (action.type) {
		case DOCUMENTATION_ACTIONS.SET_DOCUMENT_TYPE_SELECT:
			return updateStateWithCommonProperties(state, { documentTypeSelect: action.payload });
		case DOCUMENTATION_ACTIONS.SET_DEALERSHIP_SELECT:
			return updateStateWithCommonProperties(state, { dealershipSelect: action.payload });
		case DOCUMENTATION_ACTIONS.SET_START_DATE:
			return updateStateWithCommonProperties(state, { startDate: action.payload });
		case DOCUMENTATION_ACTIONS.SET_END_DATE:
			return updateStateWithCommonProperties(state, { endDate: action.payload });
		case DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX:
			return updateStateWithCommonProperties(state, {
				selectedCheckbox: {
					...state.selectedCheckbox,
					[action.payload]: !state.selectedCheckbox[action.payload]
				}
			});
		case DOCUMENTATION_ACTIONS.SET_FREE_FILTER:
			return updateStateWithCommonProperties(state, { freeFilter: action.payload });
		case DOCUMENTATION_ACTIONS.SET_PAGE:
			return { ...state, page: action.payload, isResetting: false };
		case DOCUMENTATION_ACTIONS.RESET_FILTERS:
			return { ...documentationFiltersInitialState, isResetting: true };
		case DOCUMENTATION_ACTIONS.COMPLETE_RESET:
			return { ...state, isResetting: false };
		default:
			return state;
	}
};
