import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { errorToast } from "@helpers/toastFunctions";
const TableTasacionesPrev = ({ cliente, setTasacionesCount }) => {
  const { t } = useTranslation("tableTasacionesPrev");
  const [tasaciones, setTasaciones] = useState([]);

  const getTasaciones = async () => {
    try {
      const response = await post(API.GET_TASACIONES, { cliente });

      await setTasaciones(response.data);
      await setTasacionesCount(response.count[0].count);
    } catch {
      return errorToast(<span>{t("errors.errorLoading")}</span>);
    }
  };
  useEffect(() => {
    getTasaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="table-wrapper">
      <Table className="fl-table" striped>
        <thead>
          <tr>
            <th>Fecha de Alta</th>
            <th>Última acción</th>
            <th>Estado</th>
            <th>Procedencia</th>
            <th>Provincia</th>
            <th>Marca</th>
            <th>Modelo</th>
            <th>€ CD</th>
            <th>€ GdV</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {tasaciones.map((elm) => (
            <tr key={elm.id_leed}>
              <td>{moment(elm.fecha_alta).format("DD-MM-YYYY | HH.mm")}</td>
              <td>{moment(elm.fecha).format("DD-MM-YYYY | HH.mm")}</td>
              <td>{elm.estado_nombre}</td>
              <td>{elm.procedencia}</td>
              <td>{elm.provincia}</td>
              <td>{elm.marca_nombre}</td>
              <td>{elm.modelo_nombre}</td>
              <td>{elm.precio_ofrecido_cd}</td>
              <td>{elm.precio_ofrecido_gdv}</td>
              <td>
                <a href={`/main/lead-tasacion/${elm.leed_id}`}> Editar</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableTasacionesPrev;
