import { errorToast } from "@helpers/toastFunctions";
import validationDNI from "@helpers/validationDNI";
import validationNIE from "@helpers/validationNIE";
import validationCIF from "@helpers/validationCIF";
import validationDocument from "@helpers/validationDocument";
import validationMatricula from "@helpers/regexMatricula";
import validationIBAN from "@helpers/validationIBAN";
import validationPostalCode from "@helpers/validationPostalCode";




const revision = (t, type, name, data, locale) => {

  switch (type) {
    case "bastidor":
      if (data.length !== 17) {
        errorToast(<span>{t("errors.errorFrame")}</span>);
        return false;
      } else {
        return true;
      }
    case "phone":
      if (data.toString().length !== 9) {
        errorToast(<span>{t("errors.errorPhone")}</span>);
        return false;
      } else {
        return true;
      }
    case "DNI":
      if (validationDNI(data)) {
        return true;
      } else {
        errorToast(<span>{t("errors.errorDni")}</span>);

        return false;
      }
    case "CIF":
      if (validationCIF(data)) {
        return true;
      } else {
        errorToast(<span>{t("errors.errorDni")}</span>);

        return false;
      }
    case "CIF/DNI":
      if (validationCIF(data) || validationDNI(data) || validationNIE(data)) {
        return true;
      } else {
        errorToast(<span>{t("errors.errorDni")}</span>);

        return false;
      }
      case "CP":
        if (validationPostalCode(data)) {
          return true;
        } else {
          errorToast(<span>{t("errors.errorPostalCode")}</span>);
          return false;
        }
    case "matricula":
      if (validationMatricula(data, locale)) {
        return true;
      } else {
        errorToast(<span>{t("errors.errorPlate")}</span>);

        return false;
      }
    case "coche_forma_pago":
      if (data === 0) {
        return true;
      }
      if (!data || data === "" || data === "0") {
        errorToast(<span>{t("errors.errorPlate2")}</span>);

        return false;
      } else {
        return true;
      }
    case "cuenta":
      if (validationIBAN(data)) {
        return true;
      } else {
        errorToast(<span>{t("errors.errorIban")}</span>);
        return false;
      }
    case "Document":
      if (validationCIF(data) || validationDocument(data)) {
        return true;
      } else {
        errorToast(<span>{t("errors.errorDni")}</span>);
        return false;
      }
    case "potencia":
      if (String(data).includes(".")) {
        errorToast(<span>{t("errors.errorPower")}</span>);
        return false;
      } else {
        return true;
      }

    case "cilindrada":
      if (String(data).includes(".")) {
        errorToast(<span>{t("errors.errorCylinder")}</span>);
        return false;
      } else {
        return true;
      }
    case "zero":
      if (data === 0 || data === "0") {
        errorToast(<span>{`${t("errors.errorInput")} ${name} ${t("errors.errorZero")}`}</span>);
        return false;
      } else {
        return true;
      }

    case "empty":
      if (data === 0) {
        return true;
      }
      if (!data || data === "" || data === "0") {
        errorToast(<span>{`${t("errors.errorInput")} ${name} ${t("errors.errorEmpty")}`}</span>);

        return false;
      } else {
        return true;
      }
    default:
      break;
  }
};

export default revision;
