import { IoClose } from "react-icons/io5";

import Select from "@components/Select";

import "@styles/components/search-filter-extended.scss";

import MultiSelectChipsList from "@components/MultiSelectChipsList";

/**
 * A component that displays an input search and a dropdown multiselect to filter a list.
 *
 * @param {Object} props - The component props.
 * 
 * @param {string} props.searchLabel - Label for the input search.
 * @param {string} props.searchValue - Value of the input search.
 * @param {function} props.handleChange - Function to display the search from the input search.
 * @param {string} props.dropdownLabel - Label for the dropdown multiselect.
 * @param {string} props.dropdownPlaceholder - Placeholder for the dropdown multiselect.
 * @param {string} props.searchPlaceholder - Placeholder for license plate finder.
 * @param {Array<{value: number, label: string}>} props.dropdownSelectOptions - An array of dropdown multiselect options.
 * @param {Array<{value: number, label: string}>} props.dropdownSelected - An array of dropdown multiselect values selected.
 * @param {function} props.setDropdownSelected - Sets dropdown multiselect values.
 * @param {string} props.checkboxLabel - Label for the input checkbox.
 * @param {boolean} props.checkboxValue - Value of the input checkbox.

 * @returns {React.ReactNode} The rendered React component.
 */

const SearchFilterExtended = ({
    searchLabel,
    searchValue,
    searchPlaceholder,
    handleChange,
    dropdownLabel,
    dropdownPlaceholder,
    dropdownSelectOptions,
    dropdownSelected,
    setDropdownSelected,
    checkboxLabel,
    checkboxValue
}) => {

    const removeValue = (value, index) => {
        const _dropdownSelected = [...dropdownSelected];
        setDropdownSelected(_dropdownSelected.filter(x => x.label !== value));
    };

    return (
        <div className="d-flex flex-wrap">
            <div className="col-12 col-md-4 mb-2 pl-md-3 px-0">
                <label className="m-0">{searchLabel}</label>
                <input
                    style={{ height: "42px" }}
                    autoComplete="off"
                    placeholder={searchPlaceholder}
                    className="form-control ds-input"
                    type="text"
                    value={searchValue}
                    onChange={(e) => handleChange(e)}
                />
            </div>
            <div className="col-12 col-md-4 mb-2 pl-md-3 px-0">
                <label className="m-0">{dropdownLabel}</label>
                <Select
                    isMulti
                    placeholder={dropdownPlaceholder}
                    options={dropdownSelectOptions}
                    value={dropdownSelected}
                    onChange={(values) => setDropdownSelected(values)}
                />
            </div>
            <div className="col-12 col-md-4 d-flex justify-content-center checkboxCustom">
                <input
                    type="checkbox"
                    className="mt-3"
                    checked={checkboxValue}
                    onChange={(e) => handleChange(e)}
                />
                <label className="m-0 ml-2 mt-3 align-self-center">{checkboxLabel}</label>
            </div>
            <MultiSelectChipsList
                dropdownSelected={dropdownSelected.map(x => x.label)}
                deleteDropdownSelected={removeValue}
            />
        </div>
    );
};

export default SearchFilterExtended;