import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalFilters = ({
  state,
  dispose,
  title = "Filtros",
  footerButtonText,
  children,
}) => {
  return (
    <Modal className="modal-lg" isOpen={state} toggle={dispose}>
      <ModalHeader toggle={dispose}>
        <b>{title}</b>
      </ModalHeader>
      <ModalBody className="p-4">{children}</ModalBody>
      <ModalFooter>
        <button className="btn btn-danger btn-block" onClick={dispose}>
          <b>{footerButtonText}</b>
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalFilters;
