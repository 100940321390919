import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { MdDeleteForever } from "react-icons/md";

import styles from "./index.module.scss";

const ModalDeleteImages = ({ images, show, toggle, deleteCb, deleteAllCb }) => {
  const [imageToDelete, setImageToDelete] = useState();

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);

  const toggleModalDelete = () => setShowModalDelete(!showModalDelete);
  const toggleModalDeleteAll = () => setShowModalDeleteAll(!showModalDeleteAll);

  const selectImageToDelete = (id, url) => {
    setImageToDelete({ id, url });
    toggleModalDelete();
  };

  const deleteImage = async (id) => {
    await deleteCb(id);
    toggleModalDelete();
  };

  const deleteImages = async () => {
    await deleteAllCb();
    toggleModalDeleteAll();
  };

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered="lg"
      style={{ maxWidth: "700px" }}
    >
      <ModalHeader toggle={toggle}>Fotos del vehículo</ModalHeader>
      <ModalBody>
        <div className="table-wrapper">
          <div className="row d-flex align-items-start">
            {images.map((img) => (
              <div
                key={img.id}
                className={`${styles.column} col-md-4 col-sm-12`}
              >
                <div className={styles.figure}>
                  <img
                    src={img.url}
                    className={styles.image}
                    style={{ width: "100%" }}
                    alt="delete"
                  />
                  {img.id !== -1 ? (
                    <>
                      <MdDeleteForever
                        className={styles.delete}
                        onClick={() => selectImageToDelete(img.id, img.url)}
                        size={28}
                      />

                      <Modal isOpen={showModalDelete} toggle={toggleModalDelete}>
                        <ModalHeader toggle={toggleModalDelete}>
                          ¿Seguro que quiere borrar la foto?
                        </ModalHeader>
                        <ModalBody>
                          <img
                            src={imageToDelete?.url}
                            className={styles.image}
                            alt="delete"
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            className="btn-flexicar py-1"
                            onClick={async () => await deleteImage(imageToDelete?.id)}
                          >
                            Borrar
                          </Button>
                          <Button
                            className="py-1"
                            color="secondary"
                            onClick={toggleModalDelete}
                          >
                            Cancelar
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </>
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          {images.length > 1 ? (
            <div className="d-flex justify-content-center">
              <Button className="btn-flexicar" onClick={toggleModalDeleteAll}>
                Borrar todas las fotos
              </Button>
            </div>
          ) : null}

          <Modal isOpen={showModalDeleteAll} toggle={toggleModalDeleteAll}>
            <ModalHeader toggle={toggleModalDeleteAll}>
              ¿Seguro que quiere borrar todas las fotos?
            </ModalHeader>

            <ModalFooter>
              <Button className="btn-flexicar py-1" onClick={async () => await deleteImages()}>
                Borrar
              </Button>
              <Button className="py-1" color="secondary" onClick={toggleModalDeleteAll}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDeleteImages;
