import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import styles from "@styles/views/SellContract/vehicle-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { errorToast } from "@helpers/toastFunctions";
import { ivaTypeOptions } from "@constants/selectOptions";

const VehicleData = ({ dealershipsOptions }) => {
	const { watch, setValue } = useFormContext();

	const watchedDealership = watch("vehicle.dealership");

	const getProvinceByLocalId = useCallback(
		async (id) => {
			try {
				const response = await instance.post(API.GET_PROVINCIA_LOCAL, {
					id_local: id
				});
				setValue("vehicle.dealershipProvince", response.data[0]);
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al obtener la provincia del local");
			}
		},
		[setValue]
	);

	useEffect(() => {
		watchedDealership?.value && getProvinceByLocalId(watchedDealership.value);
	}, [watchedDealership, getProvinceByLocalId]);

	return (
		<div>
			<PaneHeader title="Datos del vehículo" />
			<div className={styles.container}>
				<FormInput
					name="vehicle.plate"
					label="Matrícula"
				/>
				<FormInput
					name="vehicle.bastidor"
					label="Bastidor"
				/>
				<FormFormattedNumberInput
					name="vehicle.km"
					label="Kilómetros"
					suffix=" km"
					decimalScale={0}
				/>
				<FormSelect
					name="vehicle.dealership"
					label="Local"
					options={dealershipsOptions}
				/>
				<FormSelect
					name="vehicle.dealershipProvince"
					label="Provincia local"
					options={[]}
					isDisabled
				/>
				<FormInput
					name="vehicle.companie"
					label="Sociedad"
					disabled
				/>
				<FormSelect
					name="vehicle.iva"
					label="Aplica IVA"
					options={ivaTypeOptions}
				/>
				<FormDatePicker
					name="vehicle.dateLastITV"
					label="Fecha última ITV"
				/>
				<FormDatePicker
					name="vehicle.dateExpirationITV"
					label="Fecha caducidad ITV"
				/>
				<FormTextArea
					name="vehicle.conformidades"
					label="Conformidades sobre el estado del vehículo"
					maxLength={300}
				/>
			</div>
		</div>
	);
};

export default VehicleData;
