export const SELL_FILTER_STATE = Object.freeze({
	APPOINTED: "citados",
	WON: "ganados",
	ABSENCE: "no asiste",
	ARCHIVED: "archivados",
	MANAGING: "en gestión",
	WEB_APPOINTMENT: "cita web"
});



export const sellFiltersConfigES = [
	{
		filteri18n: "appointeds",
		filter: SELL_FILTER_STATE.APPOINTED,
		color: "#f0a23c",
	},
	{
		filteri18n: "won",
		filter: SELL_FILTER_STATE.WON,
		color: "#ff6252",
	},
	{
		filteri18n: "absence",
		filter: SELL_FILTER_STATE.ABSENCE,
		color: "#82a2dd",
	},
	{
		filteri18n: "archived",
		filter: SELL_FILTER_STATE.ARCHIVED,
		color: "#2ecaac",
	},
	{
		filteri18n: "managing",
		filter: SELL_FILTER_STATE.MANAGING,
		color: "#bfbfbf",
	},
	{
		filteri18n: "web_appointment",
		filter: SELL_FILTER_STATE.WEB_APPOINTMENT,
		color: "#373737",
	}
];

export const sellFiltersConfigPT = [
	{
		filteri18n: "appointeds",
		filter: SELL_FILTER_STATE.APPOINTED,
		color: "#f0a23c",
	},
	{
		filteri18n: "won",
		filter: SELL_FILTER_STATE.WON,
		color: "#ff6252",
	},
	{
		filteri18n: "absence",
		filter: SELL_FILTER_STATE.ABSENCE,
		color: "#82a2dd",
	},
	{
		filteri18n: "archived",
		filter: SELL_FILTER_STATE.ARCHIVED,
		color: "#2ecaac",
	},
	{
		filteri18n: "managing",
		filter: SELL_FILTER_STATE.MANAGING,
		color: "#bfbfbf",
	}
];

export const SELL_LEAD_STATES = Object.freeze({
	appointed: [12],
	absence: [5, 6, 32],
	won: [44, 28, 2],
	archived: [13, 3],
	webAppointment: [61]
});

export const getSellColorByLeadState = (leadState) => {
	if (SELL_LEAD_STATES.appointed.includes(leadState)) return "#f0a23c";
	else if (SELL_LEAD_STATES.won.includes(leadState)) return "#ff6252";
	else if (SELL_LEAD_STATES.absence.includes(leadState)) return "#82a2dd";
	else if (SELL_LEAD_STATES.archived.includes(leadState)) return "#2ecaac";
	else if (SELL_LEAD_STATES.webAppointment.includes(leadState)) return "#373737";
	return "#bfbfbf";
};

export const getSellTypeNameByLeadState = (event) => {
	const leadState = event.lead.state;

	if (SELL_LEAD_STATES.appointed.includes(leadState)) return SELL_FILTER_STATE.APPOINTED;
	else if (SELL_LEAD_STATES.won.includes(leadState)) return SELL_FILTER_STATE.WON;
	else if (SELL_LEAD_STATES.absence.includes(leadState)) return SELL_FILTER_STATE.ABSENCE;
	else if (SELL_LEAD_STATES.archived.includes(leadState)) return SELL_FILTER_STATE.ARCHIVED;
	else if (SELL_LEAD_STATES.webAppointment.includes(leadState)) return SELL_FILTER_STATE.WEB_APPOINTMENT;
	return SELL_FILTER_STATE.MANAGING;
};
