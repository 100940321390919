import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { API } from "@services/urlConstants";
import { errorToast } from "@helpers/toastFunctions";
import { useTranslation } from "react-i18next";
import Locale from "@constants/Locale";
import { PHONE_REGEX_ES, PHONE_REGEX_PT } from "@constants/regex";
import instance from "@services/base";

const ModalLead = ({ state, handleValues, launchModal, setState, setClientsByPhone }) => {
	const { i18n, t } = useTranslation("modalLead");

	const [loading, setLoading] = useState(false);

	const validatePhone = (str) =>
		({
			[Locale.ES]: PHONE_REGEX_ES,
			[Locale.PT]: PHONE_REGEX_PT
		}[i18n.language].test(str));

	const checkLead = async () => {
		const { checkLead, vehicle } = state;
		setLoading(true);

		if (checkLead.nombre === "") {
			errorToast(<span>{t("errors.errorName")}</span>);
			setLoading(false);
			return;
		}
		if (!validatePhone(checkLead.telefono)) {
			errorToast(<span>{t("errors.errorPhone")}</span>);
			setLoading(false);
			return;
		}

		try {
			const vehicleId = checkLead.vehicle ? checkLead.vehicle : vehicle.id;
			const response = await instance.get(API.GET_CLIENTS_QUERY, {
				params: { phone: checkLead.telefono.trim(), vehicleId }
			});

			// Nuevo Cliente
			if (response.data.length === 0) {
				setState((prev) => ({
					...prev,
					modal_add_client: true
				}));
			} else {
				setClientsByPhone(response.data);
				setState((prev) => ({
					...prev,
					modal_clients: true
				}));
			}
		} catch (e) {
			errorToast(<span>{t("errors.errorLead")}</span>);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			className="modal-lg"
			isOpen={state.modal_check}
			toggle={() => launchModal("modal_check", null, false)}
		>
			<ModalHeader toggle={() => launchModal("modal_check", null, false)}>
				{t("addLead")}
			</ModalHeader>
			<ModalBody className="p-4">
				<span className="flex">
					<input
						autoComplete="off"
						placeholder={t("name")}
						className="form-control ds-input mt-1"
						type="text"
						value={state.checkLead?.nombre}
						onChange={(e) => handleValues(e, "nombre", "checkLead")}
					/>
				</span>
				<span className="flex">
					<input
						autoComplete="off"
						placeholder={t("phone")}
						className="form-control ds-input mt-1"
						type="tel"
						value={state.checkLead?.telefono}
						onChange={(e) => handleValues(e, "telefono", "checkLead")}
					/>
				</span>
			</ModalBody>
			<ModalFooter>
				<Button
					className="btn-flexicar-orange"
					onClick={checkLead}
					style={{ width: "150px" }}
					disabled={loading}
				>
					{loading ? <Spinner size={"sm"} /> : <span>{t("addLead")}</span>}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalLead;
