import * as Yup from "yup";

export const addLeadInfoSchema = Yup.object({
	estado: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	forma_contacto: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	origen: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	observaciones: Yup.string().max(50, "Máximo 50 caracteres"),
	condiciones: Yup.boolean().required("Es obligatorio aceptar los términos y condiciones"),
	comercial: Yup.boolean().optional()
});
