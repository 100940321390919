import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import moment from "moment";
import {
  MdEdit,
  MdErrorOutline,
  MdSchedule,
  MdRefresh,
  MdDeleteForever,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { BsPaperclip } from "react-icons/bs";
import { IoMdDocument } from "react-icons/io";

import styles from "./index.module.scss";
import TabPaneHeader from "@views/Vehicle/components/TabPaneHeader";
import { environment } from "@config/environment.config";
import { UserContext } from "@contexts/UserContext";
import Loading from "@views/Loading/Loading";
import UploadComponent from "@components/UploadComponent";
import CancelSignComponent from "@components/CancelSignComponent";
import ModalWarning from "@components/ModalWarning";
import ModalPrintView from "@components/ModalPrintView";
import ModalCalendar from "@components/ModalCalendar";
import ModalUpdateContract from "@components/ModalUpdateContract";
import errorCall from "@services/errorCall";
import { getSignUrl, post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { successToast, errorToast } from "@helpers/toastFunctions";
import RolType from "@constants/roles";
import SellContractModal from "@views/LeadVenta/SellContractModal/SellContractModal";
import DocumentationType from "@constants/documentationType";

const DocumentosVehiculo = ({ launchModal, rol, state }) => {
  const { t } = useTranslation("documentosVehiculo");
  const { id: vehicleId } = useParams();
  const { user } = useContext(UserContext);
  const adminRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_MANAGER, RolType.ADMINISTRATION]; 
  const docuRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_AGENT, RolType.TRADING_MANAGER, RolType.ADMINISTRATION]; // roles 1 6 7 9

  const [isLoading, setIsLoading] = useState(true);

  const [uploadDocument, setUploadDocument] = useState({
    open: false,
    document: null,
    type: "",
  });

  const [cancelSign, setCancelSign] = useState({
    open: false,
    document: null,
  });

  const [garantia, setGarantia] = useState();
  const [toggleDocuments, setToggleDocuments] = useState(null);
  const [documentsInfo, setDocumentsInfo] = useState([]);

  const [modalCalendar, setModalCalendar] = useState(null);
  const [modalUpdateContract, setModalUpdateContract] = useState();
  const [modalPrintView, setModalPrintView] = useState();

  const [dropdownIdx, setDropdownIdx] = useState(-1);
  const [warningAlert, setWarningAlert] = useState(false);
  const [warningAlertDocument, setWarningAlertDocument] = useState(false);

  const [elementToDelete, setElementToDelete] = useState(null);

  const goSignedUrl = async (id) => {
    const response = await getSignUrl(id);
    window.open(response.data.url);
  };

  const handleToggleDocument = (idx) => {
    toggleDocuments === idx
      ? setToggleDocuments(null)
      : setToggleDocuments(idx);
  };

  const recallDocuments = async () => {
    const response = await post(API.GET_DOCUMENTACION_FIRMAS, {
      vehicle_id: vehicleId,
    });
    setDocumentsInfo(response);
  };

  const recallGarantia = async () => {
    const response = await post(API.GET_GARANTIA, { vehicle_id: vehicleId });
    setGarantia(response);
  };

  const deleteStart = (id, type, id_documento) => {
    setElementToDelete({ id, type, id_documento });
    setWarningAlert(true);
  };

  const deleteStartDocument = (id_documento) => {
    setElementToDelete({ id_documento });
    setWarningAlertDocument(true);
  };

  const deleteDocument = async () => {
    try {
      const response = await post(API.DELETE_DOCUMENT, elementToDelete);
      if (!response.error) {
        recallDocuments();
        cancelDelete();
        successToast("Elemento borrado con exito");
      } else {
        errorToast(<span>{t("errors.errorDeleting")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const cancelDelete = () => {
    setElementToDelete(null);
    setWarningAlert(false);
    setWarningAlertDocument(false);
  };

  const deleteEnd = async () => {
    try {
      const response = await post(API.DELETE_FILES, elementToDelete);
      if (!response.error) {
        recallDocuments();
        cancelDelete();
        successToast("Elemento borrado con exito");
      } else {
        errorToast(<span>{t("errors.errorDeleting")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const redirectLead = (id_lead, id_cliente) =>
    (window.location.href = `${environment.REACT_HOST}/main/lead-venta/${id_lead}/${id_cliente}`);

  const changeDropdownIdx = (idx) => {
    handleToggleDocument(idx);
    let selected = -1;
    if (idx !== dropdownIdx) selected = idx;
    setDropdownIdx(selected);
  };

  const findStatus = (document) => {
    let today = moment().format("DD-MM-YYYY");
    let fecha_entrega = moment(document.fecha_entrega).format("DD-MM-YYYY");
    let delivery_status;
    let button_style;
    let disabledButton = false;
    switch (document.status) {
      case 3:
        if (today >= fecha_entrega) {
          disabledButton = false;
          delivery_status = "PDTE.CONFIRMAR";
          button_style = `${styles.grey} ${styles.btnFlexicarMini}`;
        }
        if (today < fecha_entrega) {
          disabledButton = false;
          delivery_status = "PDTE.ENTREGA";
          button_style = `${styles.yellow} ${styles.btnFlexicarMini}`;
        }
        break;
      case 4:
        disabledButton = true;
        delivery_status = "ENTREGADO";
        button_style = `${styles.green} ${styles.btnFlexicarMini} ${styles.disabledBtn}`;
        break;
      case 5:
        disabledButton = false;
        delivery_status = "RETIRADO";
        button_style = `${styles.grey} ${styles.btnFlexicarMini}`;
        break;
      default:
        disabledButton = false;
        delivery_status = "ENTREGAR";
        button_style = `${styles.red} ${styles.btnFlexicarMini}`;
    }
    return (
      <button
        className={
          document.fecha_entrega
            ? button_style
            : `${styles.red} ${styles.btnFlexicarMini}`
        }
        disabled={disabledButton}
        onClick={() =>
          setModalCalendar({
            type: "delivery",
            document,
          })
        }
      >
        {document.fecha_entrega ? delivery_status : "ENTREGAR"}
      </button>
    );
  };

  const getDocumentacion = useCallback(async () => {
    try {
      const [getGarantia, vehicleFirmas] = await Promise.all([
        post(API.GET_GARANTIA, { vehicle_id: vehicleId }),
        post(API.GET_DOCUMENTACION_FIRMAS, { vehicle_id: vehicleId }),
      ]);
      setGarantia(getGarantia);
      setDocumentsInfo(vehicleFirmas);
    } catch (e) {
      errorCall(e);
    }
  }, [vehicleId]);

  useEffect(() => {
    (async () => {
      await getDocumentacion();
      setIsLoading(false);
    })();
  }, [getDocumentacion]);

  if (isLoading) return <Loading />;

  return (
    <>
      <TabPaneHeader
        launchModal={launchModal}
        state={state}
        title="DOCUMENTACION DEL VEHÍCULO"
      />
      <div>
        <div className={styles.tableContainer}>
          {documentsInfo && documentsInfo.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Tipo</th>
                  <th>Fecha</th>
                  <th>Cliente</th>
                  <th>Email</th>
                  <th>Telefono</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              {documentsInfo.map((document, idx) => {
                return (
                  <tbody key={"vehicle" + idx}>
                    <tr
                      style={
                        idx % 2
                          ? {
                              background: "#ffffff",
                            }
                          : {
                              background: "rgba(242,242,242,0.5)",
                            }
                      }
                    >
                      <td
                        onClick={() => changeDropdownIdx(idx)}
                        className={document.files ? "pointer" : null}
                      >
                        {(document.files ||
                          document.url ||
                          document.documentNameCloud) &&
                          (dropdownIdx === idx ? (
                            <div
                              key="right"
                              className={styles.marginPointer + " text-primary"}
                            >
                              ▼
                            </div>
                          ) : (
                            <div
                              key="right"
                              className={styles.marginPointer + " text-primary"}
                            >
                              ►
                            </div>
                          ))}
                      </td>
                      <td>
                        <div className={styles.row}>
                          {document.url || document.documentNameCloud ? (
                            <div
                              title="El contrato esta subido"
                              className={styles.okIcon}
                            >
                              <MdOutlineCheckCircleOutline
                                size={25}
                                color={"green"}
                              />
                            </div>
                          ) : document.sign_start_date ? (
                            <div className={styles.iconsContainer}>
                              <div
                                title="Refrescar"
                                onClick={() => recallDocuments()}
                                className={styles.refreshIcon}
                              >
                                <MdRefresh size={25} />
                              </div>
                              <div
                                title="El contrato se ha enviado"
                                onClick={() =>
                                  setCancelSign({
                                    open: true,
                                    document,
                                  })
                                }
                                className={styles.pendingIcon}
                              >
                                <MdSchedule size={25} />
                              </div>
                            </div>
                          ) : (
                            <div
                              title="Adjuntar contrato firmado"
                              onClick={() =>
                                setUploadDocument({
                                  open: true,
                                  document,
                                  type: "contracts",
                                })
                              }
                              className={styles.warningIcon}
                            >
                              <MdErrorOutline size={36} />
                            </div>
                          )}
                          <span className={`ml-2 ${styles.noDecoration}`}>
                            {document.nameTipo}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className={styles.noDecoration}>
                          {document.fecha_contrato
                            ? moment(document.fecha_contrato).format(
                                "DD-MM-YYYY"
                              )
                            : "Fecha no disponible"}
                        </span>
                      </td>
                      <td>
                        <span className={styles.noDecoration}>
                          {`${document.datos?.nombre}`}
                        </span>
                      </td>
                      <td>
                        <span className={styles.noDecoration}>
                          {document.datos?.email
                            ? document.datos?.email
                            : "No disponible"}
                        </span>
                      </td>
                      <td>
                        <span className={styles.noDecoration}>
                          {document.datos?.telefono}
                        </span>
                      </td>

                      <td className={styles.centerMargin}>
                        <span className={styles.iconBox}>
                          {!(document.url || document.documentNameCloud) && (
                            <div
                              onClick={() =>
                                setModalPrintView({
                                  open: true,
                                  contractId: document.documentId,
                                  contractSignStartDate:
                                    document.sign_start_date,
                                  title: document.nameTipo,
                                  tipo: document.tipo,
                                })
                              }
                              className={styles.marginPointer}
                            >
                              <FaEye
                                title="Ver"
                                size={30}
                                color={"#007bff"}
                                role="button"
                              />
                            </div>
                          )}
                        </span>
                        <span className={styles.iconBox}>
                          {!(
                            document.url ||
                            document.documentNameCloud ||
                            document.sign_start_date
                          ) && (
                            <div
                              onClick={() =>
                                setModalUpdateContract({
                                  open: true,
                                  document,
                                })
                              }
                              className={styles.marginPointer}
                            >
                              <MdEdit
                                title="Modificar"
                                size={30}
                                color={"#007bff"}
                              />
                            </div>
                          )}
                        </span>
                        <span className={styles.bigIconBox}>
                          <div
                            onClick={() =>
                              setUploadDocument({
                                open: true,
                                type: "documents",
                                document,
                              })
                            }
                            className={styles.marginPointerBox}
                          >
                            <BsPaperclip
                              title="Adjuntar"
                              size={30}
                              color={"#007bff"}
                            />

                            <div
                              style={{ width: "22px", textAlign: "center" }}
                              className="btn-flexicar-blue-mini"
                              onClick={() =>
                                setUploadDocument({
                                  open: true,
                                  type: "documents",
                                  document,
                                })
                              }
                            >
                              {document.url || document.documentNameCloud
                                ? document?.files?.length
                                  ? document?.files?.length + 1
                                  : 1
                                : document?.files?.length}
                            </div>
                          </div>
                        </span>
                        <span className={styles.buttonBox}>
                          {document.nameTipo === "Contrato de Venta" &&
                            adminRoles.some((r) => rol.indexOf(r) >= 0) && (
                              <button
                                className={
                                  document.fecha_facturacion
                                    ? "btn-flexicar-green-mini"
                                    : "btn-flexicar-red-mini"
                                }
                                onClick={() =>
                                  setModalCalendar({ type: "bill", document })
                                }
                              >
                                {document.fecha_facturacion
                                  ? "FACTURADO"
                                  : "FACTURAR"}
                              </button>
                            )}
                        </span>
                        <span className={styles.buttonBox}>
                          {document.nameTipo === "Contrato de Venta" &&
                            !document.vehicle_id_stock &&
                            docuRoles.some((r) => rol.indexOf(r) >= 0) && (
                              <button
                                className={
                                  document.fecha_entrega
                                    ? `${styles.green} ${styles.btnFlexicarMini}`
                                    : `${styles.red} ${styles.btnFlexicarMini}`
                                }
                                onClick={() =>
                                  setModalCalendar({
                                    type: "delivery",
                                    document,
                                  })
                                }
                              >
                                {document.fecha_entrega
                                  ? "ENTREGADO"
                                  : "ENTREGAR"}
                              </button>
                            )}
                          {document.nameTipo === "Contrato de Venta" &&
                            document.vehicle_id_stock &&
                            docuRoles.some((r) => rol.indexOf(r) >= 0) &&
                            findStatus(document)}
                        </span>

                        <span className={styles.iconBox}>
                          <div
                            onClick={() => {
                              redirectLead(
                                document.id_lead,
                                document.id_cliente
                              );
                            }}
                            className={styles.marginPointer}
                          >
                            <IoMdDocument
                              title="Ir al lead"
                              size={30}
                              color={"#007bff"}
                            />
                          </div>
                        </span>
                        <span className={styles.iconBox}>
                          {adminRoles.some((r) => rol.indexOf(r) >= 0) && (
                            <div
                              onClick={() =>
                                deleteStartDocument(document.documentId)
                              }
                              className={styles.marginPointer}
                            >
                              <MdDeleteForever
                                title="Eliminar documento"
                                size={30}
                                color="red"
                              />
                            </div>
                          )}
                        </span>
                      </td>
                    </tr>

                    {toggleDocuments === idx &&
                      (document.url || document.documentNameCloud) && (
                        <tr
                          key={document.fecha}
                          style={{
                            background: "#a5e2ff",
                          }}
                        >
                          <td colSpan="12">
                            <div className={styles.wrapRowSpace}>
                              <div className={styles.width70rowSpace}>
                                <span className={styles.noDecoration}>
                                  {`Contrato firmado, subido el ${moment(
                                    document.fecha_url
                                  ).format("DD-MM-YYYY HH:mm")}`}
                                </span>
                              </div>
                              <div className={styles.width20rowSpace}>
                                {document.documentNameCloud ? (
                                  <div
                                    onClick={() =>
                                      goSignedUrl(document.documentNameCloud)
                                    }
                                    className={styles.centerPointer}
                                  >
                                    <FaEye
                                      title="Ver"
                                      size={30}
                                      color={"#007bff"}
                                      role="button"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => window.open(document.url)}
                                    className={styles.centerPointer}
                                  >
                                    <FaEye
                                      title="Ver"
                                      size={30}
                                      color={"#007bff"}
                                      role="button"
                                    />
                                  </div>
                                )}
                                {adminRoles.some(
                                  (r) => rol.indexOf(r) >= 0
                                ) && (
                                  <div
                                    onClick={() =>
                                      deleteStart(
                                        document.documentId,
                                        "contracts"
                                      )
                                    }
                                    className={styles.centerPointer}
                                  >
                                    <MdDeleteForever
                                      title="Eliminar documento"
                                      size={30}
                                      color="red"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    {toggleDocuments === idx &&
                      document.files &&
                      document.files.map((e, index) => (
                        <tr
                          key={e.fecha}
                          style={
                            index % 2
                              ? {
                                  background: "#f1f3f5",
                                }
                              : {
                                  background: "#d5dce1",
                                }
                          }
                        >
                          <td colSpan="12">
                            <div className={styles.wrapRowSpace}>
                              <div className={styles.width70rowSpace}>
                                <span className={styles.noDecoration}>
                                  {`${e.nombre} subido el ${moment(
                                    e.fecha
                                  ).format("DD-MM-YYYY HH:mm")}`}
                                </span>
                              </div>

                              <div className={styles.width20rowSpace}>
                                {e.sign_url_id ? (
                                  <div
                                    onClick={() => goSignedUrl(e.sign_url_id)}
                                    className={styles.centerPointer}
                                  >
                                    <FaEye
                                      title="Ver"
                                      size={30}
                                      color={"#007bff"}
                                      role="button"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => window.open(e.url)}
                                    className={styles.centerPointer}
                                  >
                                    <FaEye
                                      title="Ver"
                                      size={30}
                                      color={"#007bff"}
                                      role="button"
                                    />
                                  </div>
                                )}
                                {adminRoles.some(
                                  (r) => rol.indexOf(r) >= 0
                                ) && (
                                  <div
                                    onClick={() =>
                                      deleteStart(
                                        e.id,
                                        "documents",
                                        e.id_documento
                                      )
                                    }
                                    className={styles.centerPointer}
                                  >
                                    <MdDeleteForever
                                      title="Eliminar documento"
                                      size={26}
                                      color="red"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                );
              })}
            </Table>
          ) : (
            <p>Documentación no disponible</p>
          )}
        </div>
        <UploadComponent
          recall={recallDocuments}
          setUploadDocument={setUploadDocument}
          uploadDocument={uploadDocument}
        ></UploadComponent>
        <CancelSignComponent
          recall={recallDocuments}
          setCancelSign={setCancelSign}
          cancelSign={cancelSign}
        ></CancelSignComponent>
        <ModalWarning
          okButton={deleteEnd}
          cancelButton={cancelDelete}
          open={warningAlert}
        ></ModalWarning>
        <ModalWarning
          okButton={deleteDocument}
          cancelButton={cancelDelete}
          open={warningAlertDocument}
        ></ModalWarning>
      </div>

      <ModalCalendar
        insideVehicle
        title={
          modalCalendar?.type === "bill"
            ? "Fecha de facturación"
            : "Fecha de entrega"
        }
        open={modalCalendar?.type}
        document={modalCalendar?.document}
        type={modalCalendar?.type}
        close={() => setModalCalendar(null)}
        setDocumentsInfo={setDocumentsInfo}
        documentsInfo={documentsInfo}
        garantia={garantia}
        recallDocuments={recallDocuments}
        setGarantia={setGarantia}
      />

      {modalUpdateContract?.open &&
				modalUpdateContract?.document.tipo !== DocumentationType.CONTRATO_VENTA && (
					<ModalUpdateContract
						open={modalUpdateContract?.open === true}
						close={() => setModalUpdateContract()}
						document={modalUpdateContract?.document}
						recall={recallDocuments}
            recallGarantia={recallGarantia}
						user={user}
					/>
				)}

			{modalUpdateContract?.open &&
				modalUpdateContract?.document.tipo === DocumentationType.CONTRATO_VENTA && (
					<SellContractModal
						isOpen={modalUpdateContract.open}
						setIsOpen={setModalUpdateContract}
						mode="edit"
						document={modalUpdateContract.document}
            recall={recallDocuments}
            vehicleYear={state?.vehicle.anio}
            vehicleMonth={state?.vehicle.mes}
					/>
				)}

      <ModalPrintView
        title={modalPrintView?.title || "Ver documento"}
        open={modalPrintView?.open === true}
        close={() => setModalPrintView()}
        contractId={modalPrintView?.contractId}
        contractSignStartDate={modalPrintView?.contractSignStartDate}
        contractType={modalPrintView?.tipo}
        recall={recallDocuments}
      />
    </>
  );
};
export default DocumentosVehiculo;
