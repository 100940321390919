import { API } from "@services/urlConstants";
import { get } from "@services/axiosCalls";

export const getEmployeeProfile = async (id) => {
  const response = await get(`${API.EMPLOYEE_PROFILE}/${id}`);
  return response;
};

export const getEmployeeContract = async (id) => {
  const response = await get(`${API.EMPLOYEE_CONTRACTS}/${id}`);
  return response;
};
