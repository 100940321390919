import React, { useState, useEffect, useContext, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";
import { getFilterSellAgent } from "@services/Filtros/filtros";
import instance from "@services/base";

const ModalChangeAgent = ({ id_lead, status, setModal }) => {
  const { t } = useTranslation("modalChangeAgent");
  const [lead, setLead] = useState(null);
  const { user } = useContext(UserContext);

  const getLeadData = useCallback(
    async (id_lead) => {
      const response = await post(API.GET_LEAD_VENTA, {
        agent: user,
        id: id_lead,
      });
      setLead(response[0]);
    },
    [user]
  );

  useEffect(() => {
		if (id_lead) {
			getLeadData(id_lead);
		}
	}, [id_lead, getLeadData]);

  const changeAgent = async (agentId) => {
		try {
			const response = await instance.put(`${API.CHANGE_AGENT_VENTAS}/${id_lead}/agent/`, {
				id: agentId
			});
			if (response.status === 204) {
				successToast("Gestor actualizado correctamente");
			}
			setModal(false);
		} catch (error) {
			errorToast(<span>{t("errors.errorChangeAgent")}</span>);
		}
	};

  return (
    <Modal
      className="modal-md"
      centered
      isOpen={status}
      toggle={() => setModal()}
    >
      <ModalHeader>Cambiar Agente</ModalHeader>
      <ModalBody className="p-4">
        <Select
          placeholder={lead?.nombre_agente}
          loadOptions={getFilterSellAgent}
          value={{
            label: lead?.nombre_agente,
            value: lead?.id_agente
          }}
          onChange={(opt) => changeAgent(opt.value)}
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ModalChangeAgent;
