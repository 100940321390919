import moment from "moment";
import { convertToInteger } from "@utils/convertToInteger";

export const isDisabledForExtraWarranty = (year, month, km) => {
	const currentDate = moment();
	const vehicleDate = moment({ year, month: month - 1 });
	const yearsDifference = currentDate.diff(vehicleDate, "years", true);
	const checkYears = yearsDifference >= 8;

	const checkKm = convertToInteger(km) >= 140000;

	return checkYears || checkKm;
};
