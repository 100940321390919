import { LoadScript } from "@react-google-maps/api";
import { environment } from "@config/environment.config";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Maps from "@views/Dealers/Components/Maps/Maps";

import styles from "./index.module.scss";

const AddressModal = (props) => {
  const {
    modalAddress,
    setModalAddress,
    setModalAddDealer,
    setModalSchedules,
    newDealer,
    setNewDealerHandler,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    reset({
      direccion: newDealer.direccion,
      longitud: newDealer.coordinate_longitude,
      latitude: newDealer.coordinate_latitude,
      mapa: newDealer.mapa,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDealer]);

  const onSubmit = (data) => {
    setNewDealerHandler({
      ...newDealer,
      direccion: data.direccion,
      coordinate_longitude: Number(Number(data.longitud).toFixed(7)),
      coordinate_latitude: Number(Number(data.latitude).toFixed(7)),
      mapa: data.mapa,
    });

    setModalAddress();
    setModalSchedules(true);
  };

  const handleBack = () => {
    setModalAddress();
    setModalAddDealer();
  };

  const cancelHandler = () => {
    setModalAddress();
    setNewDealerHandler({
      id: undefined,
      nombre: undefined,
      tpv: undefined,
      local_type: undefined,
      email: undefined,
      telefono: undefined,
      movil: undefined,
      direccion: undefined,
      coordinate_longitude: undefined,
      coordinate_latitude: undefined,
      schedule: undefined,
      slug: undefined,
      administrationPhone: undefined,
      administrationEmail: undefined,
      encargadoLocal: undefined,
      society: undefined,
    });
  };

  return (
    <Modal isOpen={modalAddress} size="lg">
      <ModalHeader className={styles.modal_header} toggle={cancelHandler}>
        {"Confirmar ubicación. Paso 2 de 4"}
      </ModalHeader>
      <ModalBody>
        <Container>
          <form className="ml-4" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="my-1 col-12">
                <input
                  className={`form-control ${errors.direccion && "is-invalid"}`}
                  placeholder="Dirección"
                  {...register("direccion", {
                    required: true,
                    maxLength: 255,
                    minLength: 0,
                  })}
                />
                {errors.direccion?.type === "required" && (
                  <span className={`${styles.error}`}>
                    Introduza la dirección
                  </span>
                )}
                {errors.direccion?.type === "maxLength" && (
                  <span className={`${styles.error}`}>
                    Número de caracteres no permitido
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="my-1 col-12">
                <input
                  defaultValue={
                    newDealer.mapa ||
                    `https://www.google.com/maps/@${newDealer.coordinate_latitude},${newDealer.coordinate_longitude},19z`
                  }
                  className={`form-control ${errors.mapa && "is-invalid"}`}
                  placeholder="Url Google maps"
                  {...register("mapa", { required: false })}
                />
              </Col>
            </Row>

            <Row>
              <Col className="my-1 col-6">
                <input
                  type="number"
                  className={`form-control ${errors.latitude && "is-invalid"}`}
                  placeholder="Latitud"
                  {...register("latitude", { required: true })}
                />
              </Col>
              <Col className="my-1 col-6">
                <input
                  type="number"
                  className={`form-control ${errors.number && "is-invalid"}`}
                  placeholder="Longitud"
                  {...register("longitud", { required: true })}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <LoadScript
                googleMapsApiKey={environment.API_KEY_GOOGLEMAPS}
                libraries={["places"]}
              >
                <Maps
                  location={{
                    lat: newDealer.coordinate_latitude,
                    lng: newDealer.coordinate_longitude,
                  }}
                  newDealer={newDealer}
                  setNewDealerHandler={setNewDealerHandler}
                />
              </LoadScript>
            </Row>
          </form>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Container>
          <Row className="justify-content-center">
            <Button className="mx-5" color="secondary" onClick={handleBack}>
              Atras
            </Button>

            <Button
              className={`${styles.btn_custom} mx-5`}
              onClick={handleSubmit(onSubmit)}
            >
              Siguiente
            </Button>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  );
};

export default AddressModal;
