import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Form from "@components/Form/Form";
import FormSelect from "@components/Form/Select/FormSelect";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import FormCheckbox from "@components/Form/Checkbox/FormCheckbox";

import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";

import { successToast } from "@helpers/toastFunctions"

import { addLeadInfoSchema } from "@schema/addLeadInfoSchema";


const ModalAddLeadInfo = ({
  isOpen,
  setIsOpen,
  state,
  setState
}) => {
  const [stateTypes, setStateTypes] = useState([]);
  const [originTypes, setOriginTypes] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [defaultValues, setDefaultValues] = useState(null);

  const history = useHistory();

  const { t } = useTranslation("modalAddLeadInfo");

  const formRef = useRef(null);

  const NUEVO_FORMULARIO = 1;

  const launchQuery = async (query, data) => {
    try {
      const dataCall = {
        data_query: query,
        data_call: data ? data : null,
      };
      const response = await post(API.FILTROS, { dataCall });
      const mapped = response.map((item) => ({ label: item.nombre, value: item.id }));
      query === "estado_lead_venta_acciones" && setStateTypes(mapped);
      query === "tipos_procedencia_lead" && setOriginTypes(mapped);
      query === "tipos_contacto_lead" && setContactTypes(mapped);
    } catch (e) {
      errorCall(e);
    }
  };

  useEffect(() => {
    launchQuery("estado_lead_venta_acciones", null);
    launchQuery("tipos_procedencia_lead", null);
    launchQuery("tipos_contacto_lead", null);
  }, []);

  useEffect(() => {
    if (stateTypes.length) {
      setDefaultValues({ 
        estado: stateTypes.find(x => x.value === NUEVO_FORMULARIO)
      });
    }
  }, [stateTypes]);

  const handleSubmit = async (data) => {
    setState(prev => ({
      ...prev,
      checkLead: {
        ...prev.checkLead,
        estado: data.estado,
        forma_contacto: data.forma_contacto,
        origen: data.origen,
        observaciones: data.observaciones,
        condiciones: data.condiciones,
        comercial: data.comercial
      },
    }));

    try {
      const dataCall = {
        clientId: state.checkLead.clientId || state.checkLead.cliente,
        vehicleId: state.checkLead.vehicle || state.vehicle.id,
        statusId: data.estado.value,
        originId: data.origen.value,
        contactTypeId: data.forma_contacto.value,
        annotations: data.observaciones || null,
        conditions: data.condiciones ,
        commercialInfo: data.comercial
      };
      const response = await post(API.CREATE, dataCall);

      if (response?.id) {
        const newLeadId = response.id;
        const clientId = state.checkLead.clientId || state.checkLead.cliente;
        history.push(`/main/lead-venta/${newLeadId}/${clientId}`);
				successToast(t("newLeadCreated"));
      }
    } catch (e) {
      errorCall(e);
    }
  };

  return (
    <Modal
      className="modal-lg"
      centered
      isOpen={isOpen}
      toggle={setIsOpen}
    >
      <ModalHeader>{t("basicLeadInformation")}</ModalHeader>
      <ModalBody className="p-8">
        {defaultValues && <Form
          ref={formRef}
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          schema={addLeadInfoSchema}
          className="d-flex justify-content-center"
        >
          <div className="row p-4">
            <div className="col-md-6 col-sm-12">
              <FormSelect
                name="estado"
                options={stateTypes}
                placeholder={t("selectAnOption")}
                label={t("state")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <FormSelect
                name="origen"
                options={originTypes}
                placeholder={t("selectAnOption")}
                label={t("origin")}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <FormSelect
                name="forma_contacto"
                options={contactTypes}
                placeholder={t("selectAnOption")}
                label={t("contactForm")}
              />
            </div>
            <div className="col-12">
              <FormTextArea
                name="observaciones"
                placeholder={t("observations")}
                label={t("observations")}
              />
            </div>
            <div className="col-12 mt-4">
              <FormCheckbox
                name={"condiciones"}
                label={t('privatePolicy')}
              />
              <FormCheckbox
                name={"comercial"}
                label={t('communicationPolicy')}
              />
            </div>
          </div>
        </Form>}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-flexicar-inverse-size-s btn-flexicar-mobile"
          onClick={setIsOpen}
        >
          {t("close")}
        </button>

        <button
          type="submit"
          onClick={() => formRef.current?.triggerSubmit()}
          className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
        >
          {t("createLead")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddLeadInfo;
