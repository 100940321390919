import React from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  FormGroup,
} from "reactstrap";
import styles from "./index.module.scss";

const ModalRemoveVehicle = ({
  vehiculo,
  handleSimpleInput,
  removeVehicle,
  open,
  toggle,
}) => {
  return (
    <>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader className={styles.modalheader} toggle={toggle}>
          <span>
            {" "}
            Estás retirando el vehículo de stock por finalización de la gestión
            de venta.{" "}
          </span>
          <br />
          <div className={styles.text}>
            <span> ¿Tienes otro motivo para la retirada? </span>
          </div>
        </ModalHeader>
        <FormGroup style={{ padding: "20px" }}>
          <Input
            type="textarea"
            name="text"
            id="exampleText"
            placeholder="Motivo de la baja"
            value={vehiculo?.comment_status}
            onChange={(e) => {
              handleSimpleInput(e, "comment_status");
            }}
          />
        </FormGroup>
        <ModalFooter>
          <Button color="primary" onClick={() => removeVehicle()}>
            Retirar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalRemoveVehicle;
