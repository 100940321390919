import React, { useState, useEffect } from "react";

import Select from "react-dropdown-select";
const FilterFromToAnio = ({
  name,
  sendData,
  value,
  symbol,
  values,
  refr,
  str,
  query_val,
}) => {
  const [filterValues, setFilterValues] = useState([]);

  //Al actualizar el padre recibe Prop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (value && value.value.length === 0 && filterValues.length > 0) {
      setFilterValues([]);
    }
  });

  //Se ejecuta cuando se añade o elimina un valor del desplegable
  //Envía el valor al padre y al State

  const onChange = (value) => {
    sendData(symbol, value, refr);
    setFilterValues(value);
  };

  return (
    <div>
      <Select
        color={"#FF5D0E"}
        options={values.map((x, y) => {
          return {
            label: str + x,
            value: "v." + query_val + symbol + x,
          };
        })}
        placeholder={name}
        values={filterValues}
        clearable={filterValues.length > 0 ? true : false}
        noDataRenderer={() => (
          <span style={{ textAlign: "center" }}>Sin datos</span>
        )}
        onChange={(value) => onChange(value)}
        className="fix_selector_arrow"
      />
    </div>
  );
};

export default FilterFromToAnio;
