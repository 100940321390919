import { useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/textarea.module.scss";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * FormTextArea component for rendering a textarea element with its label.
 *
 * @param {React.HTMLProps<HTMLTextAreaElement>} textAreaProps - Props for the textarea element.
 * @returns {JSX.Element} The rendered component.
 */
const FormTextArea = ({ ...textAreaProps }) => {
  const { register, watch } = useFormContext();
  const { markupError } = useFormError(textAreaProps.name);

  const textareaValue = watch(textAreaProps.name);

  return (
    <div className={styles.customTextAreaWrapper}>
      <label htmlFor={textAreaProps.name}>{textAreaProps.label}</label>
      <textarea
        id={textAreaProps.name}
        className={styles.customInput}
        {...textAreaProps}
        {...register(textAreaProps.name)}
      />

      {textAreaProps.maxLength && (
        <span className={styles.customTextAreaMaxLengthInfo}>
          {textareaValue?.length ?? 0} / {textAreaProps.maxLength}
        </span>
      )}

      {markupError}
    </div>
  );
};

export default FormTextArea;
