import PreparationTable from "@views/Vehicle/components/VehiclePreparation/PreparationTable";
import PreparationState from "@views/Vehicle/components/VehiclePreparation/PreparationState";
import Verification from "@views/Vehicle/components/VehiclePreparation/Verification/Verification";

const VehiclePreparation = ({ vehicle }) => {
	return (
		<>
			<PreparationTable />
			<PreparationState />
			<Verification />
		</>
	);
};
export default VehiclePreparation;
