import { useEffect, useState } from "react";
import { Table } from "reactstrap";

import { MdDone, MdCropSquare, MdClose, MdEdit } from "react-icons/md";

import Mainlayout from "@layouts/MainLayout";
import styles from "./index.module.scss";
import { getAgents, changeAgentStatus } from "@services/Agents/AgentsService";
import { successToast } from "@helpers/toastFunctions";
import { useTranslation } from "react-i18next";

const LeadsAgentsList = () => {
  const [agents, setAgents] = useState([]);

  const { t } = useTranslation("leadsAgentsList");

  useEffect(() => {
    (async () => {
      const response = await getAgents();
      setAgents(response);
    })();
  }, []);

  const changeStatus = async (agentId, currentState, agentName) => {
    const response = await changeAgentStatus(
      agentId,
      currentState === 1 ? 0 : 1
    );
    if (response.updated) {
      const state = currentState === 0 ? t("changedToAgent") : t("isNotAgent");
      successToast(`${t("employee")} ${agentName} ${state}`);
      setAgents((prevAgents) =>
        prevAgents.map((prevAg) =>
          prevAg.id === agentId
            ? { ...prevAg, isAgent: prevAg.isAgent === 1 ? 0 : 1 }  
            : prevAg
        )
      );
    }
  };

  return (
    <Mainlayout>
      <Mainlayout.Content full contentTitle={t("agentList")}>
        <div
          className={`col-12 col-lg-10 d-flex flex-column align-self-center mt-3 ${styles.card}`}
        >
          <span className="text-primary mt-2 mb-4">
           {t("totalEmployees")}{" "}
            <span className="text-success">{agents.length}</span>
          </span>
          <div style={{ overflowX: "auto" }}>
            <Table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("itsAgent")}</th>
                  <th>{t("employeeType")}</th>
                  <th>{t("name")}</th>
                  <th>{t("active")}</th>
                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {agents.map((agent) => (
                  <tr key={agent.id}>
                    <td>
                      <button
                        className="btn"
                        onClick={() => changeStatus(agent.id, agent.isAgent, `${agent.nombre} ${agent.apellido1} ${agent.apellido2}`)}
                      >
                        {
                          {
                            0: <MdCropSquare size={24} />,
                            1: <MdDone size={24} color="#26A69A" />,
                          }[agent.isAgent]
                        }
                      </button>
                    </td>
                    <td>
                      <span>{agent.employeeType}</span>
                    </td>
                    <td>
                      <span>
                        {agent.nombre} {agent.apellido1} {agent.apellido2}
                      </span>
                    </td>
                    <td>
                      {
                        {
                          0: <MdClose size={24} color="ff0000" />,
                          1: <MdDone size={24} color="#26A69A" />,
                        }[agent.isActive]
                      }
                    </td>
                    <td>
                      <a href={`/main/editar-empleado/${agent.id}`}>
                        <MdEdit size={24} color="#039BE5" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default LeadsAgentsList;
