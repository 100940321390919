import React, { useState } from "react";
import { postFile } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import styles from "./index.module.scss";
import { successToast } from "@helpers/toastFunctions";
import { Modal, ModalHeader, ModalBody, Label, Input } from "reactstrap";
import { MdClose, MdFindInPage } from "react-icons/md";

const UploadComponent = ({
  setUploadDocument,
  uploadDocument,
  recall,
  createVehicleDraft,
  vtcRoles,
  rol,
}) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const onFileChange = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
    setName(event.target.files[0].name.slice(0, 50));
  };

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("id", uploadDocument?.document?.documentId);
    formData.append("fileName", name ? name : null);
    formData.append("type", uploadDocument.type);

    try {
      const response = await postFile(API.UPLOAD_FILES, formData);
      if (!response.error) {
        await recall();
        await reset();
        await successToast("Subido con éxito");
        if (
          uploadDocument?.type === "contracts" &&
          (uploadDocument?.type_contract === 3 ||
            uploadDocument?.type_contract === 4) &&
          !vtcRoles.some((r) => rol.includes(r))
        ) {
          await createVehicleDraft({ id: uploadDocument?.documentId });
        }
      }
    } catch (e) {
      errorCall(e);
    }
  };
  const reset = () => {
    setFile(null);
    setName("");
    setUploadDocument({ open: false, document: null, type: null });
  };

  return (
    <Modal
      className="modal-xs"
      isOpen={uploadDocument?.open}
      toggle={() => reset()}
    >
      <ModalHeader>Subir documento</ModalHeader>
      <ModalBody className="p-4">
        {file &&
          (uploadDocument.type === "contracts" ? (
            <div className={styles.center}>
              <h4>Contrato firmado</h4>
            </div>
          ) : (
            <div className={styles.center}>
              <Label>
                Inserte nombre para renombrar archivo
                <Input
                  maxLength="50"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Label>
            </div>
          ))}
        <div className={styles.center}>
          <div className={styles.container}>
            {file ? (
              <div>Archivo seleccionado</div>
            ) : (
              <div>Seleccione un archivo</div>
            )}
            {file && <strong>{file.name}</strong>}

            <div className={styles.uploadBox}>
              {file ? (
                <div className={styles.iconBox}>
                  <button
                    onClick={() => {
                      setFile(null);
                      setName("");
                    }}
                    className={styles.buttonCancel}
                  >
                    Cancelar
                  </button>

                  <button
                    className={styles.buttonAccept}
                    onClick={() => onFileUpload()}
                  >
                    Subir
                  </button>
                </div>
              ) : (
                <div>
                  <label for="file-input" className="pointer">
                    <MdFindInPage
                      title="Buscar archivo"
                      size={75}
                      color={"#007bff"}
                    />
                  </label>
                  <input
                    className={styles.uploadInput}
                    id="file-input"
                    type="file"
                    name="customFile"
                    onChange={onFileChange}
                    label="Seleccion un archivo"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <div onClick={() => reset()} className={styles.close}>
        <MdClose title="Cerrar" size={30} />
      </div>
    </Modal>
  );
};

export default UploadComponent;
