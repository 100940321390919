import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Decimal } from "decimal.js";

import styles from "@styles/views/SellContract/sell-resume.module.scss";

import PaneHeader from "@components/PaneHeader";
import { WarningPanel } from "@components/InfoBox";
import FormattedNumberInput from "@components/FormattedNumberInput";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";

const SellResumePT = ({ areTotalAmountsValid, setAreTotalAmountsValid }) => {
	const { watch } = useFormContext();

	const [totalAmount, setTotalAmount] = useState(0);
	const [payTotalAmount, setPayTotalAmount] = useState(0);

	const pvp = watch("sellInfo.pvp");
	const administrativeCosts = watch("sellInfo.administrativeCosts");

	const wantsTransport = watch("sellInfo.transport.wants", false)?.value;
	const transportAmount = watch("sellInfo.transport.amount");

	const warrantyAmount = watch("sellInfo.warranty.amount");

	const financingAmount = watch("payAmounts.financingAmount");
	const dealershipTPVAmount = watch("payAmounts.dealershipTPVAmount");
	const virtualTPVAmount = watch("payAmounts.virtualTPVAmount");
	const cashAmount = watch("payAmounts.cashAmount");
	const transferenceAmount = watch("payAmounts.transferenceAmount");
	const carPayAmount = watch("sellInfo.carAsPayment.amount");

	useEffect(() => {
		const values = [];

		if (pvp) values.push(pvp);
		if (administrativeCosts) values.push(administrativeCosts);
		if (wantsTransport && transportAmount) values.push(transportAmount);
		if (warrantyAmount) values.push(warrantyAmount);

		const sumReduce = values.reduce((acc, num) => acc.plus(new Decimal(num)), new Decimal(0));

		setTotalAmount(sumReduce.toNumber());
	}, [pvp, administrativeCosts, warrantyAmount, wantsTransport, transportAmount]);

	useEffect(() => {
		const newPayAmount =
			financingAmount +
			dealershipTPVAmount +
			virtualTPVAmount +
			cashAmount +
			transferenceAmount +
			carPayAmount;

		setPayTotalAmount(newPayAmount);
	}, [
		financingAmount,
		dealershipTPVAmount,
		virtualTPVAmount,
		cashAmount,
		transferenceAmount,
		carPayAmount
	]);

	useEffect(() => {
		setAreTotalAmountsValid(totalAmount === payTotalAmount);
	}, [setAreTotalAmountsValid, totalAmount, payTotalAmount]);

	return (
		<div>
			<PaneHeader title="Resumo de venda" />

			<div className={styles.container}>
				<div>
					<PaneHeader
						title="Produtos contratados"
						color="secondary"
					/>

					<table className={styles.sellResume}>
						<thead>
							<tr>
								<th>Producto contratado</th>
								<th>Quantia</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Preço da viatura (PVP)</td>
								<td>
									<FormattedNumberInput
										value={pvp}
										displayType="text"
									/>
								</td>
							</tr>

							<tr>
								<td>Despensas administrativas</td>
								<td>
									<FormattedNumberInput
										value={administrativeCosts}
										displayType="text"
									/>
								</td>
							</tr>

							{wantsTransport && (
								<tr>
									<td>Transporte</td>
									<td>
										<FormattedNumberInput
											value={transportAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}

							<tr>
								<td>Garantias</td>
								<td>
									<FormattedNumberInput
										value={warrantyAmount}
										displayType="text"
									/>
								</td>
							</tr>

							<tr>
								<td>Total</td>
								<td>
									<FormattedNumberInput
										value={totalAmount}
										displayType="text"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div>
					<PaneHeader
						title="Formas de pagamento"
						color="secondary"
					/>

					<div className={styles.payMethods}>
						<div className={styles.payAmounts}>
							<FormFormattedNumberInput
								name="payAmounts.financingAmount"
								label="Financiado"
							/>
							<FormFormattedNumberInput
								name="payAmounts.dealershipTPVAmount"
								label="Loja PDV"
							/>
							<FormFormattedNumberInput
								name="payAmounts.virtualTPVAmount"
								label="PDV virtual"
							/>
							<FormFormattedNumberInput
								name="payAmounts.cashAmount"
								label="Dinheiro"
							/>
							<FormFormattedNumberInput
								name="payAmounts.transferenceAmount"
								label="Transferir"
							/>
							<FormFormattedNumberInput
								name="sellInfo.carAsPayment.amount"
								label="Retoma"
								dis
							/>
						</div>

						<div className={styles.payTotalContainer}>
							<label>Total</label>
							<FormattedNumberInput
								value={payTotalAmount}
								onChange={setPayTotalAmount}
								disabled
							/>
							{areTotalAmountsValid && (
								<span className={styles.validatedAmount}>Métodos de pagamento validados</span>
							)}
						</div>
					</div>

					{!areTotalAmountsValid && (
						<WarningPanel text="A soma dos valores informados nas formas de pagamento deverá coincidir com o valor total dos produtos contratados." />
					)}
				</div>
			</div>
		</div>
	);
};

export default SellResumePT;
