import { useCallback, useEffect, useState } from "react";

export function useMediaQuery(query) {
  const getMatches = (query) => window.matchMedia(query).matches;
  const [matches, setMatches] = useState(getMatches(query));

  const handleChange = useCallback(
    () => setMatches(getMatches(query)),
    [query]
  );

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addListener
      ? matchMedia.addListener(handleChange)
      : matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeListener
        ? matchMedia.removeListener(handleChange)
        : matchMedia.removeEventListener("change", handleChange);
    };
  }, [handleChange, query]);

  return matches;
}
