import React, { useRef, useState } from "react";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import GenericModal from "@components/GenericModal";
import { addLeadSchema } from "@schema/addleadSchema";
import { useTranslation } from "react-i18next";
import { API } from "@services/urlConstants";
import { Button, Spinner } from "reactstrap";
import instance from "@services/base";

const AddLead = ({
	isOpen,
	setIsOpen,
	setPhoneState,
	setPlateState,
	setShowModalCreateClient,
	setClientsPurchaseLead,
	setShowModalClientsTable,
	handleClose
}) => {
	const { i18n, t } = useTranslation("fichaLeadsCompra");

	const locale = i18n.language;
	const formRef = useRef();

	const [loading, setLoading] = useState(false);

	const handlePhoneOrPlate = (phoneOrMatricula) => {
		if (phoneOrMatricula.length === 9) {
			setPhoneState(phoneOrMatricula);
			return {
				phone: phoneOrMatricula
			};
		}
		if (phoneOrMatricula.trim().length === 7 || phoneOrMatricula.trim().length === 6) {
			setPlateState(phoneOrMatricula);
			return {
				plate: phoneOrMatricula
			};
		}

		return null;
	};

	const checkClientsPurchase = async (data) => {
		try {
			setLoading(true);
			const payload = handlePhoneOrPlate(data.phoneOrMatricula);

			const response = await instance.get(API.GET_CLIENTS_QUERY, {
				params: payload
			});

			if (response.data.length) {
				setClientsPurchaseLead(response.data);
				setShowModalClientsTable(true);
			} else {
				setShowModalCreateClient(true);
			}
			setIsOpen(false);
		} catch (e) {
			<span>{t("errorAddLead")}</span>;
		} finally {
			setLoading(false);
		}
	};
	

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			onClose={handleClose}
			headContent={t("addLead")}
			bodyContent={
				<Form
					i18nErrorsFile="fichaLeadsCompra"
					schema={() => addLeadSchema(locale)}
					ref={formRef}
					onSubmit={(data) => checkClientsPurchase(data)}
				>
					<FormInput
						name="phoneOrMatricula"
						placeholder={t("contactPlate")}
					/>
				</Form>
			}
			footerContent={
				<Button
					className="btn-flexicar-orange"
					onClick={() => formRef.current.triggerSubmit()}
					style={{ width: "150px" }}
					disabled={loading}
				>
					{loading ? <Spinner size={"sm"} /> : <span>{t("addLead")}</span>}
				</Button>
			}
		/>
	);
};

export default AddLead;
