import { useRef, useState } from "react";

import styles from "./index.module.scss";

const DropzoneFileMulti = ({ files, setFiles, type }) => {
  const fileInputRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

 const handleDrop = (e) => {
   e.preventDefault();
   setIsDragOver(false);
   const fileList = Array.from(e.dataTransfer.files);
   const updatedFiles = [...files];

   fileList.forEach((file) => {
     const reader = new FileReader();

     reader.onload = (e) => {
       const fileData = {
         name: file.name,
         file: e.target.result,
       };

       updatedFiles.push(fileData);
       setFiles(updatedFiles);
     };

     reader.readAsDataURL(file);
   });
 };

 const handleFileSelect = (e) => {
   e.preventDefault();
   const fileList = Array.from(e.target.files);
   const updatedFiles = [...files];

   fileList.forEach((file) => {
     const reader = new FileReader();

     reader.onload = (e) => {
       const fileData = {
         name: file.name,
         file: e.target.result,
         type: type
       };

       updatedFiles.push(fileData);
       setFiles(updatedFiles);
     };

     reader.readAsDataURL(file);
   });
 };

 const handleFileName = (index, newName) => {
   const updatedFiles = [...files];
   updatedFiles[index].name = newName;
   setFiles(updatedFiles);
  };


  return (
    <div
      className={`${styles.dropzone} ${isDragOver ? styles.dragActive : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {isDragOver ? (
        <span>Arrastre los archivo aqui...</span>
      ) : files.length > 0 ? (
        <>
          {files.map((fileData, index) => (
            <div key={`dropzone-file-${index}`} className={styles.preview}>
              <label>Inserte nombre para renombrar archivo</label>
              <input
                type="text"
                value={fileData.name}
                onChange={(e) => handleFileName(index, e.target.value)}
              />
            </div>
          ))}
        </>
      ) : (
        <span>
          Arrastre o pulse{" "}
          <button
            className={`btn ${styles.dropzoneTextButton}`}
            onClick={() => fileInputRef.current.click()}
          >
            aquí
          </button>{" "}
          para subir los documentos
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            multiple
          />
        </span>
      )}
    </div>
  );
};

export default DropzoneFileMulti;
