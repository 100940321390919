export const createUser = (user) => {
  return { type: "CREATE_USER", user };
};
export const createRol = (rol) => ({ type: "CREATE_ROL", rol });
export const createLocal = (locals) => ({ type: "CREATE_LOCALS", locals });
export const vehicleSearch = (search) => ({
  type: "CREATE_VEHICLE_SEARCH",
  search,
});
export const leadsSearch = (search) => ({
  type: "CREATE_LEADS_SEARCH",
  search,
});
export const message = (mes) => ({ type: "OK_MESSAGE", mes });
