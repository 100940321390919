

export const isNumber = (value) => (
    typeof value === 'number' && isFinite(value) && !isNaN(value)
)

export const getCurrentPage = (offset, limit, totalElements) => {
    const isAllValuesNumber = isNumber(offset) && isNumber(limit) && isNumber(totalElements)

    if(!isAllValuesNumber){
        return 0
    }
    
    const currentPage = parseInt(offset / limit + 1)

    return currentPage
}