import React, { useState, useEffect, useCallback } from "react";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./index.module.scss";
import moment from "moment";
import { errorToast } from "@helpers/toastFunctions";
import { useTranslation } from "react-i18next";

const ModalMensajesLead = ({ lead_id, vehicle_id, client_id, user_id, setModal, status }) => {
	const { t } = useTranslation("modalLead");
	const [mensajes, setMensajes] = useState([]);
	const [nuevoMensaje, setNuevoMensaje] = useState("");

	const getData = useCallback(async () => {
		const response = await post(API.GET_MENSAJES_LEAD, { lead_id, client_id });
		setMensajes(response);
	}, [client_id, lead_id]);

	const saveMesaje = async () => {
		if (!nuevoMensaje.trim()) {
			return errorToast(<span>{t("errors.errorEmptyComment")}</span>);
		}
		const response = await post(API.SAVE_MENSAJE_LEAD, {
			lead_id,
			client_id,
			vehicle_id,
			user_id,
			nuevoMensaje
		});
		if (response) {
			getData();
			setNuevoMensaje("");
		}
	};

	useEffect(() => {
		if (status && lead_id) {
			getData();
		}
	}, [status, lead_id, getData]);

	const addLinkToComment = (text) => {
		const HTTPS = "https://";
		const HTTP = "http://";

		const textList = text.split(" ");
		const isAnyLink = textList.some((x) => x.startsWith(HTTPS) || x.startsWith(HTTP));
		if (isAnyLink) {
			return (
				<span className={`${styles.commentBlock}`}>
					{textList.map((t, index) => {
						if (t.startsWith(HTTPS) || t.startsWith(HTTP)) {
							return (
								<a
									href={t}
									key={index}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t}
								</a>
							);
						} else {
							return `${t} `;
						}
					})}
				</span>
			);
		} else {
			return <span className={`${styles.commentBlock}`}>{text}</span>;
		}
	};

	return (
		<Modal
			className="modal-lg"
			centered
			isOpen={status}
			toggle={() => setModal()}
		>
			<ModalHeader>Comentarios del Lead</ModalHeader>
			<ModalBody className="p-4">
				{mensajes.length !== 0
					? mensajes.map((i, idx) => {
							return (
								<div
									key={`mensajes-lead-${idx}`}
									className="text-right mt-3"
								>
									{addLinkToComment(i.comentario)}
									<img
										alt="logo"
										className="pointer"
										style={{ width: "39px" }}
										title={i.nombre + " el " + moment(i.fecha).format("DD-MM-YYYY HH.mm")}
										src="https://crm.flexicar.es/public/logo192.png"
									/>
								</div>
							);
					  })
					: "Ningún mensaje encontrado"}
				<hr className="mt-5" />
				<textarea
					className="w-100"
					value={nuevoMensaje}
					onChange={(e) => setNuevoMensaje(e.target.value)}
					onKeyDown={(e) => (e.key === "Enter" ? saveMesaje() : null)}
				></textarea>
			</ModalBody>
			<ModalFooter>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => saveMesaje()}
				>
					Enviar
				</button>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => setModal()}
				>
					Cerrar
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalMensajesLead;
