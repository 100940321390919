import styles from "./index.module.scss";

const ModalProposalItem = ({
  children,
  description,
  registerInput,
  defaultValue,
  onKeyDown,
  onInput,
}) => {
  return (
    <div className={`${styles.wrapper} d-flex flex-column p-2`}>
      <span className="tittle_sub mb-2">{description}</span>
      <input
        {...registerInput}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
        onInput={onInput}
      />
      {children}
    </div>
  );
};

export default ModalProposalItem;
