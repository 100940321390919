import * as Yup from "yup";

const createCaseFormSchema = Yup.object({
	fullName: Yup.string().min(1, "Campo obligatorio").max(100, "Máximo 100 caracteres"),
	phone: Yup.string().phone(),
	title: Yup.string().min(1, "Campo obligatorio"),
	description: Yup.string().min(1, "Campo obligatorio"),
	email: Yup.string().email()
});

const withdrawalReceptionFormSchema = Yup.object({
	fullName: Yup.string().min(1, "Campo obligatorio").max(100, "Máximo 100 caracteres"),
	identification: Yup.string().identification(),
	phone: Yup.string().phone(),
	email: Yup.string().email(),
	make: Yup.string().optional(),
	model: Yup.string().optional(),
	plate: Yup.string().optional(),
	kmOnDelivery: Yup.number()
		.required("Campo obligatorio")
		.positive("Los Km deben ser mayor que 0")
		.max(999999, "Km debe ser igual o inferior a 999.999"),
	warranty: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	local: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	agentFullName: Yup.string().min(1, "Campo obligatorio").max(100, "Máximo 100 caracteres"),
	afterSaleEmail: Yup.string().email(),
	isOwnerVerified: Yup.boolean().required("Campo obligatorio"),
	hasDrivingLicence: Yup.boolean().required("Campo obligatorio"),
	clientIssue: Yup.string().max(500, "Máximo 500 caracteres").optional()
});

const createAfterSalesAppointmentSchema = Yup.object({
	appointmentLocal: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	appointmentDate: Yup.date().required("Campo obligatorio")
});

const updateCaseEmailSchema = Yup.object({
	email: Yup.string().email()
});

const createCaseComment = Yup.object({
	comment: Yup.string().min(1, "Campo obligatorio"),
	amount: Yup.number().max(99999.99, "Valor máximo 99999.99").optional()
});

export {
	createCaseFormSchema,
	withdrawalReceptionFormSchema,
	createAfterSalesAppointmentSchema,
	updateCaseEmailSchema,
	createCaseComment
};
