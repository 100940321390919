export const initialEmployeeFilters = {
    types: [],
    locales: [],
    state: "",
};

export const filterReducer = (state, action) => {
    switch (action.type) {
        case "changed_types":
            return {
                ...state,
                types: action.nextEmpTypes
            };

        case "changed_locales":
            return {
                ...state,
                locales: action.nextEmpLocales
            };

        case "changed_state":
            return {
                ...state,
                state: action.nextState?.value ?? initialEmployeeFilters.state,
            };

        default:
            throw Error("Unkown action " + action.type);
    }
};