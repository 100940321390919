import { forwardRef } from "react";
import { NumberFormatBase } from "react-number-format";

/**
 * @type React.ForwardRefRenderFunction<?, import('react-number-format').NumberFormatBaseProps>
 */
const IBANInput = forwardRef(({ onChange, ...props }, ref) => (
	<NumberFormatBase
		{...props}
		type="text"
		getInputRef={ref}
		format={(value) =>
			value
				.replace(/\s+/g, "")
				.slice(0, 24)
				.replace(/(.{4})/g, "$1 ")
				.trim()
				.toLocaleUpperCase()
		}
		removeFormatting={(value) => value.replace(/\s+/g, "").slice(0, 24)}
		isValidInputCharacter={(char) => /^[a-z0-9]$/i.test(char)}
		getCaretBoundary={(value) =>
			Array(value.length + 1)
				.fill(0)
				.map((v) => true)
		}
		onValueChange={(values, { event }) =>
			onChange(
				Object.assign({}, event, {
					target: { name: props.name, value: values.value.replace(/\s+/g, "").toLocaleUpperCase() }
				})
			)
		}
		onKeyDown={(e) =>
			!/^(?:[a-z0-9]|Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)$/i.test(
				e.key
			) && e.preventDefault()
		}
	/>
));

export default IBANInput;
