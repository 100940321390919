import React from "react";
import DropdownStandar from "@components/DropdownStandar";
import styles from "./index.module.scss";

const AddAgentBlock = ({
  acceptAgent,
  listAgents,
  getListAgents,
  agentSelected,
  handleAgent,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <button className={styles.button} onClick={() => getListAgents()}>
          {listAgents ? "Cancelar" : "Añadir agente"}
        </button>
      </div>
      <div className={styles.box}>
        {listAgents && (
          <DropdownStandar
            data={listAgents}
            selected={agentSelected}
            handleSelect={handleAgent}
          />
        )}
      </div>
      <div className={styles.box}>
        {agentSelected && (
          <button className={styles.button} onClick={() => acceptAgent()}>
            Confirmar
          </button>
        )}
      </div>
    </div>
  );
};
export default AddAgentBlock;
