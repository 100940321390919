import React, { useState } from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import { RiArrowGoBackFill } from "react-icons/ri";

const ModalBack = ({ createLead }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const send = async () => {
    await createLead();
    await toggle();
    await setTimeout(goback, 1000);
  };
  let history = useHistory();
  const goback = async () => {
    await history.push("/main/listaleads?section=propios");
  };
  return (
    <>
      <div>
        <Button
          color="primary"
          className={styles.back}
          onClick={() => toggle()}
        >
          <RiArrowGoBackFill size={30} role="button" />
        </Button>

        <Modal isOpen={modal}>
          <ModalHeader>
            ¿Guardar cambios antes de abandonar la página?
          </ModalHeader>

          <ModalFooter>
            <Button color="primary" onClick={send}>
              Guardar
            </Button>
            {""}
            <Button
              color="secondary"
              onClick={() => history.push("/main/listaleads?section=propios")}
            >
              Descartar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default ModalBack;
