import { useContext, useEffect, useState } from "react";
import { Row, Col, Label, Input, Button } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useTranslation } from "react-i18next";
import moment from "moment";

import styles from "./index.module.scss";
import DatosVehiculoClasico from "@components/DatosVehiculosClasico";
import ModalUpdateLocalVehicle from "@components/ModalUpdateLocalVehicle";
import { UserContext } from "@contexts/UserContext";
import RolType from "@constants/roles";
import { getColors, putLocal } from "@services/Vehicle/vehicle.service";
import thousandsFormat from "@helpers/thousandsFormat";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { getFilterAllDealersihps } from "@services/Filtros/filtros";
import Select from "@components/Select";

registerLocale("es", es);

const VehicleValidationData = ({
	vehiculo,
	setVehiculo,
	type,
	errors,
	handleSimpleInput,
	handleNumberThousands,
	handleFilter,
}) => {
	const { t } = useTranslation("vehicleValidation");
	const { rol } = useContext(UserContext);

	const [minDate, setMinDate] = useState();
	const [maxDate, setMaxDate] = useState();

	const [color, setColor] = useState([]);

	const [openChangeLocalModal, setOpenChangeLocalModal] = useState({
		open: false
	});

	const changeDealer = async ({ dealer }) => {
		try {
			await putLocal({
				id: vehiculo.id,
				type: type,
				idDealer: dealer[0].value
			});
			successToast(`Vehículo trasladado `);
			setOpenChangeLocalModal({ open: false });
			setVehiculo({ ...vehiculo, local: dealer });
		} catch (e) {
			errorToast(<span>{t("errors.errorTransfer")}</span>);
		}
	};

	useEffect(() => {
		if (vehiculo?.matriculacion[0]?.value) {
			setMinDate(new Date(`${vehiculo?.matriculacion[0]?.value}-01-01`));
			setMaxDate(new Date(`${vehiculo?.matriculacion[0]?.value}-12-31`));
		}
	}, [vehiculo]);

	useEffect(() => {
		(async () => {
			try {
				const colores = await getColors();
				setColor(colores);
			} catch (err) {
				errorToast("Error al cargar los colores de la carrocería");
			}
		})();
	}, []);

	return (
		<>
			<Row className={styles.centerRow}>
				<Col
					xs="12"
					sm="12"
				>
					<DatosVehiculoClasico
						vehiculo={vehiculo}
						setVehiculo={setVehiculo}
						mode={vehiculo.jato ? "JATO" : "MANUAL"}
						type={type}
					/>
				</Col>
				<Col
					xs="12"
					sm="12"
				>
					<Col
						xs="12"
						sm="6"
					>
						<DatePicker
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							minDate={minDate}
							maxDate={maxDate}
							className={styles.date}
							selected={vehiculo?.fecha_matriculacion}
							onChange={(element) =>
								handleSimpleInput({ target: { value: element } }, "fecha_matriculacion")
							}
							locale="es"
							customInput={
								vehiculo.fecha_matriculacion ? (
									<Label>
										{t("registrationDate")}
										<Input value={moment(vehiculo?.fecha_matriculacion).format("DD-MM-YYYY")} />
									</Label>
								) : (
									<Label>
										{t("registrationDate")}
										<Input value={"Seleccione una fecha"} />
									</Label>
								)
							}
						/>
					</Col>
				</Col>
			</Row>

			<Row className={styles.centerRow}>
				<Col
					xs="12"
					sm="12"
				>
					<h4 className={styles.bold}> {t("characteristics")}</h4>
				</Col>

				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("displacement")} </Label>
					<Input
						placeholder={t("displacement")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo?.cilindrada}
						min="0"
						onChange={(e) => {
							handleSimpleInput(e, "cilindrada");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("power")} *</Label>
					<Input
						placeholder={t("power")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo?.potencia}
						min="0"
						onChange={(e) => {
							handleSimpleInput(e, "potencia");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("co2Emissions")} </Label>
					<Input
						placeholder={t("co2Emissions")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo.emisiones}
						min="0"
						onChange={(e) => {
							handleSimpleInput(e, "emisiones");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("trunkCapacity")}</Label>
					<Input
						placeholder={t("trunkCapacity")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo.maletero}
						min="0"
						onChange={(e) => {
							handleSimpleInput(e, "maletero");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("consumption")}</Label>
					<div style={{ display: "flex" }}>
						<Input
							placeholder={"Combi l/100"}
							className="form-control ds-input mt-1"
							type="number"
							disabled={vehiculo.jato}
							value={vehiculo.consumo_combi}
							min="0"
							onChange={(e) => {
								handleSimpleInput(e, "consumo_combi");
							}}
						/>
						<Input
							placeholder={"Road l/100"}
							className="form-control ds-input mt-1"
							type="number"
							disabled={vehiculo.jato}
							value={vehiculo.consumo_road}
							min="0"
							onChange={(e) => {
								handleSimpleInput(e, "consumo_road");
							}}
						/>
						<Input
							placeholder={"Urban l/100"}
							disabled={vehiculo.jato}
							className="form-control ds-input mt-1"
							type="number"
							min="0"
							value={vehiculo.consumo_urban}
							onChange={(e) => {
								handleSimpleInput(e, "consumo_urban");
							}}
						/>
					</div>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("seats")}*</Label>
					<Input
						placeholder={t("seats")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo.asientos}
						min="1"
						onChange={(e) => {
							handleSimpleInput(e, "asientos");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("doors")}*</Label>
					<Input
						placeholder={t("doors")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo.puertas}
						min="1"
						onChange={(e) => {
							handleSimpleInput(e, "puertas");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("numberOfGears")}</Label>
					<Input
						placeholder={t("numberOfGears")}
						className="form-control ds-input mt-1"
						type="number"
						disabled={vehiculo.jato}
						value={vehiculo.marchas}
						min="1"
						onChange={(e) => {
							handleSimpleInput(e, "marchas");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("ecoStiker")}</Label>
					<div className={styles.filterAdjust}>
						<Input
							placeholder={t("ecoStiker")}
							className="form-control ds-input mt-1 pointer"
							type="select"
							invalid={errors.ecoSticker === true}
							value={vehiculo?.ecoSticker[0]?.value}
							disabled={vehiculo.jato}
							onChange={(e) => {
								setVehiculo({
									...vehiculo,
									ecoSticker: [
										{
											label: e.target.selectedOptions[0].text,
											value: Number(e.target.value)
										}
									]
								});
							}}
						>
							<option value={0}> </option>
							<option value={1}>0</option>
							<option value={2}>E</option>
							<option value={3}>C</option>
							<option value={4}>B</option>
						</Input>
					</div>
				</Col>
			</Row>
			<Row className={styles.centerRow}>
				<Col
					xs="12"
					sm="12"
				>
					<h4 className={styles.bold}>{t("vehicleCondition")}</h4>
				</Col>

				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("mileage")}*</Label>
					<Input
						placeholder={t("mileage")}
						className="form-control ds-input mt-1"
						type="text"
						value={typeof vehiculo.km === "number" ? thousandsFormat(vehiculo.km) : vehiculo.km}
						invalid={vehiculo.km === ""}
						onChange={(e) => {
							handleNumberThousands(e, "km");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("officialBrandWarranty")}</Label>
					<div className={styles.filterAdjust}>
						<Input
							placeholder={t("officialBrandWarranty")}
							className="form-control ds-input mt-1 pointer"
							type="select"
							invalid={errors.garantia === true}
							value={vehiculo?.garantia[0]?.value}
							onChange={(e) => {
								setVehiculo({
									...vehiculo,
									garantia: [
										{
											label: e.target.selectedOptions[0].text,
											value: Number(e.target.value)
										}
									]
								});
							}}
						>
							<option value={3}>Sí</option>
							<option value={0}>No</option>
						</Input>
					</div>
				</Col>
				{vehiculo?.garantia[0]?.value === 3 ? (
					<Col
						xs="12"
						sm="4"
					>
						<DatePicker
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={`${styles.date}`}
							selected={vehiculo.fecha_garantia}
							onChange={(element) =>
								handleSimpleInput({ target: { value: element } }, "fecha_garantia")
							}
							locale="es"
							customInput={
								vehiculo.fecha_garantia ? (
									<Label>
										{"Fecha Fin Garantía Oficial Marca"}
										<Input value={moment(vehiculo.fecha_garantia).format("DD-MM-YYYY")} />
									</Label>
								) : (
									<Label>
										{"Fecha Fin Garantía Oficial Marca"}
										<Input value={"Seleccione una fecha"} />
									</Label>
								)
							}
						/>
					</Col>
				) : null}
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("provenance")}*</Label>
					<div className={styles.filterAdjust}>
						<Input
							placeholder={t("provenance")}
							className="form-control ds-input mt-1 pointer"
							type="select"
							invalid={errors.procedencia === true}
							value={vehiculo?.procedencia[0]?.value}
							onChange={(e) => {
								setVehiculo({
									...vehiculo,
									procedencia: [
										{
											label: e.target.selectedOptions[0].text,
											value: Number(e.target.value)
										}
									]
								});
							}}
						>
							<option value={1}>{t("rentWithoutDriver")}</option>
							<option value={2}>{t("privateIndividual")}</option>
							<option value={3}>{t("publicService")}</option>
							<option value={4}>{t("drivingSchool")}</option>
							<option value={5}>{t("fleet")} </option>
							<option value={6}>{t("independent")}</option>
							<option value={7}>{t("company")} </option>
						</Input>
					</div>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("bodyColor")}*</Label>
					<div style={{ display: "flex" }}>
						<div style={{ width: "100%" }}>
							<Select
								placeholder={t("bodyColor")}
								options={color.map((x, y) => ({
									label: x.name,
									value: x.id
								}))}
								value={vehiculo?.color}
								onChange={(e) => {
									setVehiculo({
										...vehiculo,
										color: [e]
									});
								}}
							/>
						</div>
					</div>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("upholstery")}</Label>
					<Input
						placeholder={t("upholstery")}
						className="form-control ds-input mt-1"
						value={vehiculo.tcolor}
						invalid={vehiculo.tcolor === ""}
						onChange={(e) => {
							handleSimpleInput(e, "tcolor");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<DatePicker
						//scrollableYearDropdown
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						className={`${styles.date}`}
						selected={vehiculo?.vto_itv}
						onChange={(element) => handleSimpleInput({ target: { value: element } }, "vto_itv")}
						locale="es"
						customInput={
							vehiculo.vto_itv ? (
								<Label>
									{t("itvExpirationDate")}
									<Input value={moment(vehiculo.vto_itv).format("DD-MM-YYYY")} />
								</Label>
							) : (
								<Label>
									{t("itvExpirationDate")}
									<Input value={"Seleccione una fecha"} />
								</Label>
							)
						}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<div style={{ display: "flex" }}>
						<DatePicker
							//scrollableYearDropdown
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={`${styles.date}`}
							selected={vehiculo.ultima_itv}
							maxDate={Date.now()}
							onChange={(element) =>
								handleSimpleInput({ target: { value: element } }, "ultima_itv")
							}
							locale="es"
							customInput={
								vehiculo.ultima_itv ? (
									<Label>
										{t("lastItvDate")}
										<Input value={moment(vehiculo.ultima_itv).format("DD-MM-YYYY")} />
									</Label>
								) : (
									<Label>
										{t("lastItvDate")}
										<Input value={"Seleccione una fecha"} />
									</Label>
								)
							}
						/>
					</div>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("itvResult")}</Label>
					<div className={styles.filterAdjust}>
						<Input
							placeholder={t("itvResult")}
							className="form-control ds-input mt-1 pointer"
							type="select"
							invalid={errors.resultado_itv === true}
							value={vehiculo?.resultado_itv[0]?.value}
							onChange={(e) => {
								setVehiculo({
									...vehiculo,
									resultado_itv: [
										{
											label: e.target.selectedOptions[0].text,
											value: Number(e.target.value)
										}
									]
								});
							}}
						>
							<option value={0}></option>
							<option value={3}>{t("unfavorable")}</option>
							<option value={2}>{t("favorableWithMinorDefects")}</option>
							<option value={1}>{t("favorable")}</option>
							<option value={4}>{t("negative")}</option>
						</Input>
					</div>
				</Col>
				<Col
					xs="12"
					sm="12"
				>
					<Label
						style={{ cursor: "pointer", marginLeft: "30px" }}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									noItv: vehiculo.noItv ? false : true
								});
							}}
							checked={vehiculo.noItv}
						/>
						{t("noItv")}
					</Label>
				</Col>
				<Col
					xs="12"
					sm="12"
				>
					<Label
						style={{ cursor: "pointer", marginLeft: "30px" }}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									km0: vehiculo.km0 ? false : true
								});
							}}
							checked={vehiculo.km0}
						/>
						{t("zeroKm")}
					</Label>
				</Col>
				<Col
					xs="12"
					sm="12"
				>
					<Label
						style={{ cursor: "pointer", marginLeft: "30px" }}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									upropietario: vehiculo.upropietario ? false : true
								});
							}}
							checked={vehiculo.upropietario}
						/>
						{t("singleOwner")}
					</Label>
				</Col>
				<Col
					xs="12"
					sm="12"
				>
					<Label
						style={{ cursor: "pointer", marginLeft: "30px" }}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									importado: vehiculo.importado ? false : true
								});
							}}
							checked={vehiculo.importado}
						/>
						{t("imported")}
					</Label>
				</Col>
			</Row>
			<Row className={styles.centerRow}>
				<Col
					xs="12"
					sm="12"
				>
					<h4 className={styles.bold}> {t("location")}</h4>
				</Col>

				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}> {t("location")}*</Label>
					<Select
						placeholder="Empresas"
						loadOptions={getFilterAllDealersihps}
						value={vehiculo?.local}
						onChange={(opt) => handleFilter(opt.value, "local")}
						isDisabled
					/>
				</Col>
				{rol?.some(
					(e) =>
						e === RolType.TRADING_MANAGER_PILOT ||
						e === RolType.TRADING_MANAGER ||
						e === RolType.GENERAL_ADMIN
				) ? (
					<Button
						className="btn-flexicar mt-4 mr-5 left no-print"
						onClick={() => setOpenChangeLocalModal({ open: true })}
					>
						{t("moveVehicle")}
					</Button>
				) : null}
			</Row>

			<ModalUpdateLocalVehicle
				changeLocal={openChangeLocalModal}
				setChangeLocal={setOpenChangeLocalModal}
				createVehicleDraft={changeDealer}
			/>
		</>
	);
};

export default VehicleValidationData;
