import styles from "@styles/views/SellContract/client-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";

const ClientData = ({ provincesOptions }) => {
	return (
		<div>
			<PaneHeader title="Datos del cliente" />
			<div className={styles.container}>
				<FormInput
					name="client.name"
					label="Nombre"
				/>
				<FormInput
					name="client.firstSurname"
					label="Primer apellido"
				/>
				<FormInput
					name="client.secondSurname"
					label="Segundo apellido"
				/>
				<FormInput
					name="client.phone"
					label="Teléfono"
				/>
				<FormInput
					name="client.email"
					label="Email"
				/>
				<FormInput
					name="client.dni"
					label="DNI/NIE"
				/>

				<FormDatePicker
					name="client.dateOfBirth"
					label="Fecha de nacimiento"
				/>
			</div>
			<div className={styles.container}>
				<FormSelect
					name="client.province"
					label="Provincia"
					options={provincesOptions}
				/>
				<FormInput
					name="client.town"
					label="Población"
				/>
				<FormInput
					name="client.address"
					label="Dirección"
				/>
				<FormInput
					name="client.cp"
					label="CP"
				/>
			</div>
		</div>
	);
};

export default ClientData;
