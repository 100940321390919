import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { FiPaperclip } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import styles from "./index.module.scss";
import Form from "@components/Form/Form";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import CaseComment from "@components/CaseComment";
import UploadNoCallComponent from "@components/UploadNoCallComponent";
import { UserContext } from "@contexts/UserContext";
import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import Loading from "@views/Loading/Loading";
import { createCaseComment } from "@schema/postventaSchema";

const CasoComments = ({ caseId }) => {
	const { user: agentId } = useContext(UserContext);
	const formRef = useRef(null);

	const [isLoading, setIsLoading] = useState(true);

	const [comments, setComments] = useState([]);

	const [file, setFile] = useState(null);
	const [showAddFileModal, setShowAddFileModal] = useState(false);

	const resetAddCommentForm = () => {
		formRef.current?.reset();
		setFile(null);
	};

	const addNewComment = async (formData) => {
		try {
			const data = new FormData();
			if (file) {
				data.append("file", file.object);
				data.append("fileName", file.name);
			}
			data.append("caso", caseId);
			data.append("comentario", formData.comment);
			data.append("agente", agentId);
			if (formData.amount) data.append("amount", formData.amount);

			await instance.post(API.ADD_COMMENT, data);
			const newComments = await instance.post(API.GET_COMMENTS, {
				id: Number(caseId)
			});
			setComments(newComments.data);
			resetAddCommentForm();
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al crear el comentario");
		} finally {
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const commentsResponse = await instance.post(API.GET_COMMENTS, {
					id: Number(caseId)
				});
				setComments(commentsResponse.data);
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al obtener los comentarios");
			}

			setIsLoading(false);
		})();
	}, [caseId]);

	if (isLoading) return <Loading />;

	return (
		<div className="flex flex-column">
			<Form
				className={styles.newCommentForm}
				onSubmit={addNewComment}
				ref={formRef}
				schema={createCaseComment}
			>
				<div>
					<div className={`${styles.inputWrapper} ${styles.newCommentText}`}>
						<FormTextArea
							name="comment"
							label="Comentario*"
							placeholder="Escribe aquí tu comentario"
						/>
					</div>

					<div className={`${styles.inputWrapper} ${styles.newCommentAmount}`}>
						<FormFormattedNumberInput
							name="amount"
							label="Importe"
						/>
					</div>
				</div>

				<div className={styles.newCommentDoc}>
					{!file ? (
						<span>
							<FiPaperclip
								size={36}
								onClick={() => setShowAddFileModal(true)}
							/>
							<span style={{ color: "#D34600" }}>Adjuntar archivo</span>
						</span>
					) : (
						<span>
							<RiDeleteBin6Line
								size={36}
								color="#ED0530"
								onClick={() => setFile(null)}
							/>
							<span style={{ color: "black" }}>{file.name}</span>
						</span>
					)}
				</div>

				<button
					className="btn btn-flexicar-orange"
					type="submit"
				>
					Añadir
				</button>
			</Form>

			<hr className="w-100" />

			{comments.map((comment) => (
				<Fragment key={comment.documentId}>
					<CaseComment
						comment={comment}
						setComment={(updatedComment) =>
							setComments(
								comments.map((comm) =>
									comm.documentId === updatedComment.documentId
										? { ...comm, ...updatedComment }
										: comm
								)
							)
						}
					/>
					<hr className={styles.divider} />
				</Fragment>
			))}

			<UploadNoCallComponent
				onFile={setFile}
				show={showAddFileModal}
				setShow={setShowAddFileModal}
			/>
		</div>
	);
};

export default CasoComments;
