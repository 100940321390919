import { IoClose } from "react-icons/io5";

import "@styles/components/double-filter-chips-list.scss";

/**
 * A component that displays a list of chips for each elemets that recives.
 *
 * @param {Object} props - The component props.
 * 
 * @param {Array<{name: string, option: string, filters: string[] }>} props.dropdownSelected - An array of objects of dropdown values selected.
 * @param {function} props.deleteDropdownSelected - Function that deletes each chip by clicking.

 * @returns {React.ReactNode} The rendered React component.
 */

const DoubleFilterChipsList = ({
    dropdownSelected,
    deleteDropdownSelected,
}) => {

    return (
        <>
            {dropdownSelected.map(filter => {
                return filter.filters?.map((item, idx) => (
                    <div className="chipContainer" key={item+idx}>
                        {item}
                        <span
                            className="react-dropdown-select-option-remove pointer ml-1"
                            onClick={() => deleteDropdownSelected(item, idx, filter.name, filter.option)}
                        >
                            <IoClose size={14} />
                        </span>
                    </div>
                ))
            })}

        </>
    );
};

export default DoubleFilterChipsList;