import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiAlertCircle } from "react-icons/fi";

import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import useSearchParam from "@hooks/useSearchParam";
import { withdrawalReceptionFormSchema } from "@schema/postventaSchema";
import FormattedNumberInput from "@components/FormattedNumberInput";
import { errorToast } from "@helpers/toastFunctions";
import { get, postWithStatus } from "@services/axiosCalls";
import {
	WARRANTY_OPTIONS,
	typeDocument,
	typeDocumentTranslate
} from "@constants/documentWithdrawReception";
import { UserContext } from "@contexts/UserContext";
import Loading from "@views/Loading/Loading";
import Select from "@components/Select";

const WithdrawalReceptionForm = () => {
	const { vehicleId, caseId, documentId } = useParams();
	const history = useHistory();
	const { getSearchParam } = useSearchParam();
	const { name } = useContext(UserContext);

	const [docType, setDocType] = useState(null);
	const [docTypeText, setDocTypeText] = useState(null);
	const [locales, setLocales] = useState([]);

	const loadCreationDefaultValues = async () => {
		const { client, vehicle, warranty, dealership } = await get(
			API.GET_VEHICLE_DRAFT_POST_VENTA(caseId)
		);
		const type = getSearchParam("type");
		setDocType(type);
		setDocTypeText(type);

		return {
			fullName: client.fullName,
			phone: client.phone,
			email: client.email,
			make: vehicle.make.name,
			model: vehicle.model.name,
			plate: vehicle.plate,
			warranty: warranty
				? { label:warranty.name, value: warranty.id }
				: WARRANTY_OPTIONS[0],
			local: { label: dealership.name, value: dealership.id },
			agentFullName: `${name.nombre} ${name.apellido1} ${name.apellido2}`
		};
	};

	const loadEditionDefaultValues = async () => {
		const { data } = await get(API.GET_VEHICLE_POST_VENTA(caseId, documentId));
		setDocType(data.type);
		setDocTypeText(typeDocumentTranslate[data.type]);

		return {
			fullName: data.client.fullName,
			identification: data.client.identification,
			phone: data.client.phone,
			email: data.client.email,
			make: data.vehicle.make.name,
			model: data.vehicle.model.name,
			plate: data.vehicle.plate,
			kmOnDelivery: data.vehicle.kmOnDelivery,
			warranty: data.warranty
				? { label: data.warranty.name, value: data.warranty.id }
				: WARRANTY_OPTIONS[0],
			local: { label: data.dealership.name, value: data.dealership.id },
			agentFullName: `${name.nombre} ${name.apellido1} ${name.apellido2}`,
			afterSaleEmail: data.afterSaleEmail,
			isOwnerVerified: data.isOwnerVerified,
			hasDrivingLicence: data.hasDrivingLicence,
			clientIssue: data.clientIssue || ""
		};
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isLoading },
		control,
	} = useForm({
		resolver: yupResolver(withdrawalReceptionFormSchema),
		defaultValues: !documentId ? loadCreationDefaultValues : loadEditionDefaultValues
	});

	const onSubmit = async (formData) => {
		const {
			warranty,
			local,
			clientIssue,
			fullName,
			email,
			phone,
			identification,
			kmOnDelivery,
			afterSaleEmail,
			isOwnerVerified,
			hasDrivingLicence
		} = formData;

		// Hacemos esto para pasar null al back y validar el select cuando no esta seleccionado
		// ya que si intentamos pasar null al value da errores
		const realWarranty = warranty.value === 0 ? null : warranty.value;

		const data = {
			client: {
				fullName,
				email,
				phone,
				identification
			},
			vehicle: {
				kmOnDelivery
			},
			warrantyTypeId: realWarranty,
			clientIssue: clientIssue || null,
			dealershipId: local.value,
			afterSaleEmail,
			isOwnerVerified,
			hasDrivingLicence,
			type: !documentId ? typeDocument[docType] : docType
		};
		try {
			const response = !documentId
				? await postWithStatus(API.CREATE_DOCUMENT_POST_VENTA(caseId), data)
				: await instance.put(API.EDIT_DOCUMENT_POST_VENTA(caseId, documentId), data);

			if (response.status === 201 || response.status === 204) {
				history.push(`/main/vehicle/${vehicleId}/caso/${caseId}?tab=docs`);
			}
		} catch (e) {
			errorToast(<span>{e.message}</span>);
		}
	};

	const barButtons = (
		<>
			<button
				className="btn btn-flexicar-inverse"
				onClick={() => history.push(`/main/vehicle/${vehicleId}/caso/${caseId}?tab=docs`)}
			>
				Volver
			</button>
			<button
				className="btn btn-flexicar-orange"
				onClick={handleSubmit(onSubmit)}
			>
				{!documentId ? "Generar" : "Editar"}
			</button>
		</>
	);

	const error = (name) =>
		errors[name] && (
			<p className={styles.customSelectError}>
				<FiAlertCircle size={20} />
				{errors[name].message}
			</p>
		);

	const optionGroup = (name) => (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, onBlur, value: renderValue, ref } }) => (
				<div>
					{[
						{ label: "Si", value: true },
						{ label: "No", value: false }
					].map(({ label, value }) => (
						<Fragment key={`radio-group-${name}-${label}`}>
							<label className={styles.customRadioButtonWrapper}>
								<input
									type="radio"
									onBlur={onBlur}
									onChange={() => onChange(value)}
									checked={renderValue === value}
									ref={ref}
								/>
								<span>{label}</span>
							</label>
						</Fragment>
					))}
				</div>
			)}
		/>
	);

	const selectController = (name, options) => (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<Select
					{...field}
					options={options}
				/>
			)}
		/>
	);

	useEffect(() => {
		(async () => {
			try {
				const response = await instance.post(API.FILTROS, {
					dataCall: {
						data_query: "concesionarios_flexicar",
						data_call: null
					}
				});
				setLocales(response.data.map(({ id, nombre }) => ({ label: nombre, value: id })));
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al cargar los locales");
			}
		})();
	}, [vehicleId, caseId, documentId]);

	if (isLoading) return <Loading />;

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={`Formulario documento ${docTypeText}`}
				buttons={barButtons}
			>
				<form className={styles.form}>
					<div>
						<span>Datos del Cliente</span>
						<div className={styles.dataClientWrapper}>
							<div>
								<label>Nombre completo*</label>
								<input
									type="text"
									{...register("fullName")}
								/>
								{error("fullName")}
							</div>
							<div>
								<label>DNI/NIE*</label>
								<input
									type="text"
									{...register("identification")}
								/>
								{error("identification")}
							</div>
							<div>
								<label>Teléfono*</label>
								<input
									type="text"
									{...register("phone")}
								/>
								{error("phone")}
							</div>
							<div>
								<label>Email*</label>
								<input
									type="text"
									{...register("email")}
								/>
								{error("email")}
							</div>
						</div>
					</div>

					<div>
						<span>Datos del vehículo</span>
						<div className={styles.dataVehicleWrapper}>
							<div>
								<label>Marca</label>
								<input
									type="text"
									{...register("make")}
									disabled
								/>
							</div>
							<div>
								<label>Modelo</label>
								<input
									type="text"
									{...register("model")}
									disabled
								/>
							</div>
							<div>
								<label>Matrícula</label>
								<input
									type="text"
									{...register("plate")}
									disabled
								/>
							</div>
							<div>
								<label>Km a la entrega*</label>
								<Controller
									name="kmOnDelivery"
									control={control}
									render={({ field }) => (
										<FormattedNumberInput
											{...field}
											suffix=""
											placeholder=""
											decimalScale={0}
										/>
									)}
								/>
								{error("kmOnDelivery")}
							</div>
							<div>
								<label>Garantía*</label>
								{selectController("warranty", WARRANTY_OPTIONS)}
								{error("warranty")}
							</div>
						</div>
					</div>

					<div>
						<span>Datos del concesionario</span>
						<div className={styles.dataDealershipWrapper}>
							<div>
								<label>Local*</label>
								{selectController("local", locales)}
								{error("local")}
							</div>
							<div>
								<label>Agente CC*</label>
								<input
									type="text"
									{...register("agentFullName")}
									disabled
								/>
								{error("agentFullName")}
							</div>
							<div>
								<label>Email Postventa*</label>
								<input
									type="text"
									{...register("afterSaleEmail")}
								/>
								{error("afterSaleEmail")}
							</div>
							<div>
								<div>
									<label>Verificación porpietario*</label>
									{optionGroup("isOwnerVerified")}
								</div>
								{error("isOwnerVerified")}
							</div>
							<div>
								<div>
									<label>Verificación permiso de conducir*</label>
									{optionGroup("hasDrivingLicence")}
								</div>
								{error("hasDrivingLicence")}
							</div>
							<div>
								<label>Incidencias comentadas por el cliente</label>
								<textarea {...register("clientIssue")} />
								{error("clientIssue")}
							</div>
						</div>
					</div>
				</form>
			</Mainlayout.Content>
		</Mainlayout>
	);
};
export default WithdrawalReceptionForm;
