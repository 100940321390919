import { environment } from "@config/environment.config";
import { getCookie } from "@helpers/getCookie";
import instance from "@services/BaseService";

export const checkVideo = async (video_id) => {
  try {
    const response = await instance.get(
      "https://www.googleapis.com/youtube/v3/videos",
      {
        params: {
          key: environment.API_KEY_YOUTUBE,
          id: video_id,
          part: "snippet,contentDetails",
        },
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getVideos = async (route, data, off) => {
  try {
    const response = await instance.get(`/api/v1${route}`, {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: { limit: 20, offset: off },
      ...data,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const postVideo = async (route, data) => {
  try {
    const response = await instance.post(`/api/v1${route}`, data, {
      headers: {
        Authorization: `Bearer ${getCookie("JWT")}`,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const patchVideo = async (route, data) => {
  try {
    const response = await instance.patch(`/api/v1${route}`, data, {
      headers: {
        Authorization: `Bearer ${getCookie("JWT")}`,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
