import * as Yup from "yup";

import { sellContractClientSchema } from "@schema/SellContract/clientSchema";
import { sellContractVehicleSchema } from "@schema/SellContract/vehicleSchema";
import { sellContractSellInfoSchema } from "@schema/SellContract/sellDataSchema";
import { sellContractPayAmountsSchema } from "@schema/SellContract/sellResumeSchema";

const sellContractSchema = Yup.object({
	client: sellContractClientSchema,
	vehicle: sellContractVehicleSchema,
	sellInfo: sellContractSellInfoSchema,
	payAmounts: sellContractPayAmountsSchema,
	observations: Yup.string().nullable().default("").max(300, "Máximo 300 caracteres"),
});

export { sellContractSchema };
