import { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import { MdFileCopy } from "react-icons/md";

import styles from "@styles/components/contractId.module.scss"

import DocumentationType from "@constants/documentationType";
import { errorToast } from "@helpers/toastFunctions";

/**
 * ContractId component renders a contract ID and provides a functionality
 * to copy it to the clipboard with a tooltip indication.
 *
 * @component
 * @param {Object} props - React props
 * @param {number} props.contractType - The contract type which determines the type of contract
 * @param {number} props.contractId - The contract ID
 * @returns {JSX.Element} The rendered component
 */
const ContractId = ({ contractType, contractId, children }) => {
	const [generatedId, setGeneratedId] = useState("");
	const [showTooltip, setShowTooltip] = useState(false);

	const copyTextToClipboard = () => {
		navigator.clipboard
			.writeText(generatedId)
			.then(() => {
				setShowTooltip(true);
				setTimeout(() => setShowTooltip(false), 2000);
			})
			.catch(() => errorToast("Error al copiar el texto"));
	};

	useEffect(() => {
		const idKey = {
			[DocumentationType.CONTRATO_VENTA]: "V",
			[DocumentationType.RESERVA]: "R",
			[DocumentationType.COMPRA_DIRECTA]: "C",
			[DocumentationType.GESTION_VENTA]: "G"
		}[contractType];

		setGeneratedId(`${idKey}${contractId}`);
	}, [contractType, contractId]);

	return (
		<div className={styles.container}>
			{children}
			<span>{generatedId}</span>
			<MdFileCopy
				id={`copy-${contractId}`}
				size={24}
				color="#D34600"
				onClick={copyTextToClipboard}
			/>

			<Tooltip
				target={`copy-${contractId}`}
				isOpen={showTooltip}
			>
				Copiado
			</Tooltip>
		</div>
	);
};

export default ContractId;
