import React from "react";
// eslint-disable-next-line no-unused-vars
import { Props as SelectProps } from "react-select";
import { Controller, useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/select.module.scss";
import useFormError from "@components/Form/hooks/useFormError";
import Select from "@components/Select";

/**
 * FormSelect component for rendering a select input.
 *
 * @typedef {object} ExtraFormSelectProps
 * @property {string} label - The label text for the select input.
 * @property {Function} additionalOnChangeCb - Additional onChange callback (optional).
 */

/**
 * @typedef {SelectProps & ExtraFormSelectProps} FormSelectProps
 */

/**
 * @param {FormSelectProps} props - Props for the FormSelect component.
 * @returns {JSX.Element} The rendered component.
 */
const FormSelect = ({ label, additionalOnChangeCb = () => {}, ...rest }) => {
	const { control } = useFormContext();
	const { markupError } = useFormError(rest.name);

	return (
		<div className={styles.customSelectWrapper}>
			<label>{label}</label>
			<Controller
				name={rest.name}
				control={control}
				render={({ field }) => (
					<Select
						{...field}
						{...rest}
						onChange={(option) => {
							field.onChange(option);
							additionalOnChangeCb(option);
						}}
					/>
				)}
			/>

			{markupError}
		</div>
	);
};

export default FormSelect;
