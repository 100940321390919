import styles from "./index.module.scss";

const Chip = ({ name, bgColor, onClick }) => (
  <div
    className={styles.chip}
    style={{
      backgroundColor: name ? bgColor : "",
    }}
    onClick={onClick}
  >
    {name}
  </div>
);

export default Chip;
