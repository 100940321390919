const STATUS_VEHICLE = {
	validation_pending: 0,
	available: 1,
	reserved: 2,
	delivery_pending: 3,
	sold: 4,
	retired: 5
};

export default STATUS_VEHICLE;
