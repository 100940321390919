import React, { useState, useEffect, useContext, useCallback } from "react";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { UserContext } from "@contexts/UserContext";

const ModalPossession = ({
  setModal,
  status,
  lead_id,
  client_id,
  client_phone
}) => {
  const [agent, setAgent] = useState("");
  const [agentId, setAgentId] = useState(null);
  const [clientStatus, setClientStatus] = useState("");
  const { user, name } = useContext(UserContext);

  const updateData = async () => {
    try{
      const possChange = await post(API.SAVE_CHANGE_POSSESSION, {
        lead_id, phone: client_phone, user, userName: `${name.nombre} ${name.apellido1} ${name.apellido2}`, agent: agentId
      });
      if(possChange && !possChange.error){
        return window.location.reload();
      }
    } catch (e) {
      console.log(e)
    }
  }

  const checkProperty = useCallback(async () => {
    try{
      const possStatus = await post(API.CHECK_LEAD_POSSESSION, {
        client_id
      });
      if(possStatus && possStatus[0]){
        setAgent(possStatus[0].nombre_agente);
        setAgentId(possStatus[0].id_agente);
        setClientStatus(possStatus[0].nombre_estado);
      }
    } catch (e) {
      console.log(e)
    }
  }, [client_id])

  useEffect(() => {
    if(status){
      checkProperty();
    }
  }, [status, checkProperty]);
  
  return (
    <Modal
    className="modal-lg"
    centered
    isOpen={status}
    toggle={() => setModal()}
  >
    <ModalHeader>Cambio de agente</ModalHeader>
    <ModalBody className="p-4 text-center">
      {
        agent ? (
          <>          
          <p>Antes de continuar debes confirmar que has leído el aviso.<br/>El cliente ya existe, y está asociado a:</p>
          <h4>{agent}</h4>
          <p>{clientStatus}</p>
          <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => updateData()}>Cambiar Posesión</button>
          </>
        ) : "Espere..."
      }
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => setModal()}>Cerrar</button>
    </ModalFooter>
  </Modal>
  );
};

export default ModalPossession;