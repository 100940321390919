import { useState } from "react";
import { useWatch } from "react-hook-form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AiOutlineEye } from "react-icons/ai";

import styles from "./index.module.scss";
import useIsOverflowed from "@hooks/useIsOverflowed";

const OverflowedInput = ({ control, register }) => {
	const { ref, ...rest } = register;
	const [showEye, setShowEye] = useState(true);
	const [showCommentModal, setShowCommentModal] = useState(false);

	const text = useWatch({
		control,
		name: rest.name
	});
	const { containerRef, isOverflowed } = useIsOverflowed(text);

	return (
		<div className={styles.container}>
			<input
				type="text"
				className={styles.comment}
				{...rest}
				maxLength={70}
				onFocus={() => setShowEye(false)}
				onBlur={(e) => {
					rest.onBlur(e);
					setShowEye(true);
				}}
				ref={(e) => {
					ref(e);
					containerRef.current = e;
				}}
			/>
			{showEye && isOverflowed && (
				<AiOutlineEye
					size={22}
					className={styles.icon}
					onClick={() => setShowCommentModal(true)}
				/>
			)}

			{showCommentModal && (
				<Modal
					centered
					className="modal-xl"
					isOpen={showCommentModal}
					toggle={() => setShowCommentModal(false)}
				>
					<ModalHeader toggle={() => setShowCommentModal(false)}>
						<span>Comentario</span>
					</ModalHeader>
					<ModalBody>
						<span>{text}</span>
					</ModalBody>
					<ModalFooter>
						<button
							onClick={() => setShowCommentModal(false)}
							className="btn btn-flexicar-orange"
						>
							Aceptar
						</button>
					</ModalFooter>
				</Modal>
			)}
		</div>
	);
};

export default OverflowedInput;
