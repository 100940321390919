import { appendErrors } from "react-hook-form";
import { toNestError, validateFieldsNatively } from "@hookform/resolvers";

/**
 * Parses a Yup validation error and formats it into a record of field errors.
 *
 * @param {import("yup").ValidationError} error - The Yup validation error.
 * @param {boolean} validateAllFieldCriteria - Flag to indicate if all field criteria should be validated.
 * @returns {Record<string, FieldError>} A record of field errors keyed by field path.
 */
const parseErrorSchema = (error, validateAllFieldCriteria) =>
	(error.inner || []).reduce((previous, error) => {
		if (!previous[error.path]) {
			previous[error.path] = { message: error.message, type: error.type };
		}

		if (validateAllFieldCriteria) {
			const types = previous[error.path].types;
			const messages = types && types[error.type];

			previous[error.path] = appendErrors(
				error.path,
				validateAllFieldCriteria,
				previous,
				error.type,
				messages ? [].concat(messages, error.message) : error.message
			);
		}

		return previous;
	}, {});

/**
 * @typedef ResolverOptions Options for the resolver function.
 * @property {"async"|"sync"} [resolverOptions.mode='async'] - The mode for validation, either 'async' or 'sync'.
 * @property {boolean} [resolverOptions.raw=false] - Flag indicating whether to return raw input values instead of parsed values.
 *
 */

/**
 * Creates a resolver function for react-hook-form using Yup schema for validation.
 *
 * @param {import("yup").ObjectSchema<Record<string, any>>} schema - The Yup schema to use for validation.
 * @param {import("yup").ValidateOptions<import("yup").AnyObject>} [schemaOptions] - Options to pass to the Yup schema's validate function.
 * @param {ResolverOptions} resolverOptions Options for the resolver function.
 * @returns  A resolver function compatible with react-hook-form.
 */
export function customYupResolver(
	schema,
	schemaOptions = {},
	resolverOptions = { mode: "async", raw: false }
) {
	return async (values, context, options) => {
		try {
			if (schemaOptions.context && process.env.NODE_ENV === "development") {
				console.warn(
					"You should not used the yup options context. Please, use the 'useForm' context object instead"
				);
			}

			// Agregamos al context de yup los values del form para que se puedan usar selectores de hijos a padres en los .when()
			const result = await schema[resolverOptions.mode === "sync" ? "validateSync" : "validate"](
				values,
				Object.assign({ abortEarly: false }, schemaOptions, { context: values })
			);

			options.shouldUseNativeValidation && validateFieldsNatively({}, options);

			return {
				values: resolverOptions.raw ? values : result,
				errors: {}
			};
		} catch (e) {
			if (!e.inner) {
				throw e;
			}

			const errors = toNestError(
				parseErrorSchema(e, !options.shouldUseNativeValidation && options.criteriaMode === "all"),
				options
			);

			return {
				values: {},
				errors
			};
		}
	};
}
