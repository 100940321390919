import React, { useEffect, useRef, useState } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useTranslation } from "react-i18next";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import { clientSchema } from "@schema/ModalClient/clientSchema";
import FormSelect from "@components/Form/Select/FormSelect";
import { getProvinces } from "@utils/getProvinces";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { errorToast } from "@helpers/toastFunctions";

const CreateClient = ({ isOpen, setIsOpen, changeModeAddLead, phoneState, handleClose }) => {
	const { i18n, t } = useTranslation("modalClient");
	const formRef = useRef(null);
	const [provinceOptions, setProvinceOptions] = useState([]);

	useEffect(() => {
		getProvinces(setProvinceOptions, i18n.t("errors.province"));
	}, [i18n]);
	useEffect(() => {
		formRef.current?.setValue("primaryPhone", phoneState);
	}, [phoneState]);

	const handleCreateClient = async (data) => {
		const { name, firstSurname, secondSurname, email, primaryPhone, province, municipality } = data;

		const payload = {
			name: name.trim() || null,
			firstSurname: firstSurname.trim() || null,
			secondSurname: secondSurname.trim() || null,
			contact: {
				email: email.trim() || null,
				primaryPhone: primaryPhone
			},
			address: {
				province: {
					id: province?.value ? province.value : null,
					name: province?.label ? province.label : null
				},
				municipality: municipality.trim() || null
			}
		};
		try {
			const response = await post(API.CREATE_CLIENT, payload);
			if (response.resourceId) {
				changeModeAddLead(response.resourceId);
				setIsOpen(false);
			}
		} catch (error) {
			if (error.message.includes(409)) {
				setIsOpen(false);
				errorToast(t("errors.conflictClient"));
				return;
			}
			errorToast(t("errors.createClient"));
		}
	};

	return (
		<>
			<Modal
				className="modal-lg"
				centered
				isOpen={isOpen}
				onClose={handleClose}
			>
				<ModalHeader>{t("createClient")}</ModalHeader>
				<ModalBody className="p-8">
					<Form
						ref={formRef}
						onSubmit={handleCreateClient}
						schema={clientSchema[i18n.language]}
						defaultValues={{ primaryPhone: phoneState }}
					>
						<div className="row p-4">
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="name"
									label={t("name")}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="firstSurname"
									label={t("firstSurname")}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="secondSurname"
									label={t("secondSurname")}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="primaryPhone"
									label={t("telephone")}
									disabled={phoneState ? true : false}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="email"
									label={t("email")}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormSelect
									name="province"
									placeholder={t("select")}
									label={t("province")}
									options={provinceOptions}
								/>
							</div>
							<div className="col-md-6 col-sm-12">
								<FormInput
									name="municipality"
									label={t("municipality")}
								/>
							</div>
						</div>
					</Form>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-flexicar-inverse-size-s btn-flexicar-mobile"
						onClick={handleClose}
					>
						{t("close")}
					</button>

					<button
						type="submit"
						onClick={() => formRef.current?.triggerSubmit()}
						className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
					>
						{t("save")}
					</button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default CreateClient;
