import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import styles from "./index.module.scss";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
const EquipmentJato = ({
  parentValues,
  vehiculo,
  dontShowColapse,
  equipment,
  setEquipment,
}) => {
  const [filterValues, setFilterValues] = useState([]);

  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (parentValues && parentValues !== filterValues) {
      setFilterValues(parentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  useEffect(() => {
    if (vehiculo.version.length === 0) {
      setEquipment([]);
    }
  }, [vehiculo.version, setEquipment]);

  return (
    <>
      {equipment !== null ? (
        <>
          {!dontShowColapse && (
            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                cursor: "pointer",
              }}
              onClick={toggle}
            >
              <span className="tittle ml-4">
                Datos Extras para la Tasación Vehículo
              </span>{" "}
              {isOpen ? (
                <BiChevronUp className={styles.arrow} />
              ) : (
                <BiChevronDown className={styles.arrow} />
              )}
            </div>
          )}
          <Collapse isOpen={dontShowColapse ? true : isOpen}>
            <div className="row">
              <>
                {equipment?.map((tipo, idx) => (
                  <div key={`equipment-jato-${idx}`} className="col-md-6 col-sm-12">
                    <React.Fragment>
                      <h5 style={{ fontWeight: "600" }}>{tipo.category}</h5>
                      <hr />
                      {tipo.items.map((elm, index) => (
                        <ul key={`equipment-jato-${idx}-tipo-${index}`}>
                          <li>
                            {" "}
                            <strong>{`${elm}`}</strong>
                          </li>
                        </ul>
                      ))}
                    </React.Fragment>
                  </div>
                ))}
              </>
            </div>
          </Collapse>{" "}
        </>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default EquipmentJato;
