import React from "react";
import { Modal, ModalHeader, ModalFooter, Button, FormGroup } from "reactstrap";
import styles from "./index.module.scss";
import Select from "@components/Select";
import { useTranslation } from 'react-i18next';

const ModalRestoreVehicle = ({ vehiculo, setVehicle, restoreVehicle, open, toggle }) => {

	const { t } = useTranslation('modalRestoreVehicle');

	return (
		<>
			<Modal
				isOpen={open}
				toggle={toggle}
			>
				<ModalHeader
					className={styles.modalheader}
					toggle={toggle}
				>
					<span> {t("restoreVehicleAction")} </span>
					<br />
					<div className={styles.text}>
						<span> {t("restoreCause")} </span>
					</div>
				</ModalHeader>
				<FormGroup style={{ padding: "20px" }}>
					<Select
						name="comment_status"
						id="exampleSelect"
						options={[
							{ value: "Eliminado por error", label: t("deletedError") },
							{ value: "Recompra", label: t("rebuy") },
							{ value: "Devolución de vehículo", label: t("vehicleReturn") }
						]}
						placeholder={t("selectReason")}
						value={vehiculo?.comment_status}
						onChange={(newValue) => {
							setVehicle((prev) => ({ ...prev, comment_status: newValue }));
						}}
					/>
				</FormGroup>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => restoreVehicle()}
					>
						{t("restore")}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ModalRestoreVehicle;
