import RolType from "@constants/roles";

const validationToCreateContract = (prices, rol)  => {
   return (prices.precio_preliminar_cd ||
        prices.precio_ofrecido_cd ||
        prices.precio_preliminar_gdv ||
        prices.precio_ofrecido_gdv) || 
        rol?.some(
          (e) =>
            e === RolType.GENERAL_ADMIN ||
            e === RolType.ADMINISTRATION)
};

export default validationToCreateContract;