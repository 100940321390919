import * as Yup from "yup";

const preparationSchema = Yup.object({
	title: Yup.string().min(1, "Título es requerido").max(35, "Titulo, máximo 35 caracteres"),
	comment: Yup.string().max(150, "Comentario, máximo 150 caracteres").optional(),
	amount: Yup.number()
		.min(0, "Coste, minimo 0 €")
		.max(99999.99, "Coste, máximo 99.999,99 €")
		.optional()
});

export default preparationSchema;
