import React, { useState, useEffect, useContext, useCallback, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Table } from "react-bootstrap";
import moment from "moment";
import { Container, Row, Col, Spinner, Button } from "reactstrap";
import { FaFilter, FaEye, FaRegCheckCircle } from "react-icons/fa";
import {
  MdEdit,
  MdDirectionsCarFilled,
  MdDeleteForever,
  MdErrorOutline,
  MdRefresh,
  MdSchedule,
  MdCarRepair
} from "react-icons/md";
import { BsPaperclip } from "react-icons/bs";
import { IoMdDocument } from "react-icons/io";

import styles from "./index.module.scss";
import {
  DOCUMENTATION_ACTIONS,
  documentationFiltersInitialState,
  documentationFiltersReducer
} from "./actions/documentationReducer";

import { UserContext } from "@contexts/UserContext";
import SellContractModal from "@views/LeadVenta/SellContractModal/SellContractModal";
import SellContractModalPT from "@views/LeadVenta/SellContractModalPT/SellContractModalPT";
import ModalTraslado from "@components/ModalUpdateLocalVehicle";
import DocumentationType from "@constants/documentationType";
import ContractId from "@components/ContractId/ContractId";
import MultiSelectChipsList from "@components/MultiSelectChipsList";
import Paginator from "@components/Paginator";
import UploadComponent from "@components/UploadComponent";
import CancelSignComponent from "@components/CancelSignComponent";
import ModalWarning from "@components/ModalWarning";
import ModalCalendar from "@components/ModalCalendar";
import ModalPrintView from "@components/ModalPrintView";
import Checkbox from "@components/Checkbox";
import ModalUpdateContract from "@components/ModalUpdateContract";
import Select from "@components/Select";
import { useDebouncedValue } from "@hooks/useDebouncedValue";
import { post, getSignUrl } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import { createDraft, putLocal } from "@services/Vehicle/vehicle.service";
import { successToast, errorToast } from "@helpers/toastFunctions";
import { formatDateWithoutHour, parseDateToISODate } from "@helpers/formatDate";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";
import Locale from "@constants/Locale";

const PAGE_SIZE = 15;

const PostventaContratos = () => {
  const { user, rol, local, userType } = useContext(UserContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation('myDocumentation');
  const history = useHistory();

  const adminRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_MANAGER, RolType.ADMINISTRATION];
  const docuRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_AGENT, RolType.TRADING_MANAGER, RolType.ADMINISTRATION]; // roles 1 6 7 9
  const vtcRoles = [RolType.PURCHASING_AGENT]; // rol 17
  const warrantyRoles = [RolType.WARRANTY]; // rol 37
  const portugueseAccessProtection = i18n.language === Locale.ES;

  const [filterState, filterDispatch] = useReducer(documentationFiltersReducer, documentationFiltersInitialState);
  const freeFilterDebounce = useDebouncedValue(filterState.freeFilter, 500);

  const [cancelSign, setCancelSign] = useState({
    open: false,
    document: null,
  });
  const [uploadDocument, setUploadDocument] = useState({
    open: false,
    document: null,
    type: "",
  });
  const [dropdownIdx, setDropdownIdx] = useState(-1);
  const [warningAlert, setWarningAlert] = useState(false);
  const [warningAlertDocument, setWarningAlertDocument] = useState(false);

  const [localFilterOptions, setLocalFilterOptions] = useState(null);

  const [documentsData, setDocumentsData] = useState({ count: 0, data: null });

  const [elementToDelete, setElementToDelete] = useState(null);
  const [toggleDocuments, setToggleDocuments] = useState(null);
  const [modalCalendar, setModalCalendar] = useState(null);
  const [modalPrintView, setModalPrintView] = useState();

  const [filterList, setFilterList] = useState([]);

  const [modalUpdateContract, setModalUpdateContract] = useState();
  const [changeLocal, setChangeLocal] = useState();
  const [disabledButton, setDisabledButton] = useState(false);

  const changeDocumentState = useCallback(async () => {
    if (!filterState.isResetting)
      try {
        setDocumentsData({ count: 0, data: null });

        const dataCall = {
          min: (filterState.page - 1) * PAGE_SIZE,
          limit: PAGE_SIZE,
          getLocales: local,
          checkFilters: filterState.selectedCheckbox,
          freeFilter: freeFilterDebounce,
          dateStart: filterState.startDate
            ? moment(filterState.startDate).format("YYYY-MM-DD")
            : undefined,
          dateEnd: filterState.endDate
            ? moment(filterState.endDate).format("YYYY-MM-DD")
            : undefined,
          onlyForLocal:
            filterState.dealershipSelect?.length > 0
              ? filterState.dealershipSelect.map((l) => l.value)
              : false,
          user
        };

        if (filterState.documentTypeSelect.length > 0) {
          dataCall.documentos = filterState.documentTypeSelect.map((x) => x.value);
        }

        const documents = await post(API.GET_DOCUMENTACION_FIRMAS_BY_LOCALES, dataCall);

        setDocumentsData(documents);
      } catch (e) {
        errorCall(e);
      }
    else if (filterState.isResetting && freeFilterDebounce === "") {
      filterDispatch({ type: DOCUMENTATION_ACTIONS.COMPLETE_RESET });
    }
  }, [
    filterState.isResetting,
    filterState.documentTypeSelect,
    filterState.dealershipSelect,
    filterState.startDate,
    filterState.endDate,
    filterState.selectedCheckbox,
    freeFilterDebounce,
    filterState.page,
    local,
    user
  ]);

  const deleteStart = (id, type, id_documento) => {
    setElementToDelete({ id, type, id_documento });
    setWarningAlert(true);
  };

  const deleteStartDocument = (id_documento) => {
    setElementToDelete({ id_documento });
    setWarningAlertDocument(true);
  };

  const redirectURL = (url) => {
    window.open(url);
  };

  const goSignedUrl = async (id) => {
    const response = await getSignUrl(id);
    window.open(response.data.url);
  };

  const handleToggleDocument = (idx) => {
    toggleDocuments === idx
      ? setToggleDocuments(null)
      : setToggleDocuments(idx);
  };

  const cancelDelete = () => {
    setElementToDelete(null);
    setWarningAlert(false);
    setWarningAlertDocument(false);
  };

  const deleteEnd = async () => {
    try {
      const response = await post(API.DELETE_FILES, elementToDelete);

      if (!response.error) {
        await changeDocumentState();
        cancelDelete();
        successToast("Elemento borrado con exito");
      } else {
        errorToast(<span>{t("errors.errorDeletingElement")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const deleteDocument = async () => {
    try {
      const response = await post(API.DELETE_DOCUMENT, elementToDelete);
      if (!response.error) {
        await changeDocumentState();
        cancelDelete();
        successToast("Elemento borrado con exito");
      } else {
        errorToast(<span>{t("errors.errorDeletingElement")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const redirectLead = (id_lead, id_cliente, tipo) => {
    if (tipo === DocumentationType.CONTRATO_VENTA || tipo === DocumentationType.RESERVA) {
      history.push(`/main/lead-venta/${id_lead}/${id_cliente}`);
    }

    if (tipo === DocumentationType.COMPRA_DIRECTA || tipo === DocumentationType.GESTION_VENTA) {
      history.push(`/main/lead-tasacion/${id_lead}`);
    }
  };

  const changeDropdownIdx = (idx) => {
    handleToggleDocument(idx);
    let selected = -1;
    if (idx !== dropdownIdx) selected = idx;
    setDropdownIdx(selected);
  };

  const createVehicleDraft = async ({ id, dealer }) => {

    try {
      const response = await createDraft(id);
      if (response.type === 2) {
        errorToast(<span>{t("errors.errorVehiclePublished")}</span>);
      } else if (response.id && !response.new && response.type === 1) {
        if (!vtcRoles.some((r) => rol.includes(r))) {
          history.push(`/main/validacion-vehiculo/${response.id}`);
        } else {
          errorToast(<span>{t("errors.errorVehicleValidation")}</span>);
        }
      } else if (response.id && response.new) {
        if (!vtcRoles.some((r) => rol.includes(r))) {
          successToast("Vehículo en validación creado");
        } else {
          await changeDealer({
            id: response.id,
            type: "draft",
            idDealer: dealer[0].value,
          });
          successToast("Vehículo en validación creado");
        }
      }
    } catch (e) {
      const codeError = e?.response?.data?.code;

      if (codeError === "vehicle.exists.chassisNumber") {
        errorToast(<span>{t("errors.errorVehicleInValidationExists")}</span>);
      } else if (codeError === "vehicle.exists.plate") {
        errorToast(<span>{t("errors.errorVehicleInValidationExistsPlate")}</span>);
      } else {
        errorToast(<span>{t("errors.errorCreatingVehicle")}</span>);
      }
    } finally {
      setDisabledButton(false)
    }
  };

  const changeDealer = async ({ id, type, idDealer }) => {
    try {
      await putLocal({ id, type: type, idDealer: idDealer });
      await successToast(`Vehículo trasladado `);
      await setChangeLocal({ ...changeLocal, open: false });
    } catch (e) {
      errorToast(<span>{t("errors.errorVehicleTransfer")}</span>);
    }
  };

  const findStatus = (document) => {

    let today = moment().format("DD-MM-YYYY")
    let fecha_entrega = moment(document.fecha_entrega).format("DD-MM-YYYY")
    let delivery_status
    let button_style
    let disabledButton = false

    switch (document.status) {

      case 3:
        if (today >= fecha_entrega) {
          delivery_status = 'PDTE.CONFIRMAR'
          disabledButton = false
          button_style = `${styles.grey} ${styles.btnFlexicarMini}`
        }
        if (today < fecha_entrega) {
          delivery_status = 'PDTE.ENTREGA'
          disabledButton = false
          button_style = `${styles.yellow} ${styles.btnFlexicarMini}`
        }
        break;
      case 4:
        delivery_status = 'ENTREGADO'
        disabledButton = true
        button_style = `${styles.green} ${styles.btnFlexicarMini} ${styles.disabledBtn}`
        break;
      case 5:
        delivery_status = 'RETIRADO'
        disabledButton = false
        button_style = `${styles.grey} ${styles.btnFlexicarMini}`
        break;
      default:
        delivery_status = 'ENTREGAR'
        disabledButton = false
        button_style = `${styles.red} ${styles.btnFlexicarMini}`
    }

    return <button
      className={
        document.fecha_entrega
          ? button_style
          : `${styles.red} ${styles.btnFlexicarMini} `
      }
      disabled={disabledButton}
      onClick={() =>
        setModalCalendar({
          type: "delivery",
          document,
        })
      }
    >

      {document.fecha_entrega

        ? delivery_status
        : "ENTREGAR"
      }

    </button>
  }

  useEffect(() => {
    document.title = "Flexicar | Contratos";
  }, []);

  useEffect(() => {
    post(`/api/get_user_local`, { user })
      .then((res) => setLocalFilterOptions(res))
      .catch((err) => errorToast(err.response.data.message));
  }, [user]);

  useEffect(() => {
    if (local) {
      changeDocumentState();
    }
  }, [local, changeDocumentState]);

  useEffect(() => {
    const _filterList = [filterState.documentTypeSelect, filterState.dealershipSelect].filter(
      (x) => x
    );

    const filterListFlat = _filterList.flatMap((list) => list.map((item) => item.label.toString()));

    const filterDateList = [filterState.startDate, filterState.endDate]
      .filter((x) => x)
      .map((x) => formatDateWithoutHour(x));

    setFilterList([...filterListFlat, ...filterDateList]);
  }, [
    filterState.documentTypeSelect,
    filterState.dealershipSelect,
    filterState.startDate,
    filterState.endDate
  ]);

  const handleDeletedDropdownSelected = (element) => {
    const updateFilter = (filter, element) => {
      return filter.filter((item) => item.label && item.label.toString() !== element);
    };

    const updatedLocales = updateFilter(filterState.dealershipSelect, element);
    const updatedTypes = updateFilter(filterState.documentTypeSelect, element);

    filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DEALERSHIP_SELECT, payload: updatedLocales });
    filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DOCUMENT_TYPE_SELECT, payload: updatedTypes });

    if (parseDateToISODate(element) === filterState.startDate) {
      filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_START_DATE, payload: "" });
    }

    if (parseDateToISODate(element) === filterState.endDate) {
      filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_END_DATE, payload: "" });
    }
  };


  return (
    <div>
      <Container fluid={true}>
        <Row className="mt-4">
          <Col className={`col-md-2 col-xs-12`}>
            <div className={`pt-3 pb-3 ${styles.filtersContent}`}>
              <FaFilter size={20} color="#007bff" /> {t('filters')}
              <Select
                className="my-2"
                isMulti
                placeholder={t('document')}
                value={filterState.documentTypeSelect}
                options={[
                  { label: t('Contrato de Venta'), value: 1 },
                  { label: t('Reserva'), value: 2 },
                  { label: t('Compra Directa'), value: 3 },
                  { label: t('Gestión de Venta'), value: 4 },
                ]}
                onChange={(values) => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DOCUMENT_TYPE_SELECT, payload: values })}
              />
              {localFilterOptions ? (
                <Select
                  isMulti
                  placeholder={t('locals')}
                  value={filterState.dealershipSelect}
                  options={localFilterOptions}
                  onChange={(values) => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DEALERSHIP_SELECT, payload: values })}
                />
              ) : null}
              <br />
              <div className="mb-3">
                <label>{t("from") + ":"}</label>
                <Form.Control
                  type="date"
                  name="dateStart"
                  value={filterState.startDate}
                  onChange={(e) => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_START_DATE, payload: e.target.value })}
                  className="mb-1"
                />
                <label>{t("to") + ":"}</label>
                <Form.Control
                  type="date"
                  name="dateEnd"
                  value={filterState.endDate}
                  onChange={(e) => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_END_DATE, payload: e.target.value })}
                />
              </div>
              <Checkbox
                name={t('pendingTasks')}
                status={filterState.selectedCheckbox.notAllOk}
                onChange={() => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX, payload: "notAllOk" })}
              />
              <Checkbox
                name={t('okContract')}
                status={filterState.selectedCheckbox.contract_ok}
                onChange={() => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX, payload: "contract_ok" })}
              />
              <Checkbox
                name={t('okBilling')}
                status={filterState.selectedCheckbox.bill_date_ok}
                onChange={() => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX, payload: "bill_date_ok" })}
              />
              <Checkbox
                name={t('okSent')}
                status={filterState.selectedCheckbox.delivery_date_ok}
                onChange={() => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX, payload: "delivery_date_ok" })}
              />
              <br />
              <Button outline color="danger" block onClick={() => filterDispatch({ type: DOCUMENTATION_ACTIONS.RESET_FILTERS })}>
                {t('delete')}
              </Button>
            </div>
          </Col>
          <Col className="col-md-10 col-xs-6">
            <div className={`clearfix mb-3 ${styles.container2}`}>
              <span className="tittle ml-4">{t('myDocument')}</span>
            </div>
            <div className="d-flex flex-wrap mb-1 mt-1">
              <MultiSelectChipsList
                dropdownSelected={filterList}
                deleteDropdownSelected={handleDeletedDropdownSelected}
              />
            </div>
            <Row>
              <Col>
                <input
                  autoComplete="off"
                  placeholder={t("search") + "..."}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={filterState.freeFilter}
                  onChange={e => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_FREE_FILTER, payload: e.target.value })}
                />
              </Col>
              <Col>
                <div className="text-right mt-3">
                  <span className="text-success">{t('documents') + ":"} </span>
                  <span className="text-primary">{documentsData.count}</span>
                </div>
              </Col>
            </Row>
            <div className={styles.margin10}>
              {documentsData.data?.length > 0 ? (
                <div className={styles.smallFont}>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t('type')}</th>
                        <th>{t('date')}</th>
                        <th>{t('client')}</th>
                        <th>{t('vehicle')}</th>
                        <th>{t('plate')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    {documentsData.data.map((document, idx) => {
                      const datos = document.datos;
                      return (
                        <tbody key={`documentsInfo-${document.documentId}-${idx}`}>
                          <tr
                            key={"vehicle" + idx}
                            style={
                              idx % 2
                                ? {
                                  background: "#ffffff",
                                }
                                : {
                                  background: "rgba(242,242,242,0.5)",
                                }
                            }
                          >
                            <td
                              onClick={() => changeDropdownIdx(idx)}
                              className={document.files ? "pointer" : null}
                            >
                              {(document.files ||
                                document.url ||
                                document.documentNameCloud) &&
                                (dropdownIdx === idx ? (
                                  <div
                                    key="right"
                                    className={
                                      styles.marginPointer + " text-primary"
                                    }
                                  >
                                    ▼
                                  </div>
                                ) : (
                                  <div
                                    key="right"
                                    className={
                                      styles.marginPointer + " text-primary"
                                    }
                                  >
                                    ►
                                  </div>
                                ))}
                            </td>
                            <td>
                              <div className={styles.row}>
                                {document.url || document.documentNameCloud ? (
                                  <div
                                    title={t('contractUpdoaded')}
                                    className={styles.okIcon}
                                  >
                                    <FaRegCheckCircle size={23} />
                                  </div>
                                ) : document.sign_start_date ? (
                                  <div className={styles.iconsContainer}>
                                    <div
                                      title={t('refresh')}
                                      onClick={changeDocumentState}
                                      className={styles.refreshIcon}
                                    >
                                      <MdRefresh size={25} />
                                    </div>
                                    <div
                                      title={t('contractSend')}
                                      onClick={() =>
                                        setCancelSign({
                                          open: true,
                                          document,
                                        })
                                      }
                                      className={styles.pendingIcon}
                                    >
                                      <MdSchedule size={25} />
                                    </div>{" "}
                                  </div>
                                ) : (
                                  <div
                                    title={t('attachContract')}
                                    onClick={() =>
                                      setUploadDocument({
                                        open: true,
                                        document,
                                        type: "contracts",
                                        type_contract: document.tipo,
                                        documentId: document.documentId,
                                      })
                                    }
                                    className={styles.warningIcon}
                                  >
                                    <MdErrorOutline size={25} />
                                  </div>
                                )}
                                <span className={`ml-2 ${styles.noDecoration}`}>
                                  {t(`${document.tipoNombre}`)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {document.fecha_contrato
                                  ? moment(document.fecha_contrato).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "Fecha no disponible"}
                              </span>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {`${datos?.nombre} ${datos?.apellido1 ? datos.apellido1 : ""
                                  } ${datos?.apellido2 ? datos.apellido2 : ""}`}
                              </span>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {document.tipo === DocumentationType.CONTRATO_VENTA ||
                                  document.tipo === DocumentationType.COMPRA_DIRECTA ||
                                  document.tipo === DocumentationType.GESTION_VENTA
                                  ? `${datos?.marca ? datos?.marca : ""}  ${datos?.modelo ? datos?.modelo : ""
                                  }`
                                  : `${datos?.vehiculo ? datos?.vehiculo : ""}`}
                              </span>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {datos?.matricula
                                  ? datos?.matricula
                                  : "No disponible"}
                              </span>
                            </td>

                            <td className={`${styles.centerMargin} border-0`}>
                              <span className={`${styles.iconBox}`}>
                                {!(
                                  document.url || document.documentNameCloud
                                ) && (
                                    <FaEye
                                      title={t('see')}
                                      size={26}
                                      color={"#007bff"}
                                      role="button"
                                      onClick={() =>
                                        setModalPrintView({
                                          open: true,
                                          contractId: document.documentId,
                                          contractSignStartDate:
                                            document.sign_start_date,
                                          title: document.tipoNombre,
                                          tipo: document.tipo,
                                        })
                                      }
                                    />
                                  )}
                              </span>

                              <span className={styles.iconBox}>
                                {!(
                                  document.url ||
                                  document.documentNameCloud ||
                                  document.sign_start_date)
                                  && !warrantyRoles.some((r) => rol.includes(r)) && (
                                    <MdEdit
                                      title={t('modify')}
                                      size={26}
                                      color={"#007bff"}
                                      role="button"
                                      onClick={() =>
                                        setModalUpdateContract({
                                          tipo: document.tipo,
                                          open: true,
                                          document,
                                        })
                                      }
                                    />
                                  )}
                              </span>

                              {!warrantyRoles.some((r) => rol.includes(r)) &&
                                <span className={styles.bigIconBox}>
                                  <div
                                    onClick={() =>
                                      setUploadDocument({
                                        open: true,
                                        type: "documents",
                                        document,
                                      })
                                    }
                                    className={styles.marginPointerBox}
                                  >
                                    <BsPaperclip
                                      title={t('attach')}
                                      size={26}
                                      color={"#007bff"}
                                    />

                                    <div
                                      style={{
                                        width: "22px",
                                        textAlign: "center",
                                      }}
                                      className="btn-flexicar-blue-mini"
                                      onClick={() =>
                                        setUploadDocument({
                                          open: true,
                                          type: "documents",
                                          document,
                                        })
                                      }
                                    >
                                      {document.url || document.documentNameCloud
                                        ? document?.files?.length
                                          ? document?.files?.length + 1
                                          : 1
                                        : document?.files?.length}
                                    </div>
                                  </div>
                                </span>
                              }


                              <span className={styles.buttonBox}>
                                {document.tipoNombre === "Contrato de Venta" &&
                                  adminRoles.some((r) => rol.includes(r)) && (
                                    <button
                                      className={
                                        document.fecha_facturacion
                                          ? `${styles.green} ${styles.btnFlexicarMini}`
                                          : `${styles.red} ${styles.btnFlexicarMini}`
                                      }
                                      onClick={() =>
                                        setModalCalendar({
                                          type: "bill",
                                          document,
                                          matricula:
                                            datos?.matricula,
                                        })
                                      }
                                    >
                                      {document.fecha_facturacion
                                        ? "FACTURADO"
                                        : "FACTURAR"}
                                    </button>
                                  )}
                              </span>
                              <span className={styles.buttonBox}>
                                {document.tipoNombre === "Contrato de Venta" && !document.vehicle_id_stock &&
                                  docuRoles.some((r) => rol.includes(r)) && (
                                    <button
                                      className={
                                        document.fecha_entrega
                                          ? `${styles.green} ${styles.btnFlexicarMini}`
                                          : `${styles.red} ${styles.btnFlexicarMini}`
                                      }
                                      onClick={() =>
                                        setModalCalendar({
                                          type: "delivery",
                                          document,
                                        })
                                      }
                                    >
                                      {document.fecha_entrega
                                        ? "ENTREGADO"
                                        : "ENTREGAR"}

                                    </button>
                                  )}
                                {document.tipoNombre === "Contrato de Venta" && document.vehicle_id_stock &&
                                  docuRoles.some((r) => rol.includes(r)) && (
                                    findStatus(document)

                                  )}
                              </span>
                              <span className={styles.iconBox}>
                                {(document.tipo === DocumentationType.CONTRATO_VENTA ||
                                  document.tipo === DocumentationType.RESERVA) && (
                                    <div
                                      onClick={() => {
                                        history.push(
                                          `/main/vehicle/${document.id_vehiculo}?tab=datos`
                                        );
                                      }}
                                      className={styles.marginPointer}
                                    >
                                      <MdDirectionsCarFilled
                                        title="Ir al vehículo"
                                        size={26}
                                        color={"#007bff"}
                                      />
                                    </div>
                                  )}
                                {(document.tipo === DocumentationType.COMPRA_DIRECTA || document.tipo === DocumentationType.GESTION_VENTA) && (document.url || document.documentNameCloud) && !vtcRoles.some((r) => rol.includes(r)) ? (
                                  <button
                                    disabled={disabledButton}
                                    onClick={async () => {
                                      setDisabledButton(true);
                                      await createVehicleDraft({ id: document.documentId });
                                    }}
                                    className={styles.marginPointer}
                                  >
                                    <MdCarRepair
                                      title="Crear validación VO"
                                      color={"#007bff"}
                                      size={28}
                                    />
                                  </button>
                                ) : null}
                                {(document.tipo === DocumentationType.COMPRA_DIRECTA || document.tipo === DocumentationType.GESTION_VENTA) && (document.url || document.documentNameCloud) && vtcRoles.some((r) => rol.includes(r)) ? (
                                  <button
                                    disabled={!portugueseAccessProtection}
                                    onClick={() => setChangeLocal({ open: true, id_document: document.documentId })
                                    }
                                    className={styles.marginPointer}
                                  >
                                    <MdCarRepair
                                      title="Crear validación VO"
                                      color={portugueseAccessProtection ? "#007bff" : "#ccc"}
                                      size={28}

                                    />
                                  </button>
                                ) : null}
                              </span>
                              <span className={styles.iconBox}>
                                {(document.tipo === DocumentationType.CONTRATO_VENTA ||
                                  document.tipo === DocumentationType.RESERVA) && (
                                    <div
                                      onClick={() => {
                                        redirectLead(
                                          document.id_lead,
                                          document.id_cliente,
                                          document.tipo
                                        );
                                      }}
                                      className={styles.marginPointer}
                                    >
                                      <IoMdDocument
                                        title="Ir al lead"
                                        size={26}
                                        color={"#007bff"}
                                      />
                                    </div>
                                  )}
                                {(document.tipo === DocumentationType.COMPRA_DIRECTA || document.tipo === DocumentationType.GESTION_VENTA) &&
                                  document.id_lead != null && (
                                    <div
                                      onClick={() => {
                                        redirectLead(
                                          document.id_lead,
                                          document.id_cliente,
                                          document.tipo
                                        );
                                      }}
                                      className={styles.marginPointer}
                                    >
                                      <IoMdDocument
                                        title="Ir al lead"
                                        size={26}
                                        color={"#007bff"}
                                      />
                                    </div>
                                  )}
                              </span>
                              <span className={styles.iconBox}>
                                {(rol.includes(RolType.GENERAL_ADMIN) || rol.includes(RolType.ADMINISTRATION) ||
                                  rol.includes(
                                    RolType.TRADING_MANAGER
                                  ) || rol.includes(
                                    RolType.TRADING_MANAGER_PILOT
                                  )) && (
                                    <div
                                      onClick={() =>
                                        deleteStartDocument(document.documentId)
                                      }
                                      className={styles.marginPointer}
                                    >
                                      <MdDeleteForever
                                        title={t('deleteDocument')}
                                        size={26}
                                        color="red"
                                      />
                                    </div>
                                  )}
                              </span>
                            </td>
                          </tr>

                          {toggleDocuments === idx &&
                            (document.url || document.documentNameCloud) && (
                              <tr
                                key={document.fecha}
                                style={{
                                  background: "#a5e2ff",
                                }}
                              >
                                <td colSpan="12">
                                  <div className={styles.wrapRowSpace}>
                                    <div className={styles.width70rowSpace}>
                                      <span className={styles.noDecoration}>
                                        {`Contrato firmado, subido el ${moment(
                                          document.fecha_url
                                        ).format("DD-MM-YYYY HH:mm")}`}
                                      </span>
                                      <ContractId
                                        contractType={document.tipo}
                                        contractId={document.documentId}
                                      />
                                    </div>

                                    <div className={styles.width20rowSpace}>
                                      {document.documentNameCloud ? (
                                        <div
                                          onClick={() =>
                                            goSignedUrl(
                                              document.documentNameCloud
                                            )
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            redirectURL(document.url)
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      )}
                                      {(document.tipo !== DocumentationType.COMPRA_DIRECTA || (document.tipo === DocumentationType.COMPRA_DIRECTA && IS_SUPER_ADMIN(userType))) && (
                                        <div
                                          onClick={() =>
                                            deleteStart(
                                              document.documentId,
                                              "contracts"
                                            )
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <MdDeleteForever
                                            title={t('deleteDocument')}
                                            size={26}
                                            color="red"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          {toggleDocuments === idx &&
                            document.files &&
                            document.files.map((e, index) => (
                              <tr
                                key={e.fecha}
                                style={
                                  index % 2
                                    ? {
                                      background: "#f1f3f5",
                                    }
                                    : {
                                      background: "#d5dce1",
                                    }
                                }
                              >
                                <td colSpan="12">
                                  <div className={styles.wrapRowSpace}>
                                    <div className={styles.width70rowSpace}>
                                      <span className={styles.noDecoration}>
                                        {`${e.nombre} subido el ${moment(
                                          e.fecha
                                        ).format("DD-MM-YYYY HH:mm")}`}
                                      </span>
                                    </div>

                                    <div className={styles.width20rowSpace}>
                                      {e.sign_url_id ? (
                                        <div
                                          onClick={() =>
                                            goSignedUrl(e.sign_url_id)
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() => redirectURL(e.url)}
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      )}

                                      <div
                                        onClick={() =>
                                          deleteStart(
                                            e.id,
                                            "documents",
                                            e.id_documento
                                          )
                                        }
                                        className={styles.centerPointer}
                                      >
                                        <MdDeleteForever
                                          title={t('deleteDocument')}
                                          size={26}
                                          color="red"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      );
                    })}
                  </Table>

                  <Paginator
                    className={styles.center}
                    pageSize={PAGE_SIZE}
                    totalCount={documentsData.count}
                    currentPage={filterState.page}
                    onPageChange={(page) => filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_PAGE, payload: page })}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center m-5">
                  {documentsData.data === null && (
                    <>
                      <Spinner
                        type="grow"
                        className="flexicarColor mr-5"
                      />
                      <p className="flexicarColor">Cargando documentación...</p>
                    </>
                  )}

                  {documentsData.data?.length === 0 && (
                    <p className="flexicarColor">No se encontraron resultados</p>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <UploadComponent
        setUploadDocument={setUploadDocument}
        uploadDocument={uploadDocument}
        recall={changeDocumentState}
        createVehicleDraft={createVehicleDraft}
        vtcRoles={vtcRoles}
        rol={rol}
      ></UploadComponent>
      <CancelSignComponent
        recall={changeDocumentState}
        setCancelSign={setCancelSign}
        cancelSign={cancelSign}
      ></CancelSignComponent>
      <ModalWarning
        okButton={deleteEnd}
        cancelButton={cancelDelete}
        open={warningAlert}
      ></ModalWarning>
      <ModalWarning
        okButton={deleteDocument}
        cancelButton={cancelDelete}
        open={warningAlertDocument}
      ></ModalWarning>
      <ModalCalendar
        title={
          modalCalendar?.type === "bill"
            ? "Fecha de facturación"
            : "Fecha de entrega"
        }
        open={modalCalendar?.type}
        matricula={modalCalendar?.matricula}
        document={modalCalendar?.document}
        type={modalCalendar?.type}
        close={() => setModalCalendar(null)}
        recallDocuments={changeDocumentState}
        setDocumentsInfo={setDocumentsData}
        documentsInfo={documentsData.data}
        findStatus={findStatus}
      />
      <ModalPrintView
        title={modalPrintView?.title}
        recall={changeDocumentState}
        open={modalPrintView?.open === true}
        close={() => setModalPrintView()}
        contractId={modalPrintView?.contractId}
        contractSignStartDate={modalPrintView?.contractSignStartDate}
        contractType={modalPrintView?.tipo}
      />

      {modalUpdateContract?.open &&
        modalUpdateContract?.document.tipo !== DocumentationType.CONTRATO_VENTA && (
          <ModalUpdateContract
            open={modalUpdateContract?.open === true}
            close={() => setModalUpdateContract()}
            document={modalUpdateContract?.document}
            recall={changeDocumentState}
            user={user}
          />
        )}

      {modalUpdateContract?.open &&
        modalUpdateContract?.document.tipo === DocumentationType.CONTRATO_VENTA &&
        portugueseAccessProtection && (
          <SellContractModal
            isOpen={modalUpdateContract.open}
            setIsOpen={setModalUpdateContract}
            mode="edit"
            document={modalUpdateContract.document}
            recall={changeDocumentState}
            vehicleYear={modalUpdateContract.document.vehicle_year}
            vehicleMonth={modalUpdateContract.document.vehicle_month}
          />
        )}

      {modalUpdateContract?.open &&
        modalUpdateContract?.document.tipo === DocumentationType.CONTRATO_VENTA &&
        !portugueseAccessProtection && (
          <SellContractModalPT
            isOpen={modalUpdateContract.open}
            setIsOpen={setModalUpdateContract}
            mode="edit"
            document={modalUpdateContract.document}
            recall={changeDocumentState}
            vehicleYear={modalUpdateContract.document.vehicle_year}
            vehicleMonth={modalUpdateContract.document.vehicle_month}
          />
        )}

      <ModalTraslado
        changeLocal={changeLocal}
        setChangeLocal={setChangeLocal}
        createVehicleDraft={createVehicleDraft}
      />
    </div>
  );
};

export default PostventaContratos;
