import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const GenericModal = ({
	isOpen,
	setIsOpen,
	onClose,
	headContent,
	bodyContent,
	footerContent,
	centered = true,
	modalClassName = "modal-lg"
}) => {
	const toggle = () => {
		onClose && onClose();
		setIsOpen((open) => !open);
	};

	return (
		<Modal
			className={modalClassName}
			centered={centered}
			isOpen={isOpen}
			toggle={toggle}
		>
			<ModalHeader toggle={toggle}>{headContent}</ModalHeader>
			<ModalBody>{bodyContent}</ModalBody>
			<ModalFooter>{footerContent}</ModalFooter>
		</Modal>
	);
};

export default GenericModal;
