import * as Yup from "yup";

const sellContractClientSchema = Yup.object({
	name: Yup.string().required("Campo obligatorio"),
	firstSurname: Yup.string().required("Campo obligatorio"),
	secondSurname: Yup.string().notRequired(),
	phone: Yup.string().phone(),
	email: Yup.string().required("Campo obligatorio").email(),
	dni: Yup.string().identification(),
	province: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	town: Yup.string().required("Campo obligatorio"),
	address: Yup.string().required("Campo obligatorio"),
	cp: Yup.string().required("Campo obligatorio").postalCode(),
	dateOfBirth: Yup.string().required("Campo obligatorio").isISODateString()
});

export { sellContractClientSchema };
