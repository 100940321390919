import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { errorToast } from "@helpers/toastFunctions";
import { useReactToPrint } from "react-to-print";
import styles from "./index.module.scss";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from 'react-i18next';

import ejsTemplateRender from "@helpers/ejsTemplateRender";
import Select from "@components/Select";
import { getFilterDealerships } from "@services/Filtros/filtros";

const ModalPrintPercha = ({
  open,
  close,
  leadId,
  contractSignStartDate,
}) => {
  const { i18n } = useTranslation('modalPrintPercha');
  const { user } = useContext(UserContext);
  const { local } = useContext(UserContext);
  const [spinner, setSpinner] = useState(true);
  const [localid, setLocalid] = useState(null);
  const update_print = async () => {
    try {
      await post(API.UPDATE_PRINT, {
        leadId,
        local: localid.value,
        user,
      });
    } catch (e) {
      return errorToast(<span>{i18n.t("errors.errorPrint")}</span>);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: update_print,
  });

  const getTemplate = useCallback(async () => {
    const id_tasacion = leadId;

    try {
      const dataTemplate = { id_tasacion };
      await ejsTemplateRender(1, dataTemplate);

      setSpinner(false);
    } catch (e) {
      return errorToast(
        <span>
          {i18n.t("errors.errorMissingData")} {id_tasacion}
        </span>
      );
    }
  }, [leadId, i18n]);

  useEffect(() => {
    if (open) {
      getTemplate();
    }
    else {
      setLocalid(null);
    }
  }, [open, getTemplate]);

  useEffect(() => {
    if(localid)
      document.getElementById("local").innerHTML = localid.label;
  }, [localid]);

  return (
    <Modal className={"modal-xl"} isOpen={open} toggle={close}>
      <ModalHeader>Documento Informativo de Tasación</ModalHeader>
      <ModalBody className="p-4">
        <div>
          {" "}
          {!contractSignStartDate && spinner === false ? (
            <div className={styles.space}>
              <div className="w-50">
                <p>Seleccione local:</p>
                <Select
                  placeholder="Locales"
                  loadOptions={() => getFilterDealerships([local])}
                  value={localid}
                  onChange={(opt) => setLocalid(opt)}
                />
              </div>

              <div>
                {localid ? (
                  <Button
                    className="btn-flexicar mt-2 mr-5 right no-print"
                    onClick={handlePrint}
                  >
                    Imprimir Tasación
                  </Button>
                ) : null}
              </div>
            </div>
          ) : (
            <div>
              <Spinner type="grow" className="flexicarColor left" />
            </div>
          )}
          <br />
          <div ref={componentRef} className="m-5" id="template"></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => close()}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};



export default ModalPrintPercha;
