import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getVideos, postVideo } from "@services/youtubeApi.service";

import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import styles from "./index.module.scss";
import YoutubeEmbed from "@views/VideoTutorial/components/YoutubeEmbeded";
import { getYoutubeVideoId, defaultRoles } from "@views/VideoTutorial/helpers/videoTutorials"
import Select from "@components/Select";
import { getFilterRoles } from "@services/Filtros/filtros";

const AddVideoModal = (props) => {
  const {
    modalAddVideo,
    setModalAddVideoHandler,
    videoApiData,
    onVideosHandler,
    adminRoles,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [roles, setRoles] = useState([]);

  const onSubmit = (data) => {
    Object.assign(data, {
      roles: defaultRoles(roles, adminRoles),
      duration: videoApiData.duration,
    });

    setRoles([]);

    postVideo(`/videos`, data)
      .then((response) => {
        getVideos(`/videos`)
          .then((response) => {
            reset();
            setModalAddVideoHandler(false);
            onVideosHandler(response.data);
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };


  useEffect(() => {
    reset({
      title: videoApiData ? videoApiData.title : "",
      description: videoApiData ? videoApiData.description : "",
      url: videoApiData ? videoApiData.url : "",
    });
  }, [videoApiData, reset]);

  return (
    <div>
      <Modal isOpen={modalAddVideo}>
        <ModalHeader className={styles.modal_header}>
          Añadir nuevo video
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={styles.container}>
                <input
                  className={`${errors.title && "is-invalid"}`}
                  type="text"
                  placeholder="Título"
                  {...register("title", { required: true })}
                />
                {errors.title?.type === "required" && (
                  <div className="invalid-feedback">Introduzca título</div>
                )}

                <textarea
                  className={`${errors.title && "is-invalid"}`}
                  placeholder="Descripción"
                  {...register("description", { required: true })}
                />
                {errors.description?.type === "required" && (
                  <div className="invalid-feedback">
                    Introduzca descripción del video
                  </div>
                )}

                <input
                  className={`${errors.title && "is-invalid"}`}
                  type="url"
                  placeholder="url"
                  {...register("url", { required: true })}
                  readOnly
                />
              
              <Select
                isMulti
                placeholder="Roles"
                loadOptions={getFilterRoles}
                value={roles}
                onChange={(opts) => setRoles(opts)}
              />

              <YoutubeEmbed
                embedId={videoApiData && getYoutubeVideoId(videoApiData.url)}
              />
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={styles.btn_custom}
            onClick={handleSubmit(onSubmit)}
          >
            Añadir
          </Button>
          <Button color="secondary" onClick={setModalAddVideoHandler}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddVideoModal;
