import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getVideos, patchVideo } from "@services/youtubeApi.service";
import styles from "./index.module.scss";

const DeleteVideoModal = (props) => {
  const { modalDeleteVideo, modalDeleteVideoHandler, videoId, setVideos } =
    props;

  const deleteHandler = () => {
    patchVideo(`/videos/${videoId}`, { deleted: true })
      .then(() => {
        getVideos(`/videos`)
          .then((response) => {
            setVideos(response.data);
            modalDeleteVideoHandler();
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  return (
    <div>
      <Modal isOpen={modalDeleteVideo} toggle={modalDeleteVideoHandler}>
        <ModalHeader
          toggle={modalDeleteVideoHandler}
          className={styles.modal_header}
        >
          Eliminar Video
        </ModalHeader>
        <ModalBody>
          Pulse eliminar para confirmar el borrado del video.
        </ModalBody>
        <ModalFooter>
          <Button className={styles.btn_custom} onClick={deleteHandler}>
            Eliminar
          </Button>{" "}
          <Button color="secondary" onClick={modalDeleteVideoHandler}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteVideoModal;
