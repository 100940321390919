const gray = "#181818";
const green = "#0D8500";
const orange = "#F45000";
const red = "#ED0530";

export const preparationStateOptions = [
	{ label: "No aplica", value: 4 },
	{ label: "Bien", value: 1 },
	{ label: "Regular", value: 3 },
	{ label: "Mal", value: 2 }
];

export const preparationStateOptionsColors = {
	[preparationStateOptions[0].label]: {
		color: gray
	},
	[preparationStateOptions[1].label]: {
		color: green,
		borderColor: green
	},
	[preparationStateOptions[2].label]: {
		color: orange,
		borderColor: orange
	},
	[preparationStateOptions[3].label]: {
		color: red,
		borderColor: red
	}
};

export const getStateBubbleConfig = (state) => {
	switch (state?.label) {
		case preparationStateOptions[0].label:
			return {
				letter: "Na",
				borderColor: gray,
				color: gray
			};

		case preparationStateOptions[1].label:
			return {
				letter: "B",
				borderColor: green,
				color: green
			};
		case preparationStateOptions[2].label:
			return {
				letter: "R",
				borderColor: orange,
				color: orange
			};
		case preparationStateOptions[3].label:
			return {
				letter: "M",
				borderColor: red,
				color: red
			};

		default:
			return {
				letter: "i",
				borderColor: "#939393",
				color: "#939393"
			};
	}
};
