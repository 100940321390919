import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MdLockOpen } from "react-icons/md";
import { Table } from "reactstrap";
import {
  getEmployeeProfile,
  getEmployeeContract,
} from "@services/Employees/employeeProfile";

import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import { WarningPanel } from "@components/InfoBox";
import { UserContext } from "@contexts/UserContext";
import instance from "@services/base";
import { IS_SUPER_ADMIN } from "@constants/roles";
import { errorToast, successToast } from "@helpers/toastFunctions";

const SeeEmployee = () => {
  const { id } = useParams();
  const { userType } = useContext(UserContext);

  const [employee, setEmployee] = useState();
  const [employeeContracts, setEmployeeContracts] = useState([]);

	const unlockEmployee = async (e) => {
		try {
			const response = await instance.post(`api/employee/${id}/unblock`);
			if (response.status === 204) {
        successToast("Usuario desbloqueado")
				setEmployee((prev) => ({ ...prev, blockedAt: null }));
			}
		} catch (err) {
			errorToast(err.response?.data?.message || "Error al desbloquear al usuario");
		}
	};

  useEffect(() => {
    (async () => {
      const responseProfile = await getEmployeeProfile(id);
      const responseContracts = await getEmployeeContract(id);
      setEmployee(responseProfile);
      setEmployeeContracts(responseContracts);
    })();
  }, [id]);

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()}`;
  };

  return (
    <Mainlayout>
      <Mainlayout.Content full contentTitle="DETALLES DEL EMPLEADO">
				{employee?.blockedAt && IS_SUPER_ADMIN(userType) && (
					<div className={`col-12 col-lg-10 ${styles.unlock}`}>
						<WarningPanel text="Usuario bloqueado" />
						<button
							className="btn orange-btn"
							onClick={unlockEmployee}
						>
							Desbloquear
						</button>
					</div>
				)}

        <div
          className={`col-12 col-lg-10 d-flex flex-column align-self-center mt-3 ${styles.card}`}
        >
          <blockquote>Datos del empleado</blockquote>

          <div className="d-flex flex-column align-self-center w-75">
            <div className="d-flex flex-column flex-sm-row">
              <a
                className="btn orange-btn mt-3 mt-sm-0"
                href={`/main/editar-empleado/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                EDITAR DATOS DEL EMPLEADO
              </a>
              <a
                className="btn orange-btn mt-3 mt-sm-0 ml-0 ml-sm-3"
                href={`/main/roles-usuario/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdLockOpen size={24} />
                <span className="ml-1">VER ROLES</span>
              </a>
            </div>

            <blockquote>Datos personales</blockquote>
            <div className="d-flex flex-wrap">
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Nombre</label>
                <span>{employee?.nombre}</span>
              </div>
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Primer apellido</label>
                <span>{employee?.apellido1}</span>
              </div>
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Segundo apellido</label>
                <span>{employee?.apellido2}</span>
              </div>
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>DNI</label>
                <span>{employee?.dni}</span>
              </div>
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Fecha de nacimiento</label>
                <span>{dateFormat(employee?.fecha_nacimiento)}</span>
              </div>
            </div>

            <blockquote>Datos de contacto</blockquote>
            <div className="d-flex flex-wrap">
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Email</label>
                <span>{employee?.email}</span>
              </div>
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Teléfono 1</label>
                <span>{employee?.telefono1}</span>
              </div>
              <div className={`col-12 col-sm-4 ${styles.person_info}`}>
                <label>Teléfono 2</label>
                <span>{employee?.telefono2}</span>
              </div>
            </div>

            <blockquote>Locales asignados</blockquote>
            {/* Locales chips */}
            <div className="d-flex flex-wrap">
              {employee?.locales?.map((local) => (
                <span key={local.id} className={styles.chip}>
                  {local.nombre}
                </span>
              ))}
            </div>

            <blockquote>Marcas comerciales asignadas</blockquote>
            {/* Marcas comerciales chips */}
            <div className="d-flex flex-wrap">
              {employee?.marcas?.map((marca) => (
                <span key={marca.id} className={styles.chip}>
                  {marca.nombre}
                </span>
              ))}
            </div>
          </div>

          <blockquote>Contratos</blockquote>
          <div style={{ overflowX: "auto" }}>
            <Table className={styles.table}>
              <thead>
                <tr>
                  <th>Fecha contrato</th>
                  <th>Estado</th>
                  <th>Cliente</th>
                  <th>Vehículo</th>
                  <th>Matrícula</th>
                  <th>Teléfono</th>
                  <th>Importe compra</th>
                  <th>Beneficio</th>
                </tr>
              </thead>
              <tbody>
                {employeeContracts.length !== 0 ? (
                  employeeContracts.map((contract) => (
                    <tr key={contract.id}>
                      <td>{dateFormat(contract.fecha)}</td>
                      <td>{contract.estado}</td>
                      <td>{`${contract.cliente.nombre} ${contract.cliente.apellido1}`}</td>
                      <td>{`${contract.vehiculo.marca} ${contract.vehiculo.modelo}`}</td>
                      <td>{contract.vehiculo.matricula}</td>
                      <td>{contract.cliente.telefono}</td>
                      <td>{contract.importeCompra} €</td>
                      <td>{contract.beneficioVenta} €</td>
                    </tr>
                  ))
                ) : (
                  <td colSpan="8" className="text-center font-weight-bold">
                    No existen contratos para este empleado
                  </td>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default SeeEmployee;
