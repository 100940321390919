import { formatPhoneNumber } from "@helpers/formatPhoneNumber";
import React, { useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { getDealer } from "@services/Dealers/dealers.service";
import styles from "./index.module.scss";

import { MdEdit, MdDeleteForever } from "react-icons/md";

const DealersTable = (props) => {
  const {
    dealers,
    searchTerm,
    actualPage,
    limit,
    setModalDeleteDealerHandler,
    setModalAddDealer,
    setNewDealer,
    setDealersCount,
    dealersFiltered,
  } = props;

  const dynamicSearch = () => {
    let dealersData = [];
    dealersData = dealersFiltered?.filter((dealer) => {
      return (
        dealer.nombre
          .toLowerCase()
          .replace(/\s+/g, "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            searchTerm
              .toLowerCase()
              .replace(/\s+/g, "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) ||
        (dealer.encargado &&
          dealer.encargado
            .toLowerCase()
            .replace(/\s+/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchTerm
                .toLowerCase()
                .replace(/\s+/g, "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )) ||
        dealer.telefono
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
      );
    });

    setDealersCount(dealersData?.length);
    return dealersData;
  };

  const updateHandler = (id) => {
    getDealer(`/dealers`, id)
      .then((dealer) => {
        setNewDealer(dealer);
        setModalAddDealer();
      })
      .catch((e) => {
        throw e;
      });
  };

  useEffect(() => {
    getDealersRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealersFiltered, dealers]);

  const getDealersRows = () => {
    let dealersRow = [];
    dynamicSearch()?.map((dealer) => {
      return dealersRow.push(
        <tr style={{ fontSize: "15px" }} key={dealer.id}>
          <td style={{ width: "300px" }}>{dealer.nombre}</td>
          <td style={{ width: "200px" }}>
            {formatPhoneNumber(dealer.telefono)}
          </td>
          <td style={{ width: "300px" }}>{dealer.email}</td>
          <td>{dealer.encargado}</td>

          <td className={styles.cursor_pointer}>
            <MdEdit
              title="Modificar"
              size={26}
              color={"#007bff"}
              role="button"
              onClick={() => updateHandler(dealer.id)}
            />

            <MdDeleteForever
              title="Eliminar documento"
              size={26}
              color="red"
              onClick={() => setModalDeleteDealerHandler(dealer.id)}
              className="mx-3"
            />
          </td>
        </tr>
      );
    });

    if (dealersRow.length > 0) {
      if (searchTerm === "") {
        return dealersRow.slice(
          (actualPage - 1) * limit,
          19 + ((actualPage - 1) * limit + 1)
        );
      }
      return dealersRow;
    } else {
      return (
        <h2 className="m-4">
          No existen concesionarios que coincidan con sus criterios de búsqueda.
        </h2>
      );
    }
  };

  return (
    <table className="table table-striped fl-table">
      <thead>
        <tr>
          <th scope="col-md-1">Nombre</th>
          <th scope="col-md-1">Teléfono</th>
          <th scope="col-md-1">Email</th>
          <th scope="col-md-1">Encargado</th>
          <th scope="col-md-1">Acciones</th>
        </tr>
      </thead>
      {dealers ? (
        <tbody className={""}>{getDealersRows()}</tbody>
      ) : (
        <div className="m-5">
          <Row className="justify-content-center">
            <Col className="col-12">
              <Spinner type="grow" className="flexicarColor" />
            </Col>
          </Row>
        </div>
      )}
    </table>
  );
};

export default DealersTable;
