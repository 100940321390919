import React, { useRef, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";

import styles from "./index.module.scss";

const DropzoneFile = ({
  file,
  setFile,
  fileName,
  setFileName,
  allowedFormats,
  maxLengthTitle,
  setDisableAccept,
}) => {
  const fileInputRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [errors, setErrors] = useState({});

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const droppedFile = e.dataTransfer.files[0];

    if (!isAllowedFormat(droppedFile)) {
      setErrors({
        ...errors,
        documentType:
          "Formato de archivo no permitido.",
      });
      return;
    }

    setFile(droppedFile);
    handleFileNameChange(droppedFile.name);
  };

  const handleFileNameChange = (e) => {
    const selectedFileName = e;
    setFileName(selectedFileName);
    if (selectedFileName.length > maxLengthTitle) {
      setErrors({
        ...errors,
        title: `El nombre del archivo no puede exceder los ${maxLengthTitle} caracteres`,
      });
    } else {
      handleClearErrors();
      setDisableAccept(false);
    }
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (!isAllowedFormat(selectedFile)) {
      setErrors({
        ...errors,
        documentType:
          "Formato de archivo no permitido",
      });
      return;
    }
    setFile(selectedFile);
    handleFileNameChange(selectedFile.name);
  };

  const isAllowedFormat = (file) =>
    !allowedFormats ||
    allowedFormats.some((format) => file.name.toLowerCase().endsWith(format));

  const handleClearErrors = () => {
    setErrors({
      title: null,
      documentType: null,
    });
  };

  return (
    <div
      className={`${styles.dropzone} ${isDragOver ? styles.dragActive : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {isDragOver ? (
        <span>Arrastre el archivo aquí...</span>
      ) : file ? (
        <>
          <div className={styles.preview}>
            <label>Inserte nombre para renombrar archivo</label>
            <input
              type="text"
              required
              value={fileName}
              onChange={(e) => handleFileNameChange(e.target.value)}
            />
            {errors.title && (
              <p className={styles.customSelectError}>
                <FiAlertCircle size={20} />
                {errors.title}
              </p>
            )}
          </div>
        </>
      ) : (
        <span>
          Arrastre o pulse{" "}
          <button
            className={`btn ${styles.dropzoneTextButton}`}
            onClick={() => fileInputRef.current.click()}
          >
            aquí
          </button>{" "}
          para subir el documento
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          {errors.documentType && (
            <p className={styles.customSelectError}>
              <FiAlertCircle size={20} />
              {errors.documentType}
            </p>
          )}
        </span>
      )}
    </div>
  );
};

export default DropzoneFile;
