export const formatPhoneNumber = (phone) => {
  let phoneNumber = "";

  if (!phone) {
    return "";
  }

  if (phone[0] === "9") {
    phoneNumber = `${phone[0]}${phone[1]} ${phone[2]}${phone[3]}${phone[4]} ${phone[5]}${phone[6]} ${phone[7]}${phone[8]}`;
  } else {
    phoneNumber = `${phone[0]}${phone[1]}${phone[2]} ${phone[3]}${phone[4]} ${phone[5]}${phone[6]} ${phone[7]}${phone[8]}`;
  }

  return phoneNumber;
};
