const customStyles = () => {
	const fontColor = "#181818";
	const optionFocusColor = "rgb(239, 239, 239, .45)";
	const optionSelectedColor = "rgba(0, 0, 0, 0.08)";

	return {
		control: (provided, state) => {
			let border = provided.border;

			return {
				...provided,
				outline: 0,
				boxShadow: "none",
				border
			};
		},
		multiValue: (provided) => {
			return { ...provided, 
				minWidth: "unset",
				backgroundColor: "transparent",
				borderRadius: "32px",
				border: "1px solid #424242",
				".css-xb97g8:hover": {
					backgroundColor: "transparent",
					color: "#212529"
				  }
			 };
		},
		multiValueLabel: (provided) => {
			return { ...provided, textOverflow: "unset" };
		},
		input: (provided, state) => {
			if (state.isMulti && state.hasValue) {
				return { provided, order: 5, width: "fit-content" };
			}
			return provided;
		},
		option: (provided, state) => {
			let backgroundColor = provided.backgroundColor;
			// @ts-ignore : known limitation in typescript
			let active = provided[":active"];
			const cursor = "pointer";
			const color = fontColor;

			if (state.isFocused) {
				backgroundColor = optionFocusColor;
				active = { ...active, backgroundColor };
			}

			if (state.isSelected) {
				backgroundColor = optionSelectedColor;
				active = {
					...active,
					backgroundColor: backgroundColor
				};
			}

			if (state.isFocused && state.isSelected) {
				backgroundColor = optionSelectedColor;
				active = {
					...active,
					backgroundColor: optionSelectedColor
				};
			}

			return {
				...provided,
				":active": active,
				backgroundColor,
				cursor,
				color
			};
		},
		valueContainer: (provided) => {
			return { ...provided, flexWrap: "nowrap" };
		}
	};
};

export default customStyles;
