import * as Yup from "yup";

import Locale from "@constants/Locale";
import { yesOrNotSelectPT } from "@constants/selectOptionsPT";

const transport = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obrigatório"),
	amount: Yup.number().when(["$sellInfo.transport.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema
				.min(0, "O campo deve ser maior ou igual a 0")
				.isNumberWithLessThanNDecimals(2, "O campo não pode ter mais de 2 casas decimais")
			: schema.notRequired()
	)
});

const warranty = Yup.object({
	type: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obrigatório"),
	amount: Yup.number()
		.min(0, "O campo deve ser maior ou igual a 0")
		.isNumberWithLessThanNDecimals(2, "O campo não pode ter mais de 2 casas decimais")
});

const carAsPaymentVehicle = Yup.object({
	brand: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema.required("Campo obrigatório")
			: schema.notRequired()
	),
	model: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema.required("Campo obrigatório")
			: schema.notRequired()
	),
	version: Yup.string().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		schema.notRequired()
	),
	plate: Yup.string().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema.required("Campo obrigatório").plate({ locale: Locale.PT, errorMessage: "Matrícula inválido" })
			: schema.notRequired()
	),
	plateDate: Yup.string().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		schema.notRequired()
	),
	vin: Yup.string().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		schema.notRequired()
	),
	kms: Yup.number().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema
				.integer("Decimais não são permitidos")
				.max(999999, "Km deve ser igual ou inferior a 999.999").nullable()
			: schema.notRequired()
	),
	color: Yup.string().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		schema.notRequired()
	),
	fuel: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema.required("Campo obrigatório")
			: schema.notRequired()
	),
	transmission: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema.required("Campo obrigatório")
			: schema.notRequired()
	)
});

const carAsPayment = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obrigatório"),
	amount: Yup.number().when(["$sellInfo.carAsPayment.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema
				.min(0, "O campo deve ser maior ou igual a 0")
				.isNumberWithLessThanNDecimals(2, "O campo não pode ter mais de 2 casas decimais")
			: schema.notRequired()
	),
	vehicle: carAsPaymentVehicle
});

const sellContractSellInfoSchemaPT = Yup.object({
	priceType: Yup.string().nonNullable("Selecione uma opção"),
	pvp: Yup.number().moreThan(0, "O campo deve ser maior que 0"),
	administrativeCosts: Yup.number()
		.min(0, "O campo deve ser maior ou igual a 0")
		.isNumberWithLessThanNDecimals(2, "O campo não pode ter mais de 2 casas decimais"),
	expectedDeliveryDate: Yup.string().isISODateString({ excludeEmptyString: true }).nullable(),
	transport,
	warranty,
	carAsPayment
});

export { sellContractSellInfoSchemaPT };
