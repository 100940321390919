import { HiFlag } from "react-icons/hi";
import moment from "moment";
import { MdInsertDriveFile } from "react-icons/md";
function OtherLeadsList({ otherlead, launchModalChangeState }) {
  return (
    <>
      <td>{moment(otherlead.f_alta).format("DD-MM-YYYY HH:mm")}</td>
      <td>
        {moment(otherlead.f_ultima_modificacion).format("DD-MM-YYYY HH:mm")}
      </td>
      <td>{otherlead.nombre_estado}</td>
      <td>{otherlead.nombre_procedencia}</td>
      <td>{otherlead.nombre_ultima_accion}</td>
      <td>
        {new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 0,
        }).format(Math.trunc(otherlead.pvp))}
      </td>
      <td>
        <a
          className="clean-link"
          href={`/main/lead-venta/${otherlead.id}/${otherlead.id_cliente_lead}`}
        >
          <MdInsertDriveFile color="rgb(0, 123, 255)" size={20} role="button" />
        </a>
        <HiFlag
          size={25}
          color="#007bff"
          title="Cambiar Estado"
          style={{ cursor: "pointer" }}
          onClick={() =>
            launchModalChangeState(otherlead.id, otherlead.id_cliente_lead)
          }
        />
      </td>
    </>
  );
}
export default OtherLeadsList;
