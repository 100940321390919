import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

import styles from "./index.module.scss";

const Collapsible = ({ open, header, className, children }) => {
	const [isOpen, setIsOpen] = useState(open);
	const [height, setHeight] = useState(open ? undefined : 0);

	const ref = useRef(null);

	const handleFilterOpening = () => {
		setIsOpen((prev) => !prev);
	};

	const modChildren = React.Children.map(children, (child) => {
		if (child.type?.name === "Collapsible") {
			return React.cloneElement(child, {
				...child.props,
				open: false
			});
		}
		return child;
	});

	useEffect(() => {
		if (!height || !isOpen || !ref.current) return;

		const resizeObserver = new ResizeObserver((el) => {
			setHeight(el[0].contentRect.height);
		});
		resizeObserver.observe(ref.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [height, isOpen]);

	useEffect(() => {
		setHeight(isOpen ? ref.current?.getBoundingClientRect().height : 0);
	}, [isOpen]);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<div className={`${styles.collapsible} ${className}`}>
			<div className={styles.collapsibleHeader}>
				<div className={styles.collapsibleHeaderTitle}>{header}</div>
				<button
					type="button"
					className={styles.collapsibleHeaderCaret}
					onClick={handleFilterOpening}
				>
					{isOpen ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
				</button>
			</div>
			<div
				className={styles.collapsibleBody}
				style={{ height }}
			>
				<div ref={ref}>
					<div className={styles.collapsibleBodyContent}>{modChildren}</div>
				</div>
			</div>
		</div>
	);
};

export default Collapsible;
