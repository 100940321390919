import React, { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdErrorOutline, MdOutlineCheckCircleOutline } from "react-icons/md";

import styles from "./index.module.scss";
import Loading from "@views/Loading/Loading";
import PaginationFooter from "@components/PaginationFooter";
import CasesTable from "@components/CasesTable";
import FormattedNumberInput from "@components/FormattedNumberInput";
import { API } from "@services/urlConstants";
import { get, post } from "@services/axiosCalls";
import { errorToast } from "@helpers/toastFunctions";
import Mainlayout from "@layouts/MainLayout";
import { RiFlag2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import RolType from "@constants/roles";


const PostventaVehiculo = ({ vehicle }) => {
	const { id: vehicleId } = useParams();

	const { rol } = useContext(UserContext);

	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);

	const paginationLimit = 8;
	const [paginationMin, setPaginationMin] = useState(0);

	const [cases, setCases] = useState([]);
	const [totalCases, setTotalCases] = useState(0);
	const [garantia, setGarantia] = useState([]);
	const [vehicleTotalAmount, setVehicleTotalAmount] = useState(0);



	const POSTVENTA_ROLES = [
		RolType.AFTER_SALES_AGENT,
		RolType.AFTER_SALES_SUPERVISOR,
		RolType.LEGAL_ADVISER,
		RolType.GENERAL_ADMIN,
		RolType.FINANCING_AGENT,
		RolType.USER_MANAGER,
		RolType.TRADING_MANAGER,
	  ];

	  const checkPostVentaRoles = () => {
		const hasPostventaRol = POSTVENTA_ROLES.some((r) => rol.includes(r));
		const hasWarrantyRolAndCanEdit = rol.includes(RolType.WARRANTY) && vehicle?.canUserEditAfterSale;
		return hasPostventaRol || hasWarrantyRolAndCanEdit;
	};
	  

	const changePagination = (min, num) => {
		setPaginationMin(min);
	};

	const loadCases = useCallback(async () => {
		try {
			const casesResponse = await post(API.GET_CASES, {
				min: paginationMin,
				limit: paginationLimit,
				vehicle_id: vehicleId
			});
			setCases(casesResponse.data.map((x) => ({ ...x, id_vehiculo: vehicleId })));
			setTotalCases(casesResponse.count);
		} catch (err) {
			errorToast(err?.response?.data?.message || "");
		}
	}, [paginationMin, vehicleId]);

	const loadCaseTotalAmount = useCallback(async () => {
		try {
			const amountResponse = await get(API.GET_VEHICLE_CASES_AMOUNT(vehicleId));
			setVehicleTotalAmount(amountResponse.totalAmount);
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al conseguir el coste total del vehiculo.");
		}
	}, [vehicleId]);

	const loadGarantia = useCallback(async () => {
		try {
			const garantiaResponse = await post(API.GET_GARANTIA, {
				vehicle_id: vehicleId
			});
			setGarantia(garantiaResponse);
		} catch (err) {
			errorToast(err?.response?.data?.message || "");
		}
	}, [vehicleId]);

	const handleGarantia = (element) => {
		const actual = moment();
		let info;
		if (element.caducidad) {
			if (moment(element.caducidad) <= actual) {
				info = (
					<div className={styles.warrantyBox}>
						<MdErrorOutline
							size={50}
							color={"red"}
						/>
						<span className={styles.h2Size}>{`La garantia ${element.nombre} expiró el dia ${moment(
							element.caducidad
						).format("DD-MM-YYYY")}`}</span>
					</div>
				);
			} else {
				info = (
					<div>
						<div className={styles.warrantyBox}>
							&nbsp; &nbsp; &nbsp;
							<MdOutlineCheckCircleOutline
								size={30}
								color={"green"}
							/>
							&nbsp; &nbsp; &nbsp;
							<span className={styles.h2Size}>
								La garantia <b>{element.nombre}</b> esta disponible hasta el dia
								<b> {moment(element.caducidad).format("DD-MM-YYYY")}</b>
							</span>
						</div>
					</div>
				);
			}
		} else info = null;

		return info;
	};

	useEffect(() => {
		(async () => {
			await loadCases();
			await loadCaseTotalAmount();
			await loadGarantia();
			setIsLoading(false);
		})();
	}, [loadCases, loadCaseTotalAmount, loadGarantia]);

	if (isLoading) return <Loading />;

	return (
		<>
			<Mainlayout />
			<Mainlayout.Content
        full
				contentTitle="POSTVENTA DEL VEHÍCULO"
				buttons={(
					 checkPostVentaRoles() && (
						<button
							onClick={() => {
								history.push(`/main/vehicle/${vehicleId}/crear-caso`);
							}}
							className="btn-flexicar-orange mr-3"
						>
							<RiFlag2Line
								size={24}
								color="#FFFFFF"
								data-toggle="tooltip"
								data-placement="top"
								title="Crear Caso"
								role="button"
								className="mr-2"
							/>
							Crear Caso
						</button>
					)
					)
				}
			>
				<div>
					<div>
						{garantia.length > 0 ? (
							<>
								{garantia.map((e) => (
									<Fragment key={e.contrato}>{handleGarantia(e)}</Fragment>
								))}
							</>
						) : (
							<p>No existe información sobre la garantía de este vehículo.</p>
						)}
						<span className={styles.vehicleTotalAmount}>
							Coste total del vehículo:{" "}
							<FormattedNumberInput
								value={vehicleTotalAmount}
								displayType="text"
							/>
						</span>
						{cases && (
							<CasesTable
								cases={cases}
								insideVehicle
								checkPostVentaRoles={checkPostVentaRoles}
							/>
						)}
						<PaginationFooter
							paginationMin={paginationMin}
							total={totalCases}
							showNumb={paginationLimit}
							elements={cases}
							changePagination={changePagination}
						/>
					</div>
				</div>
			</Mainlayout.Content>
		</>
	);
};

export default PostventaVehiculo;
