import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import { errorToast } from "@helpers/toastFunctions";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import FormattedNumberInput from "@components/FormattedNumberInput";
import TabPanel from "@components/TabPanel";
import Select from "@components/Select";
import instance from "@services/base";
import CasoComments from "@views/Caso/Comments";
import WithdrawalReceptionDocs from "@views/Caso/WithdrawalReceptionDocs";
import CasoAppointments from "@views/Caso/Appointments";
import HistoricalCaseActionModal from "@views/Caso/ModalHistoricalData";

const Caso = () => {
	const { vehicleId, caseId } = useParams();

	const history = useHistory();
	const location = useLocation();

	const [caseInfo, setCaseInfo] = useState({});
	const [caseStateOptions, setCaseStateOptions] = useState([]);
	const [commentsTotalAmount, setCommentsTotalAmount] = useState(0);

	const [showHistoricalActions, setShowHistoricalActions] = useState(false);

	const loadCaseStates = async () => {
		try {
			const resStates = await instance.post(API.FILTROS, {
				dataCall: { data_query: "estado" }
			});
			setCaseStateOptions(
				resStates.data.map((state) => ({
					label: state.nombre,
					value: state.id
				}))
			);
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al cargar los estados");
		}
	};

	const loadCaseInfo = useCallback(async () => {
		try {
			const caseResponse = await instance.get(API.GET_CASE(Number(caseId)));
			setCaseInfo(caseResponse.data);
			setCommentsTotalAmount(caseResponse.data.totalAmount);
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al obtener el caso");
		}
	}, [caseId]);

	const updateCaseState = async (caseState) => {
		setCaseInfo((prev) => ({
			...prev,
			estadoId: caseState.value
		}));

		try {
			post(API.CHANGE_STATE_CASE, {
				id: caseInfo.id,
				estadoId: caseState.value
			});
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al actualizar el estado del caso");
		}
	};

	useEffect(() => {
		loadCaseInfo();
		loadCaseStates();
	}, [loadCaseInfo]);

	const barButtons = (
		<>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => setShowHistoricalActions(true)}
			>
				Historial de acciones
			</button>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => history.push(`${location.pathname}/nuevo-documento?type=retirada`)}
			>
				Doc. retirada
			</button>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => history.push(`${location.pathname}/nuevo-documento?type=recepcion`)}
			>
				Doc. recepción
			</button>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => history.push(`/main/vehicle/${vehicleId}?tab=postventa`)}
			>
				Volver
			</button>
		</>
	);

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={caseInfo.titulo}
				buttons={barButtons}
			>
				<h3>{caseInfo.descripcion}</h3>
				<div className={styles.caseInfo}>
					<span>
						Nombre completo <span>{caseInfo.nombre}</span>
					</span>
					<span>
						Teléfono <span>{caseInfo.telefono}</span>
					</span>
					<span>
						Email <span>{caseInfo.email}</span>
					</span>
				</div>

				<div className={styles.caseState}>
					<div className={styles.inputWrapper}>
						<label>Estado</label>
						<Select
							options={caseStateOptions}
							value={caseStateOptions.filter((caseState) => caseState.value === caseInfo.estadoId)}
							onChange={(newValue) => updateCaseState(newValue)}
						/>
					</div>
					<span>
						Importe Total:{" "}
						<FormattedNumberInput
							value={commentsTotalAmount}
							displayType="text"
						/>
					</span>
				</div>

				<TabPanel
					id="caseTab"
					searchParam="tab"
					tabs={[
						{
							headers: {
								tabName: "Comentarios",
								searchParamValue: "comentarios"
							},
							content: <CasoComments caseId={caseId} />
						},
						{
							headers: {
								tabName: "Doc. retirada/recepción",
								searchParamValue: "docs"
							},
							content: (
								<WithdrawalReceptionDocs
									caseId={caseId}
									vehicleId={vehicleId}
								/>
							)
						},
						{
							headers: {
								tabName: "Citas",
								searchParamValue: "citas"
							},
							content: (
								<CasoAppointments
									caseInfo={caseInfo}
									setCaseInfo={setCaseInfo}
								/>
							)
						}
					]}
				/>
			</Mainlayout.Content>

			{showHistoricalActions && (
				<HistoricalCaseActionModal
					isOpen={showHistoricalActions}
					setIsOpen={setShowHistoricalActions}
				/>
			)}
		</Mainlayout>
	);
};

export default Caso;
