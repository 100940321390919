import React from "react";
import styles from "./index.module.scss";
import { Input, Col } from "reactstrap";
import {
  makes,
  models,
  years,
  fuels,
  transmision,
  body,
  version,
} from "@services/Vehicle/vehicle.service";
import classes from "./index.module.scss";
import Locale from "@constants/Locale";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";

const DatosVehiculoClasico = ({
  vehiculo,
  setVehiculo,
  className,
  mode,
  type,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('classicVehicleData');

  const getDataAdd = (filter, value) => {
    if (!vehiculo?.jato || mode === "MANUAL") {
      switch (filter) {
        case "jato":
          setVehiculo({
            ...vehiculo,
            [filter]: !value,
            modelo: "",
            matriculacion: "",
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "marca_vehiculo":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            modelo: "",
          });
          break;
        default:
          setVehiculo({
            ...vehiculo,
            [filter]: value,
          });

          break;
      }
    }
    if (vehiculo?.jato) {
      switch (filter) {
        case "jato":
          setVehiculo({
            ...vehiculo,
            [filter]: !value,
          });
          break;

        case "marca_vehiculo":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            modelo: "",
            matriculacion: "",
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "modelo":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            matriculacion: "",
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "matriculacion":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "combustible":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "ccambios":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            carroceria: "",
            version: "",
          });
          break;
        case "carroceria":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            version: "",
          });
          break;
        case "version":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
          });
          break;
        default:
          break;
      }
    }
  };

  const getFiltersMakes = async () => {
    const response = await makes(mode);

    return response.map(x => ({
      label: x.name,
      value: x.id
    }))
  }

  return (
    <>
      <h4 className={classes.bold} style={{ display: "inline-block" }}>
        Datos básicos del vehículo
      </h4>
      {type === "draft" && i18n.language === Locale.ES ? (
        <label for="valid" title="Change to true" className={styles.switch}>
          <input
            id="control-valid"
            type="checkbox"
            setter={"jato"}
            name="valid"
            checked={vehiculo.jato}
            onChange={() => {
              getDataAdd("jato", vehiculo.jato);
            }}
          />
          <span>Jato</span>
          <span>Clásico</span>
        </label>
      ) : null}

      <Col xs="12" sm="12">
        <div className={styles.row} style={{ marginTop: ".25rem" }}>
          <div className="col-md-4 col-sm-12">
            <label
              className={
                vehiculo?.marca_vehiculo ? styles.select : styles.notSelect
              }
            >
              {t('brand')}:
            </label>
            <div className={styles.filter}>
            <Select
								loadOptions={getFiltersMakes}
                placeholder={t('brand')}
                value={vehiculo?.marca_vehiculo}
                onChange={(value) => getDataAdd("marca_vehiculo", [value])}
                isDisabled={type === "stock" ? true : false}
							/>
            </div>
          </div>

          <div
            className="col-md-4 col-sm-12">
            <label
              className={vehiculo?.modelo ? styles.select : styles.notSelect}
            >
              {t('model')}:
            </label>
            <div className={styles.filter}>
              <Select
                reloadOptions
								loadOptions={async () => {
                  const response = await models(mode, vehiculo?.marca_vehiculo[0]?.value);
                  return response.map(x => ({
                    label: x.name,
                    value: x.id
                  }))
								}}
                placeholder={t('model')}
                value={vehiculo?.modelo}
                onChange={(value) => getDataAdd("modelo", [value])}
                isDisabled={!vehiculo.marca_vehiculo || (!vehiculo.marca_vehiculo &&  mode === "JATO")}
							/>
            </div>
          </div>

          <div className="col-md-4 col-sm-12">
            <label
              className={
                vehiculo?.matriculacion ? styles.select : styles.notSelect
              }
            >
              {t('year')} :
            </label>
            <div className={styles.filter}>
              <Select
                  reloadOptions
                  loadOptions={async () => {
                    const response = await years(mode, {
                      makeId: vehiculo?.marca_vehiculo[0]?.value,
                      modelId: vehiculo?.modelo[0]?.value
                    });
                    return response.map(x => ({
                      label: x,
                      value: x
                    }))
                  }}
                  placeholder={t('year')}
                  value={vehiculo?.matriculacion}
                  onChange={(value) => getDataAdd("matriculacion", [value])}
                  isDisabled={!vehiculo.modelo && mode === "JATO"}
                />
            </div>
          </div>
        </div>

        <div className={styles.row} style={{ marginTop: ".25rem" }}>
          <div className="col-md-6 col-sm-12">
            <label
              className={
                vehiculo?.combustible ? styles.select : styles.notSelect
              }
            >
              {t('fuel')}:
            </label>
            <div className={styles.filter}>
              <Select
                  reloadOptions
                  loadOptions={async () => {
                    const response = await fuels(mode, {
                      makeId: vehiculo?.marca_vehiculo[0]?.value,
                      modelId: vehiculo?.modelo[0]?.value,
                      year: vehiculo?.matriculacion[0]?.value,
                    });
                    return response.map(x => ({
                      label: x.name,
                      value: x.id
                    }))
                  }}
                  placeholder={t('fuel')}
                  value={vehiculo?.combustible}
                  onChange={(value) => getDataAdd("combustible", [value])}
                  isDisabled={!vehiculo.matriculacion && mode === "JATO"}
                />
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <label
              className={vehiculo?.ccambios ? styles.select : styles.notSelect}
            >
              {t('gearbox')}:
            </label>
            <div className={styles.filter}>
              <Select
                  reloadOptions
                  loadOptions={async () => {
                    const response = await transmision(mode, {
                      makeId: vehiculo?.marca_vehiculo[0]?.value,
                      modelId: vehiculo?.modelo[0]?.value,
                      year: vehiculo?.matriculacion[0]?.value,
                      fuelId: vehiculo?.combustible[0]?.value,
                    });
                    return response.map(x => ({
                      label: x.name,
                      value: x.id
                    }))
                  }}
                  placeholder={t('gearbox')}
                  value={vehiculo?.ccambios}
                  onChange={(value) => getDataAdd("ccambios", [value])}
                  isDisabled={!vehiculo.combustible && mode === "JATO"}
                />
            </div>
          </div>
        </div>
        <div className={styles.row} style={{ marginTop: ".25rem" }}>
          <div className="col-md-6 col-sm-12">
            <label
              className={
                vehiculo?.carroceria ? styles.select : styles.notSelect
              }
            >
              {t('body')}:
            </label>
            <div className={styles.filter}>
              <Select
                  reloadOptions
                  loadOptions={async () => {
                    const response = await body(mode, {
                      makeId: vehiculo?.marca_vehiculo[0]?.value,
                      modelId: vehiculo?.modelo[0]?.value,
                      year: vehiculo?.matriculacion[0]?.value,
                      fuelId: vehiculo?.combustible[0]?.value,
                      transmissionId: vehiculo?.ccambios[0]?.value,
                    });
                    return response.map(x => ({
                      label: x.name,
                      value: x.id
                    }))
                  }}
                  placeholder={t('body')}
                  value={vehiculo?.carroceria}
                  onChange={(value) => getDataAdd("carroceria", [value])}
                  isDisabled={!vehiculo.ccambios && mode === "JATO"}
                />
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <label
              className={
                !className
                  ? vehiculo?.version
                    ? styles.select
                    : styles.notSelect
                  : "none"
              }
            >
              {t('version')}:
            </label>
            <div className={!className ? styles.filter : "none"}>
              {mode === "JATO" ? (
                <Select
                  reloadOptions
                  loadOptions={async () => {
                    const response = await version(mode, {
                      makeId: vehiculo?.marca_vehiculo[0]?.value,
                      modelId: vehiculo?.modelo[0]?.value,
                      year: vehiculo?.matriculacion[0]?.value,
                      fuelId: vehiculo?.combustible[0]?.value,
                      transmissionId: vehiculo?.ccambios[0]?.value,
                      bodyId: vehiculo?.carroceria[0]?.value,
                    });
                    return response.map(x => ({
                      label: `${x.name || ""} - ${x.doors ? `${x.doors}P` : ""} (${x.modelYear || "-"})`,
                      value: x.id
                    }))
                  }}
                  placeholder={t('version')}
                  value={vehiculo?.version}
                  onChange={(value) => getDataAdd("version", [value])}
                  isDisabled={!vehiculo.carroceria && mode === "JATO"}
                />
              ) : (
                <Input
                  placeholder={"Version"}
                  className="form-control ds-input "
                  type="text"
                  value={vehiculo?.version instanceof Array ? vehiculo?.version[0]?.label : vehiculo?.version}
                  onChange={(e) => {
                    setVehiculo({
                      ...vehiculo,
                      version: [
                        {
                          value: e.target.value,
                          label: e.target.value,
                          id: e.target.id,
                        },
                      ],
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default DatosVehiculoClasico;
