import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import styles from "@styles/views/SellContract/vehicle-data.module.scss";

import instance from "@services/base";
import { API } from "@services/urlConstants";

import { errorToast } from "@helpers/toastFunctions";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";

const VehicleDataPT = ({ dealershipsOptions }) => {
	const { watch, setValue } = useFormContext();

	const watchedDealership = watch("vehicle.dealership");

	const getProvinceByLocalId = useCallback(
		async (id) => {
			try {
				const response = await instance.post(API.GET_PROVINCIA_LOCAL, {
					id_local: id
				});
				setValue("vehicle.dealershipProvince", response.data[0]);
			} catch (err) {
				errorToast(err?.response?.data?.message || "Erro ao obter o distrito do local");
			}
		},
		[setValue]
	);

	useEffect(() => {
		watchedDealership?.value && getProvinceByLocalId(watchedDealership.value);
	}, [watchedDealership, getProvinceByLocalId]);

	return (
		<div>
			<PaneHeader title="Dados do veículo" />
			<div className={styles.container}>
				<FormInput
					name="vehicle.brand"
					label="Marca"
					disabled
				/>
				<FormInput
					name="vehicle.model"
					label="Modelo"
					disabled
				/>
				<FormInput
					name="vehicle.version"
					label="Versão"
				/>
				<FormInput
					name="vehicle.plate"
					label="Matrícula"
				/>
				<FormDatePicker
					name="vehicle.plateDate"
					label="Data matrícula"
				/>
				<FormInput
					name="vehicle.bastidor"
					label="Nº de chassis"
				/>
				<FormFormattedNumberInput
					name="vehicle.km"
					label="Quilómetros"
					suffix=" km"
					disabled
				/>
				<FormInput
					name="vehicle.color"
					label="Cor"
					disabled
				/>
				<FormInput
					name="vehicle.fuel"
					label="Combustível"
					disabled
				/>
				<FormInput
					name="vehicle.transmission"
					label="Transmissão"
					disabled
				/>
				<FormSelect
					name="vehicle.dealership"
					label="Local"
					options={dealershipsOptions}
				/>
				<FormSelect
					name="vehicle.dealershipProvince"
					label="Distrito local"
					options={[]}
					isDisabled
				/>
				<FormTextArea
					name="vehicle.conformidades"
					label="Anotações sobre o estado do veículo"
					maxLength={300}
				/>
			</div>
		</div>
	);
};

export default VehicleDataPT;
