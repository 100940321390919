import React, { useEffect, useState } from "react";
import TableDays from "@views/Turnos/components/TableDays";
import AddAgentBlock from "@views/Turnos/components/AddAgentBlock";
import { get, post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";

import ModalWarning from "@components/ModalWarning";
import styles from "./index.module.scss";

const Turnos = () => {
 
  const [daysPanel, setDaysPanel] = useState([]);
  const [alertSure, setAlertSure] = useState(false);
  const [unsubscribeIdx, setUnsubscribeIdx] = useState();
  const [listAgents, setListAgents] = useState(null);
  const [agentSelected, setAgentSelected] = useState(null);

  useEffect(() => {
    document.title = "Flexicar | Turnos";
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await get(API.GET_VENTAS_AGENTS);
      setDaysPanel(response);
    } catch (e) {
      errorCall(e);
    }
  };

  const changeDay = async (idx, day) => {
    let nextPanel = [...daysPanel];
    nextPanel[idx][day] = nextPanel[idx][day] === 1 ? 0 : 1;
    const data = {
      agent: nextPanel[idx].id,
      day,
      status: nextPanel[idx][day] ? 1 : 0,
    };

    try {
      await post(API.CHANGE_AGENT_SHIFT, data);
      setDaysPanel(nextPanel);
    } catch (e) {
      errorCall(e);
    }
  };

  const changeHolidays = async (idx) => {
    let nextPanel = [...daysPanel];
    nextPanel[idx].vacaciones = nextPanel[idx].vacaciones !== 1 ? 1 : 0;
    const data = {
      agent: nextPanel[idx].id,
      status: nextPanel[idx].vacaciones,
    };
    try {
      await post(API.CHANGE_AGENT_HOLIDAYS, data);
      setDaysPanel(nextPanel);
    } catch (e) {
      errorCall(e);
    }
  };

  const unsubscribe = async () => {
    let nextPanel = [...daysPanel];
    nextPanel[unsubscribeIdx].baja = true;
    try {
      const data = {
        agent: daysPanel[unsubscribeIdx].id,
        leave_date: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      await post(API.CHANGE_AGENT_LEAVE, data);
      setDaysPanel(nextPanel);
      setAlertSure(false);
      setUnsubscribeIdx(null);
    } catch (e) {
      errorCall(e);
    }
  };

  const getListAgents = async () => {
    if (listAgents) {
      setListAgents(null);
      setAgentSelected(null);
    } else {
      try {
        const response = await get(API.GET_AGENT_TO_ADD_TO_VENTAS);
        response.forEach(
          (e) => (e.dropdownInfo = `${e.nombre} ${e.apellido1} ${e.apellido2}`)
        );
        setListAgents(response);
      } catch (e) {
        errorCall(e);
      }
    }
  };

  const acceptAgent = async () => {
    try {
      if (agentSelected.f_baja_agente) {
        await post(API.UPDATE_AGENT_TO_VENTAS, { id: agentSelected.id });
      } else {
        await post(API.MOVE_AGENT_TO_VENTAS, { id: agentSelected.id });
      }
      setListAgents(null);
      setAgentSelected(null);
      setDaysPanel(null);
      getData();
    } catch (e) {
      errorCall(e);
    }
  };
  const handleAgent = (e) => {
    setAgentSelected(e);
  };

  const handleAlert = (idx) => {
    setUnsubscribeIdx(idx);
    setAlertSure(!alertSure);
  };

  return (
    <div className={styles.container}>
      <TableDays
        daysPanel={daysPanel}
        changeHolidays={changeHolidays}
        handleAlert={handleAlert}
        changeDay={changeDay}
      />
      <AddAgentBlock
        listAgents={listAgents}
        getListAgents={getListAgents}
        acceptAgent={acceptAgent}
        agentSelected={agentSelected}
        handleAgent={handleAgent}
      />
      <ModalWarning
        open={alertSure}
        okButton={unsubscribe}
        cancelButton={handleAlert}
      />
    </div>
  );
};
export default Turnos;
