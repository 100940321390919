import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { useHistory } from "react-router-dom";
import { updateAgentSales } from "@services/Leads/leads.service";
import { useTranslation } from "react-i18next";
import { UserContext } from "@contexts/UserContext";

const ModalCambioPosesion = ({ launchModal, state, setState }) => {
  const history = useHistory();
  const { t } = useTranslation('modalCambioPosesion');
  const { checkLead, modal_aviso } = state;
  const { user } = useContext(UserContext);

  const changePossession = async () => {
    const id_client = checkLead.cliente;
    const id_lead = checkLead.id_existente_lead;
    const id_agente_modificador = user;

    const response = await updateAgentSales(id_lead, id_agente_modificador);

    if (response.error) {
      errorToast(
        <span>
          <span className="bold">{t("errors.error")}&nbsp;</span>
          <span>{t("errors.reasignError")}</span>
        </span>
      );
    }
    if (response.status === 204) {
      successToast(<span>El Agente se ha reasignado correctamente</span>);
      history.push(`/main/lead-venta/${id_lead}/${id_client}`);
    }
  };

  return (
    <Modal
      className="modal-lg"
      isOpen={state.modal_conflict}
      toggle={() => launchModal("modal_conflict", state.checkLead.vehicle, false)}
    >
      <ModalHeader>{modal_aviso ? "Crear Lead" : "Cambio de Agente"}</ModalHeader>
      <ModalBody className="p-4">
        Antes de continuar debes confirmar que has leído el aviso.
        <br />
        El cliente ya existe, y está asociado a:
          <>
            <h4 className="mt-1">{state.checkLead?.nombre_agente}</h4>
            <span>Estado: <b>{state.checkLead?.estado_anterior}</b></span>
          </>
      </ModalBody>
      <ModalFooter>
      <Button
          className="btn btn-flexicar-inverse-size-s btn-flexicar-mobile"
					onClick={() =>
						setState({
							...state,
							modal_conflict: false
						})
					}
        >
          Cerrar
        </Button>
        {modal_aviso ? (
          <Button
            className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
            onClick={() => {
              setState({
                ...state,
                modal_conflict: false,
                modal_add_lead: true
              });
            }}
          >
            Crear Lead
          </Button>
        ) : (
          <Button className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile" onClick={changePossession}>
            Confirmar cambio de posesión
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalCambioPosesion