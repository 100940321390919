import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "@contexts/UserContext";
import { errorToast } from "@helpers/toastFunctions";
import { getEmployeeLocals } from "@services/Appointments/AppointmentsService";
import { CALENDAR_CONFIGS } from "@views/Appointments/configs";

export const AppointmentContext = createContext();

const AppointmentContextProvider = ({ children, type }) => {
  const { user: employeeId } = useContext(UserContext);
  const history = useHistory();

  const calendarConfig = useMemo(
    () => CALENDAR_CONFIGS.find((cal) => cal.type === type),
    [type]
  );

  const [events, setEvents] = useState({});
  const [eventsFiltered, setEventsFiltered] = useState({});
  const [eventsFilteredCount, setEventsFilteredCount] = useState(0);
  const [eventsFilteredByLeadState, setEventsFilteredByLeadState] = useState(
    []
  );

  const [feria, setFeria] = useState(false);

  const [localesOptions, setLocalesOptions] = useState([]);
  const [localSelected, setLocalSelected] = useState("");

  const [activateLeadFilters, setActivateLeadFilters] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("");

  const resetFilters = useCallback(() => {
    setLocalSelected("");
    setEventsFilteredByLeadState([]);
    setEventsFiltered(events);
    setActivateLeadFilters(false);
  }, [events]);

  useEffect(() => {
    // Fetch locales
    (async () => {
      try {
        const locales = await getEmployeeLocals(employeeId);
        locales.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setLocalesOptions(
          locales.map((local) => ({ value: local.id, label: local.nombre }))
        );
      } catch (err) {
        errorToast(err);
      }
    })();
  }, [employeeId]);

  useEffect(() => {
    const filteredEvents = JSON.parse(JSON.stringify(events));

    // Filter the events to match just the ones with the current selectedLocale
    Object.entries(events).forEach(([date, hoursObj]) => {
      Object.entries(hoursObj).forEach(([time, evs]) => {
        Object.entries(evs).forEach(([local, appointments]) => {
          // Filter if feria is selected
          if (feria) {
            filteredEvents[date][time][local] = appointments.filter(
              (appointment) => appointment.lead.feria
            );
            // Remove local appointment array if it's empty
            if (Object.values(filteredEvents[date][time][local]).length === 0)
              delete filteredEvents[date][time][local];
          }

          // Filter if local is selected
          if (
            Object.keys(localSelected).length !== 0 &&
            local !== localSelected.label
          )
            delete filteredEvents[date][time][local];

          // Clear the object when there are no events for some hour/date
          if (Object.values(filteredEvents[date][time]).length === 0)
            delete filteredEvents[date][time];
          if (Object.values(filteredEvents[date]).length === 0)
            delete filteredEvents[date];
        });
      });
    });
    setEventsFiltered(filteredEvents);

    // Get all the leads info from the filtered events
    const filteredLeads = Object.values(filteredEvents).flatMap((hourObj) =>
      Object.values(hourObj).flatMap((localObj) =>
        Object.values(localObj).flatMap((appointment) => appointment)
      )
    );

    setEventsFilteredCount(filteredLeads.length);
    setEventsFilteredByLeadState(() => {
      const initialObject = Object.values(calendarConfig.filterTypes).reduce(
        (acc, state) => {
          acc[state] = [];
          return acc;
        },
        {}
      );

      return filteredLeads.reduce((acc, ev) => {
        const key = calendarConfig.getFilterType(ev);
        acc[key].push(ev);
        return acc;
      }, initialObject);
    });
  }, [localSelected, events, feria, calendarConfig]);

  useEffect(() => {
    if (Object.keys(localSelected).length !== 0) setActivateLeadFilters(true);
  }, [localSelected]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (Object.keys(localSelected).length !== 0) {
      searchParams.set("local", localSelected.label);
      history.push({
        pathname: history.location.pathname,
        search: `?${searchParams.toString()}`,
      });
    } else {
      searchParams.delete("local");
      history.push({
        pathname: history.location.pathname,
        search: `?${searchParams.toString()}`,
      });
    }
  }, [history, localSelected]);

  useEffect(() => {
    const handleBackButton = () => {
      const searchParams = new URLSearchParams(history.location.search);
      const localParam = searchParams.get("local");
      Object.keys(localesOptions).length !== 0 && localParam
        ? setLocalSelected(localesOptions.find((x) => x.label === localParam))
        : resetFilters();
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [history, localesOptions, resetFilters]);

  const providerValues = {
    type,
    calendarConfig,
    events,
    setEvents,
    eventsFiltered,
    setEventsFiltered,
    eventsFilteredCount,
    setEventsFilteredCount,
    eventsFilteredByLeadState,
    setEventsFilteredByLeadState,
    localesOptions,
    localSelected,
    setLocalSelected,
    feria,
    setFeria,
    activateLeadFilters,
    setActivateLeadFilters,
    currentFilter,
    setCurrentFilter,
    resetFilters,
  };

  return (
    <AppointmentContext.Provider value={providerValues}>
      {children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentContextProvider;
