import React, { useState } from "react";

import { GoogleMap, Marker } from "@react-google-maps/api";

const Maps = (props) => {
  const { location, setNewDealerHandler, newDealer } = props;
  const [currentLocation] = useState(location);
  const [markerLocation, setMarkerLocation] = useState(location);

  const onClickHandler = (e) => {
    setNewDealerHandler({
      ...newDealer,
      coordinate_longitude: Number(e.latLng.lng()),
      coordinate_latitude: Number(e.latLng.lat()),
    });
    setMarkerLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  return (
    <GoogleMap
      center={currentLocation}
      zoom={18}
      mapContainerStyle={{ height: "45vh", width: "100%" }}
      onRightClick={(e) => onClickHandler(e)}
    >
      <Marker position={markerLocation} />
    </GoogleMap>
  );
};

export default Maps;
