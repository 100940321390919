import { API } from "@services/urlConstants";
import { post } from "@services/axiosCalls";

export const quotationUrl = async (lead_id,user) => {
    try {
      const response = await post(
        `${API.UPDATE_MMA_QUOTATION}/${lead_id}/quotation-url`,{
          agentId: user
        }
      );

     return response
  
    } catch (error) {
     throw(error)
    } 
  };
