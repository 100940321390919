import React from "react";
import { Table } from "react-bootstrap";
import TableRowDays from "@views/Turnos/components/TableRowDays";
import Colors from "@constants/Colors";
import styles from "./index.module.scss";
import { MdCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { AiOutlineClear } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const TableDays = ({ daysPanel, changeHolidays, handleAlert, changeDay }) => {

  const { t } = useTranslation("turns");

  return (
  <Table>
    <thead>
      <tr>
      <th>{t("name")}</th>
        <th>{t("monday")}</th>
        <th>{t("tuesday")}</th>
        <th>{t("wednesday")}</th>
        <th>{t("thursday")}</th>
        <th>{t("friday")}</th>
        <th>{t("saturday")}</th>
        <th>{t("sunday")}</th>
        <th>{t("holidays")}</th>
        <th>{t("delete")}</th>
      </tr>
    </thead>
    <tbody>
      {daysPanel &&
        daysPanel.map((agent, idx) => {
          return (
            agent.baja === null && (
              <tr
                key={agent + idx}
                style={
                  daysPanel[idx].vacaciones === 1
                    ? { background: Colors.secondaryLow }
                    : idx % 2
                    ? { background: Colors.white }
                    : { background: Colors.greyTable }
                }
              >
                <td>
                  <span>{agent.name}</span>
                </td>
                <TableRowDays
                  changeDay={changeDay}
                  day="mon"
                  idx={idx}
                  agent={agent}
                />
                <TableRowDays
                  changeDay={changeDay}
                  day="tue"
                  idx={idx}
                  agent={agent}
                />
                <TableRowDays
                  changeDay={changeDay}
                  day="wed"
                  idx={idx}
                  agent={agent}
                />
                <TableRowDays
                  changeDay={changeDay}
                  day="thu"
                  idx={idx}
                  agent={agent}
                />
                <TableRowDays
                  changeDay={changeDay}
                  day="fri"
                  idx={idx}
                  agent={agent}
                />
                <TableRowDays
                  changeDay={changeDay}
                  day="sat"
                  idx={idx}
                  agent={agent}
                />
                <TableRowDays
                  changeDay={changeDay}
                  day="sun"
                  idx={idx}
                  agent={agent}
                />
                <td>
                  <span>
                    {agent.vacaciones === 1 ? (
                      <div
                        className={styles.container}
                        key={`${idx}changeHolidaysA`}
                      >
                        <MdCheckBox
                          size={40}
                          color={Colors.secondary}
                          onClick={() => changeHolidays(idx)}
                        />
                      </div>
                    ) : (
                      <div
                        className={styles.container}
                        key={`${idx}changeHolidaysB`}
                      >
                        <MdOutlineCheckBoxOutlineBlank
                          size={40}
                          onClick={() => changeHolidays(idx)}
                        />
                      </div>
                    )}
                  </span>
                </td>
                <td>
                  <span className={styles.container}>
                    <AiOutlineClear
                      size={40}
                      onClick={() => handleAlert(idx)}
                    />
                  </span>
                </td>
              </tr>
            )
          );
        })}
    </tbody>
  </Table>
);
      }
export default TableDays;
