import { deleteReq, get, post } from "@services/axiosCalls";

export const getRoles = async () => {
  const response = await get(`api/roles`);
  return response;
};

export const getEmployeeName = async (employeeId) => {
  const response = await get(
    `/api/employee/${employeeId}/name`
  );
  return response;
};

export const addRoles = async (employeeId, rolId) => {
  const response = await post(`/api/employee/${employeeId}/roles`, { rolId });
  return response;
};

export const getEmployeesNameByGreaterType = async (employeeId) => {
  const response = await get(
    `/api/employee/${employeeId}/greater-type-names`
  );
  return response;
};

export const getEmployeeRoles = async (employeeId) => {
  const response = await get(
    `/api/employee/${employeeId}/roles`
  );
  return response;
};

export const deleteEmployeeRole = async (employeeId, roleId) => {
  const response = await deleteReq(`/api/employee/${employeeId}/roles/${roleId}`);
  return response;
};  

export const copyEmployeeRoles = async (employeeIdTo, employeeIdFrom) => {
  const response = await post(`/api/roles/copy`, {
    employeeIdTo,
    employeeIdFrom,
  });
  return response;
};
