import * as Yup from "yup";

import Locale from "@constants/Locale";
import { PHONE_REGEX_ES, PLATE_REGEX_ES, PHONE_REGEX_PT, PLATE_REGEX_PT } from "@constants/regex";

export const addLeadSchema = (locale) =>
	Yup.object({
		phoneOrMatricula: Yup.string().test(
			"isPhoneOrMatricula",
			"Teléfono o Matrícula inválidos",
			(value) => {
				if (locale === Locale.ES) {
					return PHONE_REGEX_ES.test(value) || PLATE_REGEX_ES.test(value);
				} else if (locale === Locale.PT) {
					return PHONE_REGEX_PT.test(value) || PLATE_REGEX_PT.test(value);
				}
			}
		)
	});
