import { useFormContext } from "react-hook-form";
import { MdError } from "react-icons/md";

const textErrorStyle = {
	display: "flex",
	alignItems: "center",
	padding: "0 0.5rem",
	gap: "0.15rem",
	fontWeight: 600,
	color: "rgb(239 68 68)",
	backgroundColor: "rgb(254 226 226)",
	borderRadius: "0.375rem",
	marginBottom: 0
};

const svgErrorStyle = {
	marginBottom: "4px"
};

const errorMessageStyle = {
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const useFormError = (name) => {
	const { formState } = useFormContext();

	const getObjectProperty = (object, path) => {
		const keys = path.split(".");

		return keys.reduce(
			(acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
			object
		);
	};

	const error = getObjectProperty(formState.errors, name);
	const customError = error?.value || error;

	const isInvalid = customError !== undefined;

	const markupError = isInvalid && (
		<p style={textErrorStyle}>
			<MdError
				style={svgErrorStyle}
				size={20}
			/>
			<span
				style={errorMessageStyle}
				title={customError?.message}
			>
				{customError?.message}
			</span>
		</p>
	);

	return {
		isInvalid,
		errorMessage: customError?.message,
		markupError
	};
};

export default useFormError;
