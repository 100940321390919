export const postalCodePtRegex = new RegExp("^\\d{4}[- ]{0,1}\\d{3}$");
export const postalCodeEuRegex = new RegExp("^\\d{5}$");

export const PHONE_REGEX_ES = new RegExp("^[679]{1}[0-9]{8}$");
export const PHONE_REGEX_PT = new RegExp("^[9287]{1}[0-9]{8}");

export const ISO_DATE_REGEX = new RegExp("^\\d{4}-([0]\\d|1[0-2])-([0-2]\\d|3[01])$");
export const ISO_DATE_TIME_REGEX = new RegExp(
	"^\\d{4}-([0]\\d|1[0-2])-([0-2]\\d|3[01])T(2[0-3]|[01][0-9]):([0-5][0-9])$"
);

export const PLATE_REGEX_ES = new RegExp("^[0-9]{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$");
export const PLATE_REGEX_PT = new RegExp(
	/^((?:(?:(?![KWY])[A-Z]){2}\d{2}(?:(?![KWY])[A-Z]){2})|(?:\d{2}(?:(?![KWY])[A-Z]){2}\d{2})|(?:\d{2}\d{2}(?:(?![KWY])[A-Z]){2}))$/g
);

export const REGEX_VIN = new RegExp("^([A-HJ-NPR-Z0-9]){17}$");

export const EMAIL_REGEX = new RegExp(
	"^(?!\\.)(?!.*\\.\\.)([A-Z0-9_+-\\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\\-]*\\.)+[A-Z]{2,}$",
	"i"
);

export const NAME_WITHOUT_SPECIAL_CHARACTERS = new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/);
