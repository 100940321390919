import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { MdOutlineFindInPage } from "react-icons/md";

import { ErrorPanel } from "@components/InfoBox";

import styles from "./index.module.scss";


const ModalUploadMultiImages = ({ show, toggle, uploadCb, validFormats }) => {
  const [files, setFiles] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState([]);

  const { t } = useTranslation("fichaLeadsCompra");

  const invalidFilesError = invalidFiles.length !== 0;

  useEffect(() => {
    if (files) {
      const _invalidFiles = []
      files.forEach(file => {
        if (!validFormats.includes(file.name.split('.').pop())) {
          _invalidFiles.push(file.name)
        }
      });
      setInvalidFiles(_invalidFiles);
    };
  }, [files]);

  const reset = () => {
    setSpinner(false);
    setFiles(null);
    toggle();
  };

  return (
    <Modal className="modal-xs" isOpen={show} toggle={reset}>
      <ModalHeader toggle={reset}>Subir documento</ModalHeader>
      <ModalBody className="p-4">
        <div className={styles.center}>
          <div className={styles.uploadContainer}>
            {files ? (
              <h5>Archivos seleccionados</h5>
            ) : (
              <h5>Seleccione archivos</h5>
            )}
            {files && (
              <div className={styles.textBox}>
                {files.map((e) => (
                  <strong key={`file${e.name}`} className={invalidFiles.includes(e.name) ? "text-danger" : ""}>{e.name}</strong>
                ))}
              </div>
            )}
            <div className={styles.uploadBox}>
              {files ? (
                <div className="w-100">
                  <div className="my-2">
                    {invalidFilesError && <ErrorPanel text={t("errors.errorFormatNotAllowed")} />}          
                  </div>
                  <div className={styles.iconBox}>
                    <button
                      onClick={reset}
                      className={styles.buttonCancel}
                    >
                      Cancelar
                    </button>

                    {!spinner ? (
                      <button
                        className={styles.buttonAccept}
                        onClick={async () => {
                          setSpinner(true);
                          await uploadCb(files);
                          reset();
                        }}
                        disabled={invalidFilesError}
                      >
                        Confirmar
                      </button>
                    ) : (
                      <Spinner type="grow" className="flexicarColor" />
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <label for="file-input" className="pointer">
                    <MdOutlineFindInPage
                      title="Buscar archivo"
                      size={75}
                      color="#007bff"
                    />
                  </label>
                  <input
                    className={styles.uploadInput}
                    id="file-input"
                    type="file"
                    multiple
                    name="customFile"
                    onChange={(e) => setFiles(Object.values((e.target.files)))}
                    label="Seleccion un archivo"
                    accept="image/*"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>

  );
};

export default ModalUploadMultiImages;
