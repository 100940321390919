import { Table, UncontrolledTooltip } from "reactstrap";
import { MdAdd, MdDelete } from "react-icons/md";

import styles from "./index.module.scss";

const RolesList = ({ roles, action = "delete" | "add", actionClick }) => (
  <div className="d-flex flex-column align-items-center">
    <span className="text-primary mt-2 mb-2">
      Roles totales: <span className="text-success">{roles.length}</span>
    </span>

    <Table className={styles.table}>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Acción</th>
        </tr>
      </thead>
      <tbody>
        {roles.map((rol) => (
          <tr key={rol.id}>
            <td id={`role-list-tooltip-target-${rol.id}`} title={rol.decripcion}>
              <UncontrolledTooltip
                placement="auto"
                target={`role-list-tooltip-target-${rol.id}`}
              >
                {rol.descripcion}
              </UncontrolledTooltip>
              {rol.nombre}
            </td>
            <td>
              {
                // JSX inline switch
                {
                  delete: (
                    <button className="btn" onClick={() => actionClick(rol)}>
                      <MdDelete color="#ff0000" size={24} />
                    </button>
                  ),
                  add: (
                    <button className="btn" onClick={() => actionClick(rol)}>
                      <MdAdd color="#2199F6" size={24} />
                    </button>
                  ),
                }[action]
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default RolesList;
