const thousandsFormat = (number) => {
  if(!number){
    return''
  }
  else {
    let n = number.toLocaleString('pt').replace(/\D/g, '');
    let r = Number(n).toLocaleString("pt");
    return r 
  }
}
export default thousandsFormat;
