import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalSaveData = ({ modalOpen, setModalOpen }) => {
  const history = useHistory();
  
  const handleAceptButton = () => {
    history.goBack();
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <Modal size="lg" isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader className="p-4" toggle={toggleModal}>
          Datos no guardados
        </ModalHeader>
        <ModalBody className="p-4">
          Los datos modificados no han sido guardados, ¿Desea salir sin guardar?
        </ModalBody>
        <ModalFooter>
          <Button className="btn-flexicar-inverse-size-s" onClick={toggleModal}>
            Cancelar
          </Button>
          <Button className="btn-flexicar-orange" onClick={handleAceptButton}>
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ModalSaveData;
