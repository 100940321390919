import { Controller, useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/formatted-number-input.module.scss";
import useFormError from "@components/Form/hooks/useFormError";
import FormattedNumberInput from "@components/FormattedNumberInput";

/**
 * @typedef {import('components/FormattedNumberInput').Props} FormattedNumberInputProps
 */

/**
 * @typedef Props
 * @property {string} props.name - The name of the input element.
 * @property {string} props.label - The label for the input element.
 */

/**
 * FormFormattedNumberInput is a component for rendering an input element with its label.
 *
 * @param {Props & FormattedNumberInputProps} props - Props for the FormattedNumberInput component.
 * @returns {JSX.Element} The rendered component.
 */

const FormFormattedNumberInput = (props) => {
	const { control } = useFormContext();
	const { markupError } = useFormError(props.name);

	return (
		<div className={styles.customInputWrapper}>
			<label htmlFor={props.name}>{props.label}</label>
			<Controller
				name={props.name}
				control={control}
				render={({ field }) => (
					<FormattedNumberInput
						id={props.name}
						{...field}
						{...props}
					/>
				)}
			/>

			{markupError}
		</div>
	);
};

export default FormFormattedNumberInput;
