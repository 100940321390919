export const WARRANTY_OPTIONS = [
	{
		label: "No",
		value: 0
	},
	{
		label: "Estandar",
		value: 1
	},
	{
		label: "Premiun",
		value: 2
	},
	{
		label: "Extra",
		value: 3
	}
];
export const typeDocument = {
	retirada: "WITHDRAW",
	recepcion: "RECEPTION"
};

export const typeDocumentTranslate = {
	WITHDRAW: "retirada",
	RECEPTION: "recepción"
};