const numberFormat = (number) => {
  if(number===0 || !number || number===''){
    return null
  }
  else if (typeof number === 'number') { 
    return number
  }
  else {
    let n = number.replace(/\./g, '')
    let r = Number(n)
    return r 
  }
}
export default numberFormat;
