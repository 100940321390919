import React, { useState, useEffect, useCallback } from "react";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";

const ModalHistoricoLead = ({ lead_id, setModal, status }) => {

  const [historical, setHistorical] = useState([
    {
      f_ultima_modificacion: "",
      id_agente_mod: 0,
      nombre_estado: "",
      nombre: "",
      apellido1: "",
      apellido2: "",
      telefono1: "",
      email: "",
      id_lead_venta: 0,
      locals: [
        {
          id: 1,
          name: "",
        },
      ],
    },
  ]);
  const [tooltipContent, setTooltipContent] = useState("");

  const getData = useCallback(async () => {
    const response = await post(API.GET_HISTORICO_LEAD, { lead_id });
    setHistorical(response);
  }, [lead_id]);

  useEffect(() => {
    if (lead_id && status) {
      getData();
    }
  }, [status, lead_id, getData]);

  return (
    <Modal
      className="modal-lg"
      centered
      isOpen={status}
      toggle={() => setModal()}
    >
      <ModalHeader>Historial de cambios</ModalHeader>
      <ModalBody className="p-4">
        {historical.length === 0 ? (
          "No se han producido cambios en el lead."
        ) : (
          <table className="table table-striped fl-table">
            <thead>
              <tr>
                <th scope="col-md-1">Agente</th>
                <th scope="col-md-1">Fecha</th>
                <th scope="col-md-1">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {historical.map((item, idx) => (
                <tr key={`historical-item-${idx}`} style={{ fontSize: "15px" }}>
                  <td>
                    {" "}
                    <span
                      href="#"
                      id="tooltip_agent"
                      onMouseEnter={() => {
                        setTooltipContent({
                          nombre: item?.nombre + " ",
                          apellido: item?.apellido1 + " ",
                          telefono: item?.telefono1 + " ",
                          email: item?.email + " ",
                          locales: item?.isCallCenter ? 'Call Center Ventas':
                            item?.locals?.map((local) => local.name).sort() + " ",
                        });
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                    >
                      {item.nombre} {item.apellido1}
                      {tooltipContent.nombre ? (
                        <UncontrolledTooltip
                          placement="left"
                          flip
                          style={{ textAlign: "left" }}
                          target="tooltip_agent"
                        >
                          <strong>{tooltipContent.nombre}</strong>
                          <br />
                          <strong>Email: </strong>
                          {tooltipContent.email}
                          <br />
                          <strong>Telf: </strong>
                          {tooltipContent.telefono}
                          <br /> <strong>Locales </strong>
                          {tooltipContent.locales}
                          <br />
                        </UncontrolledTooltip>
                      ) : null}
                    </span>
                  </td>
                  <td>
                    {moment(item.f_ultima_modificacion).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </td>
                  <td>{item.nombre_estado}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-light btn-outline-dark btn-flexicar"
          onClick={() => setModal()}
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalHistoricoLead;
