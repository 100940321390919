import Locale from "@constants/Locale";
import { postalCodeEuRegex, postalCodePtRegex } from "@constants/regex";
import i18n from "i18next";

const validationPostalCode = (cp) => {
    if (i18n.language === Locale.PT) return postalCodePtRegex.test(cp);
    if (i18n.language === Locale.ES) return postalCodeEuRegex.test(cp);
  
    return false;
  };
  
export default validationPostalCode;
  