import { MdInfoOutline, MdWarningAmber, MdErrorOutline } from "react-icons/md";

import styles from "./index.module.scss";

const InfoBox = ({ leftIcon, text, className }) => (
	<div className={`${styles.infoBoxWrapper} ${className}`}>
		{leftIcon}
		<span>{text}</span>
	</div>
);

export const InformativePanel = ({ text }) => (
	<InfoBox
		leftIcon={
			<div>
				<MdInfoOutline
					size={22}
					color="#17a2b8"
				/>
			</div>
		}
		text={text}
		className={styles.info}
	/>
);

export const WarningPanel = ({ text }) => (
	<InfoBox
		leftIcon={
			<div>
				<MdWarningAmber
					size={22}
					color="#EDDF5D"
				/>
			</div>
		}
		text={text}
		className={styles.warning}
	/>
);

export const ErrorPanel = ({ text }) => (
	<InfoBox
		leftIcon={
			<div>
				<MdErrorOutline
					size={22}
					color="#ED0530"
				/>
			</div>
		}
		text={text}
		className={styles.error}
	/>
);
